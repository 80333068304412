import React, { useEffect } from 'react';

import { ReactComponent as CrossIcon } from 'src/assets/media/icons/cross.svg';
import { ReactComponent as SuccessIcon } from 'src/assets/media/icons/notification-success.svg';
import { ReactComponent as ErrorIcon } from 'src/assets/media/icons/notification-error.svg';

import styles from './Notification.module.sass';

interface Props {
    show: boolean;
    message: string;
    type: 'success' | 'error' | 'warning' | 'info';
    timeout?: number;
    hideCloseButton?: boolean;
    onCloseHandler?: () => void;
    className?: string;
}

const Notification: React.FC<Props> = (props) => {
    useEffect(() => {
        if (props.show && props.timeout) {
            setTimeout(() => {
                props.onCloseHandler && props.onCloseHandler();
            }, props.timeout);
        }
    }, [ props.show ]);

    const closeButtonClickHandler = () => {
        props.onCloseHandler && props.onCloseHandler();
    };

    const renderNotification = () => {
        switch (props.type) {
            case 'success':
                return (
                    <div className = { `${styles.container} ${styles.containerSuccess} ${props.className}` }>
                        <div>
                            <SuccessIcon className = { styles.icon }/>
                            {props.message}
                        </div>
                        {
                            !props.hideCloseButton &&
                            <button onClick = { closeButtonClickHandler }>
                                <CrossIcon className = { styles.closeIcon }/>
                            </button>
                        }
                    </div>
                );
            case 'error':
                return (
                    <div className = { `${styles.container} ${styles.containerError} ${props.className}` }>
                        <div>
                            <ErrorIcon className = { styles.icon }/>
                            {props.message}
                        </div>
                        {
                            !props.hideCloseButton &&
                            <button onClick = { closeButtonClickHandler }>
                                <CrossIcon className = { styles.closeIcon }/>
                            </button>
                        }
                    </div>
                );
            default:
                return null;
        }
    };

    if (props.show) {
        return (
            <>
                {
                    renderNotification()
                }
            </>
        );
    }

    return null;
};


export default Notification;
