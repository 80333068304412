// Core
import React from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { Link, useHistory } from 'react-router-dom';

// Tools
import { ROUTE_CONSTANTS } from 'src/init/constants';
import { ACTION_LOGOUT_REQUEST } from 'src/store/action-types';
import { getIdentityURL } from 'src/utils';

// Elements
import { ReactComponent as LeftArrowIcon } from 'src/assets/media/icons/left-arrow.svg';

// Styles
import styles from './Footer.module.sass';

export interface Props {
    open: boolean;
    onClose: () => void;
}

export const SidebarFooter: React.FC<Props> = (props) => {
    const dispatch: Dispatch<any> = useDispatch();
    const history = useHistory();

    const handleLogout = () => {
        dispatch({ type: ACTION_LOGOUT_REQUEST });
        history.push('/');
    };

    return (
        <div className = { `${styles.container} ${props.open && styles.open}` }>
            <a href = { getIdentityURL('/profile') }>
                <button className = { styles.button }>Edit My Information</button>
            </a>

            <Link to = { ROUTE_CONSTANTS.SETTINGS.split(':')[0] }>
                <button className = { styles.button }>EatRight Settings</button>
            </Link>

            <button className = { styles.button }>
                <Link to = { ROUTE_CONSTANTS.CONTACTS }>
                    Get Help
                </Link>
            </button>

            <div className = { styles.divider } />

            <button
                className = { `${styles.button} ${styles.buttonLogout}` }
                onClick = { handleLogout }>Logout
            </button>

            <button
                className = { `${styles.button} ${styles.buttonClose}` }
                onClick = { props.onClose }>
                <LeftArrowIcon />
            </button>
        </div>
    );
};
