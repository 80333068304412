// Core
import React, { FC } from 'react';
import clsx from 'clsx';

// Assets
import { ReactComponent as NavigationIcon } from 'src/assets/media/icons/home/navigation-icon.svg';
import { ReactComponent as MealIcon } from 'src/assets/media/icons/home/meal-icon.svg';
import { ReactComponent as DeliveryIcon } from 'src/assets/media/icons/home/delivery-icon.svg';
import { ReactComponent as AutopilotIcon } from 'src/assets/media/icons/home/autopilot-icon.svg';
import PlateImage from 'src/assets/media/home/plate-big.png';

// Styles
import styles from './SideIcons.module.sass';
import { HowDoesItWorkCard } from '../HowDoesItWorkCard';

const ICONS = [
    NavigationIcon,
    MealIcon,
    DeliveryIcon,
    AutopilotIcon
]


type SideIconsType = FC<{ className?: string, data: { icon: string, title: string, desc: string }[] }>;

export const SideIcons: SideIconsType = ({
    className,
    data
}) => {
    // check if object keys
    return (
        <section className = { clsx(className, 'common-section', styles.section) }>
            <img
                alt = 'Plate'
                className = { styles['bg-image--mobile'] }
                src = { PlateImage }
            />
            <div className = { styles['bg-color--mobile'] }>
                <div className = 'w-1/2  h-full bg-primary' />
            </div>
            <div className = 'common-container flex flex-col md:flex-row md:justify-end px-10 md:px-0'>
                <div className = 'flex flex-col md:flex-row md:flex-wrap items-center md:items-start w-full md:w-9/12 lg:w-7/12'>
                    <h3 className = { styles.title }>How does it work</h3>
                    {
                        data.map((dataItem, index) => {
                           if (Object.values(dataItem).every((v) => v === null)) {
                                return null;
                           }

                           return (
                                <HowDoesItWorkCard
                                    key = { dataItem.title }
                                    title = { dataItem.title }
                                    desc = { dataItem.desc }
                                    Icon = { dataItem.icon || ICONS[index] }
                                />)
                        })
                    }
                </div>
            </div>
        </section>
    );
};
