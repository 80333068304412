import { useEffect } from 'react';
import { usePage } from './usePage';

export const useSeo = () => {
    const page = usePage();
    useEffect(() => {
        if (page) {
            document.title = page.title;
        }
    }, [ page ]);
};
