// Core
import React, { FC, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { RootStateOrAny, useSelector } from 'react-redux';

//  Elements
import { ReactComponent as CloseIcon } from 'src/assets/media/icons/navigation/close.svg';
import { BaseButton } from 'src/components/BaseButton';
import LogoImg from 'src/assets/media/trainx-logo.svg';

// Tools
import { getIsLinkActive } from '../../tools';
import { headerConfig } from 'src/init/config';
import { getIdentityURL, getItemFromLocalStorage } from 'src/utils';
import { useClickOutside } from 'src/hooks/useClickOutside';
import useGetUserRegion from 'src/hooks/api/deliveries/useGetUserRegion';
import useCMSPages from 'src/hooks/api/cms/useCMSPages';
import { getCMSHeaderConfig } from 'src/utils/api/cms-pages';
import { IRootState } from 'src/types/state';

// Styles
import styles from './headerMobile.module.sass';


type HeaderMobileSidebarType = FC<{
    className?: string;
    cmsPages?: any[];
    isActive: boolean;
    handleCloseSidebar: () => void;
}>;

export const HeaderMobileSidebar: HeaderMobileSidebarType = ({
    className,
    isActive,
    handleCloseSidebar,
}) => {
    const { pathname } = useLocation();
    const isAuthenticated = useSelector((state: IRootState) => state.auth.isAuthenticated);
    const sidebarRootRef = React.createRef<HTMLDivElement>();

    const redirectToAuth = () => {
        if (!isAuthenticated) {
            let url = `/login${getItemFromLocalStorage('eatright-ref') ? `?ref=${getItemFromLocalStorage('eatright-ref')}` : ''}${
                (getItemFromLocalStorage('eatright-ref') && getItemFromLocalStorage('eatright-mbsy-source')) ? '&' : getItemFromLocalStorage('eatright-mbsy-source') ? '?' : ''}${
                getItemFromLocalStorage('eatright-mbsy-source') ? `mbsy_source=${getItemFromLocalStorage('eatright-mbsy-source')}&campaignid=${
                getItemFromLocalStorage('eatright-mbsy-campaignid')}&mbsy=${getItemFromLocalStorage('eatright-mbsy')}&mbsy_exp=${getItemFromLocalStorage('eatright-mbsy-exp')}` : ''}`;
            location.href = getIdentityURL(url);
        }
    };

    const region = useGetUserRegion();
    const regionList = useSelector((state: IRootState) => state.locale?.regionList?.data);

    const regionId = useMemo(() => regionList?.find((item) => item.name === region)?.id, [ region, regionList ])
  
    const { CMSPages } = useCMSPages(regionId, 'en');
    const CMSHeaderConfig = useMemo(() => 
        CMSPages?.length ? getCMSHeaderConfig(CMSPages)?.filter(({ name }) => !['About', 'Contacts', 'FAQ'].includes(name)) : [], [CMSPages])

    useClickOutside(sidebarRootRef, handleCloseSidebar);

    useEffect(() => {
        handleCloseSidebar();
    }, [ pathname ]);

    return (
        <>
            {/*@ts-ignore*/}
            <style global = 'true'>
                {
                    isActive && `
                        body::after {
                            z-index: 10;
                            position: fixed;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            display: block;
                            width: 100vw;
                            height: 100vh;
                            content: '';
                            background: rgba(46, 47, 49, 0.69);
                      }
                    `
                }
            </style>
            <div
                className = { clsx(className, styles.container, clsx({
                    [styles['container--active']]: isActive,
                })) }
                ref = { sidebarRootRef }>
                <div className = 'relative mb-12'>
                    <img
                        alt = 'Eat right logo'
                        src = { LogoImg }
                        style = {{ width: '89px', height: '51px' }}
                    />
                    <button
                        className = { styles['close-btn'] }
                        onClick = { handleCloseSidebar }>
                        <CloseIcon />
                    </button>
                </div>
                <div className = 'flex flex-col border-b border-solid border-neutral-30 pb-2 mb-8'>
                    
                    {/* pages from CMS */}
                    {CMSHeaderConfig?.filter(({ name, path }) => path === '/home').map(({ name, path }) => path ? (
                        <Link
                            className = { clsx(styles.link, {
                                [styles['link--active']]:
                                        getIsLinkActive(pathname, path),
                            }) }
                            key = { name?.slice(0, 5) }
                            to = { path }>
                            {name}
                        </Link>
                    ) : (
                        <span
                            className = { clsx(
                                styles.link,
                                'cursor-pointer',
                                {
                                    [styles['link--active']]:
                                            getIsLinkActive(
                                                pathname,
                                                path,
                                            ),
                                },
                            ) }
                            key = { name?.slice(0, 5) }>
                            {name}
                        </span>
                    ))}

                    {
                        headerConfig.map(({ name, path }) => path ? (
                            <Link
                                className = { clsx(styles.link, {
                                    [styles['link--active']]: getIsLinkActive(pathname, path),
                                }) }
                                key = { name?.slice(0, 5) }
                                to = { path }>
                                {name}
                            </Link>
                        ) : (
                            <span
                                className = { clsx(styles.link, {
                                    [styles['link-active']]: getIsLinkActive(pathname, path),
                                }) }
                                key = { name?.slice(0, 5) }>
                                {name}
                            </span>
                        ))
                    }

                    {/* pages from CMS */}
                    {CMSHeaderConfig?.filter(({ name, path }) => path !== '/home').map(({ name, path }) => path ? (
                        <Link
                            className = { clsx(styles.link, {
                                [styles['link--active']]:
                                        getIsLinkActive(pathname, path),
                            }) }
                            key = { name?.slice(0, 5) }
                            to = { path }>
                            {name}
                        </Link>
                    ) : (
                        <span
                            className = { clsx(
                                styles.link,
                                'cursor-pointer',
                                {
                                    [styles['link--active']]:
                                            getIsLinkActive(
                                                pathname,
                                                path,
                                            ),
                                },
                            ) }
                            key = { name?.slice(0, 5) }>
                            {name}
                        </span>
                    ))}
                </div>
                <BaseButton
                    className = 'w-full'
                    type = 'primary'
                    onClick = { redirectToAuth }>
                    Login
                </BaseButton>
            </div>
        </>
    );
};
