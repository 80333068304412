import { useSelector } from 'react-redux';
import { IRootState } from 'src/types/state';
import useSWR from 'swr';
import api from '../../../managers/api';

interface UseCMSContent {
    isLoading: boolean
    error: any;
    pageBlocks: any[]
    SEOTitle: string
    SEODescription: string
    SEOTags: string;
}

export default function useCMSContent(slug: string, region: string, language?: string): UseCMSContent {
    const regionList = useSelector((state: IRootState) => state.locale?.regionList?.data);

    const regionId = regionList?.find((item) => item.name === region)?.id || ''

    let queryString = new URLSearchParams('');

    if (language) {
        queryString.set('filter[language]', language);
    }
    
    const { data, error } = useSWR<any>(`/cms-pages/by-slug/${slug}?${queryString.toString()}`, api.get, { shouldRetryOnError: false });

    const isLoading = !data && !error;
    const pageBlocks = data?.data?.data?.page_blocks?.sort((a: any, b: any) => {
        if (a.order && b.order) { 
            return Number(a.order) - Number(b.order);
        }
        if (a.order && !b.order) {
            return -1;
        }
        if (!a.order && b.order) {
            return 1;
        }

        return 0;
    }) || [];

    const SEOTitle = data?.data?.data?.seo_title || '';
    const SEODescription = data?.data?.data?.seo_description || '';
    const SEOTags = data?.data?.data?.seo_tags || '';

    return {
        isLoading,
        error,
        pageBlocks,
        SEOTitle,
        SEODescription,
        SEOTags,
    };
}
