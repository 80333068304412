import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Input from 'src/components/LR/form/input/input.component';
import Loader from 'src/components/Loader';
import Pagination from 'src/components/Pagination';
import SettingsDataTable from '../../components/table/table.component';
import Notification from 'src/components/Notification';
import { DesktopLayout, MobileLayout } from 'src/layouts';
import Card from 'src/components/LR/card/card.style';

import { ReactComponent as TimesIcon } from 'src/assets/media/icons/times.svg'

import { 
    ACTION_GET_USER_COUPONS_REQUEST, 
    ACTION_POST_USER_COUPONS_REQUEST, 
    ACTION_SET_USER_COUPONS_APPLY_SUCCESS, 
    ACTION_REMOVE_USER_COUPONS_REQUEST, 
    ACTION_SET_USER_COUPONS_REMOVE_SUCCESS, 
    ACTION_GET_USER_CREDIT_REQUEST 
} from 'src/store/action-types';
import { IRootState } from 'src/types/state';

import { tableData } from './discounts.data';

import Styles, { 
    Section, 
    SectionTitle, 
    InputContainer, 
    PrimaryButton, 
    TabularData, 
    CreditDetails 
} from './discounts.styles';

interface ITableFooter {
    currentPage: number
    perPage: number
    total: number
    onChange: (page: number) => void
}

const TableFooter = ({ currentPage, perPage, total, onChange }: ITableFooter) => (
    <div className = 'table-footer-content'>
        <Pagination
            currentPage = { currentPage }
            itemsPerPage = { perPage }
            total = { total }
            onChange = { onChange }
        />
    </div>
);

const Discounts = () => {
    const dispatch = useDispatch();
    const coupons = useSelector((state: IRootState) => state.settingsCoupons.coupons);
    const applyCoupons = useSelector((state: IRootState) => state.settingsCoupons.applyCoupon);
    const userCredit = useSelector((state: IRootState) => state.deliveries?.userCredit) || 0;
    const [ couponInput, setCouponInput ] = useState('');

    useEffect(() => {
        dispatch({ type: ACTION_GET_USER_COUPONS_REQUEST, payload: { page: 1 }});
        dispatch({ type: ACTION_GET_USER_CREDIT_REQUEST });
    }, []);

    const applyCouponHandler = () => {
        dispatch({ type: ACTION_POST_USER_COUPONS_REQUEST, payload: { code: couponInput }});
    };

    const renderMobileTabularCard = (d: any) => {
        return (
            <TabularData>
                <tr>
                    <td className = 'title'>Coupon</td>
                    <td className = 'value'>{ d.coupon }</td>
                </tr>
                <tr>
                    <td className = 'title'>Amount</td>
                    <td className = 'value'>{ d.amount }</td>
                </tr>
                <tr>
                    <td className = 'title'>Status</td>
                    <td className = 'value'><div className = 'status'>{ d.status }</div></td>
                </tr>
                <tr>
                    <td className = 'title'>Uses</td>
                    <td className = 'value'>{ d.uses }</td>
                </tr>
                <tr>
                    <td className = 'title'>Remove</td>
                    <td className = 'removeButton'><TimesIcon onClick={ () => removeCouponAction(d.id)} /></td>
                </tr>
            </TabularData>
        );
    };

    const removeCouponAction = (couponId: number) => dispatch({ type: ACTION_REMOVE_USER_COUPONS_REQUEST, payload: { couponId } })

    const removeCouponBtn = (couponId: number) => <TimesIcon style={{color: 'red'}} onClick={()=>removeCouponAction(couponId)} />;

    const data = useMemo(() => {
        const data = coupons.data.map((d) => ({
            id:     d.id,
            coupon: d.coupon.code,
            amount: d.residual,
            status: d.status,
            uses:   d.usages.length,
            remove: removeCouponBtn(d.id)
        }));

        return data;
    }, [ coupons ]);

    return (
        <Styles>
            <Section>
                <CreditDetails>
                    <span className='title'>Available Credit:</span> <span>{ userCredit } {" AED"}</span>
                </CreditDetails>
                <SectionTitle>Apply Coupon To Future Orders (Renewls etc)</SectionTitle>
                <Notification
                    className = 'w-full my-3'
                    message = 'Coupon Applied Successfully'
                    show = { applyCoupons.success }
                    timeout = { 5000 }
                    type = 'success'
                    onCloseHandler = { () => dispatch({ type: ACTION_SET_USER_COUPONS_APPLY_SUCCESS }) }
                />
                <Notification
                    className = 'w-full my-3'
                    message = {coupons.error ? coupons.error : ""}
                    show = { Boolean(coupons.error) }
                    timeout = { 5000 }
                    type = 'error'
                    onCloseHandler = { () => dispatch({ type: ACTION_SET_USER_COUPONS_REMOVE_SUCCESS }) }
                />
                { applyCoupons.loading ? (
                    <Loader />
                ) : (
                    <InputContainer>
                        <Input
                            className = { `input ${applyCoupons.error ? 'error' : ''}` }
                            error = { applyCoupons.error || '' }
                            id = 'coupon'
                            placeholder = 'e.g 21y4kb4'
                            value = { couponInput }
                            onChange = { (event) => setCouponInput(event.target.value) }
                        />
                        <PrimaryButton
                            disabled = { !couponInput }
                            onClick = { () => applyCouponHandler() }>Apply Coupon
                        </PrimaryButton>
                    </InputContainer>
                )
                }
            </Section>
            <Section>
                <SectionTitle>List of Coupons To Be Applied In Future</SectionTitle>

                <DesktopLayout >
                    <SettingsDataTable
                        columns = { tableData.columns }
                        dataSource = { data }
                        footer = { () => (
                            <TableFooter
                                currentPage = { coupons.currentPage }
                                perPage = { coupons.perPage }
                                total = { coupons.total }
                                onChange = { (page) => {
                                    dispatch({ type: ACTION_GET_USER_COUPONS_REQUEST, payload: { page }});
                                } }
                            />
                        ) }
                        loading = { coupons.loading }
                        pagination = { false }
                    />
                </DesktopLayout>
                <MobileLayout>
                    {
                        data.map((d, i) => (
                            <Card
                                className = 'mb-4'
                                key = { i }>
                                { renderMobileTabularCard(d) }
                            </Card>
                        ))
                    }
                    {
                        data.length ? (
                            <Pagination
                                className = 'text-center'
                                currentPage = { coupons.currentPage }
                                itemsPerPage = { coupons.perPage }
                                total = { coupons.total }
                                onChange = { (page) => {
                                    dispatch({ type: ACTION_GET_USER_COUPONS_REQUEST, payload: { page }});
                                } }
                            />
                        ) : (
                            <h5 className = 'text-center my-4'>You do not have any coupons yet!</h5>
                        )
                    }
                </MobileLayout>
            </Section>
        </Styles>
    );
};

export default Discounts;
