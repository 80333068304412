import React from 'react';
import clsx from 'clsx';

import { ReactComponent as BlackLogoImg } from 'src/assets/media/trainx-logo.svg';
import { ReactComponent as WhiteLogoImg } from 'src/assets/media/trainx-white-logo.svg';
import Portal from '../Portal';

import styles from './Loader.module.sass';

type LoaderType = React.FC<{ className?: string; text?: string }>

export const FullScreenLoader: LoaderType = () => {
    return (
        <Portal>
            <div className = { styles.backdrop }>
                <div className = { styles.loaderBody }>
                    <WhiteLogoImg />
                    <div className = { styles.loaderCircle } />
                </div>
            </div>
        </Portal>
    );
};

export const Loader: LoaderType = ({ className, text }) => {
    return (
        <div className = { clsx(styles.container, className) }>
            <div className = { styles.loaderBody }>
                <BlackLogoImg />
                <div className = { styles.loaderCircle } />
            </div>
            <span>{text}</span>
        </div>
    );
};

export default Loader;
