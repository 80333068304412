import React, { ComponentType } from 'react';
import { Link } from 'react-router-dom';

import BottomDrawer from '../bottom-drawer/bottom-drawer.component';
import Styles from './mobile-more-drawer.styles';

type MobileMoreDrawerPropsType = {
    isOpen: boolean
    onClose: () => void
    menuItems: LinkType[]
}
type LinkType = {
    Icon: ComponentType
    onClick?: () => void
    url?: string
    name: string
    permission?: string
}

const MobileMoreDrawer = ({ isOpen, onClose, menuItems }: MobileMoreDrawerPropsType) => {
    const renderMenuItem = (
        Icon: React.ComponentType,
        url: string | undefined,
        name: string,
        onClick?: () => void,
    ) => {
        if (url?.startsWith('http')) {
            return (
                <a
                    href = { url }
                    onClick = { onClose }>
                    <Icon />
                    <span className = { 'more__label' }>{name}</span>
                </a>
            );
        } else if (url) {
            return (
                <Link
                    to = { url }
                    onClick = { onClose }>
                    <Icon />
                    <span className = { 'more__label' }>{name}</span>
                </Link>
            );
        }

        return (
            <a onClick = { onClick }>
                <Icon />
                <span className = { 'more__label' }>{name}</span>
            </a>
        );
    };

    return (
        <>
            <BottomDrawer
                isOpen = { isOpen }
                title = { 'More' }
                onClose = { onClose }>
                <Styles>
                    <ul className = { 'more__menu' }>
                        {menuItems.map(({ onClick, Icon, url, name }, index) => (
                            <li
                                className = { 'more__item' }
                                key = { index }>
                                <div className = { 'more__item-wrapper' }>
                                    {renderMenuItem(Icon, url, name, onClick)}
                                </div>
                            </li>
                        ))}
                    </ul>
                </Styles>
            </BottomDrawer>
        </>
    );
};

export default MobileMoreDrawer;
