// Core
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { I18nProvider } from './modules/i18n/i18n.context';
import { PersistGate } from 'redux-persist/integration/react';
import { CookiesProvider } from 'react-cookie';

// Components
import App from './App';

// Store
import { store, persistor } from './store/storeConfig';

// Instruments
import { ThemeProvider } from 'styled-components';
import * as serviceWorkerRegistration from './init/serviceWorker/serviceWorkerRegistration';
import reportWebVitals from './init/reportWebVitals';
import './init/config/validation.config';

// Styles
import 'antd/dist/antd.css';
import './styles/index.sass';
import theme from './assets/stylesLR';

ReactDOM.render(
    <React.StrictMode>
        <Provider store = { store }>
            <PersistGate
                loading = { null }
                persistor = { persistor }>
                <CookiesProvider>
                    <I18nProvider>
                        <ThemeProvider theme = { theme }>
                            <BrowserRouter>
                                <App/>
                            </BrowserRouter>
                        </ThemeProvider>
                    </I18nProvider>
                </CookiesProvider>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
