// Core
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

// Tools
import { useGetDeliveryRegions } from '../deliveries/useGetDeliveryRegions';

// Types
import {useUserAccount} from "./useUserAccount";

export const useUserAccountAndRegionId = (accountId?: number) => {
  const { deliveryRegions } = useGetDeliveryRegions();
  const { userAccount, updateUserAccount } = useUserAccount(accountId);

  const userAccountAndRegionId = useMemo(() => {
    const foundRegionByName = deliveryRegions.find(({name}) => name === userAccount?.region);

    return userAccount ? {
      ...userAccount,
      regionId: foundRegionByName?.id ?? null
    } : null
  }, [userAccount, deliveryRegions])

  return {
    userAccountAndRegionId,
    deliveryRegions,
    updateUserAccount,
  }
};
