import {
  ACTION_GET_MEALS_LOAD,
  ACTION_GET_MEALS_SUCCESS,
  ACTION_GET_MEALS_ERROR,
  ACTION_GET_MEAL_DETAILS_LOADING,
  ACTION_GET_MEAL_DETAILS_SUCCESS,
  ACTION_GET_MEAL_DETAILS_ERROR,
  ACTION_CLEAR_MEAL_DETAILS,
  ACTION_GET_MEALS_TAGS_SUCCESS,
  ActionType,
  ACTION_GET_MAIN_PROTIENS_OPTIONS_SUCCESS,
} from '../action-types';

import { IMealsState } from 'src/types/state';

const initialState: IMealsState = {
  mealList: {
    loading: false,
    data: [],
    error: null,
    currentPage: 1,
    mealsPerPage: 0,
    total: 0,
  },
  mealDetails: {
    loading: false,
    data: null,
    error: null,
  },
  mealTagsStatic: null,
  mainProteinOptions: []
};

export const meals = (state = initialState, { type, payload }: ActionType<any>): IMealsState => {
  switch (type) {
      case ACTION_GET_MEALS_LOAD:
          return {
              ...state,
              mealList: {
                ...state.mealList,
                loading: true,
              }

          };
      case ACTION_GET_MEALS_SUCCESS:
          return {
            ...state,
            mealList: {
              ...state.mealList,
              loading: false,
              data: payload.data,
              currentPage: payload.meta.current_page,
              mealsPerPage: payload.meta.per_page,
              total: payload.meta.total,
            }
          };
      case ACTION_GET_MEALS_ERROR:
          return {
            ...state,
            mealList: {
              ...state.mealList,
              loading: false,
              error: payload,
            }
          };
      case ACTION_GET_MEAL_DETAILS_LOADING:
          return {
            ...state,
            mealDetails: {
              ...state.mealDetails,
              loading: true,
            }
          };
      case ACTION_GET_MEAL_DETAILS_SUCCESS:
          return {
            ...state,
            mealDetails: {
              ...state.mealDetails,
              loading: false,
              data: payload,
            }
          };
      case ACTION_GET_MEAL_DETAILS_ERROR:
          return {
            ...state,
            mealDetails: {
              ...state.mealDetails,
              loading: false,
              error: payload,
            }
          };
      case ACTION_CLEAR_MEAL_DETAILS:
        return {
          ...state,
          mealDetails: {
            ...state.mealDetails,
            loading: false,
            error: null,
            data: null,
          }
        };
    case ACTION_GET_MEALS_TAGS_SUCCESS:
      return {
        ...state,
        mealTagsStatic: payload
      };
    case ACTION_GET_MAIN_PROTIENS_OPTIONS_SUCCESS:
      return {
        ...state,
        mainProteinOptions: payload || []
      };
      default:
          return state;
  }
};
