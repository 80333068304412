// Core
import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

// Components
import { RegionSelectButton } from './components/RegionSelectButton';
import { ReactComponent as CloseIcon } from 'src/assets/media/icons/close-regions-prompt.svg';

// Tools
import { useUserAccountAndRegionId } from '../../hooks/api/auth/useUserAccountAndRegionId';
import { EventBus } from '../../managers/EventEmitter/index';
import { RootState } from 'src/store/reducers';
import { SHOW_REGION_PROMPT } from 'src/init/constants';

// Styles
import styles from './regionPrompt.module.sass';
import { useCookies } from 'react-cookie';

type RegionPromptType = FC<{ className?: string }>;

export const RegionPrompt: RegionPromptType = ({
    className,
}) => {
    const { isAuthenticated } = useSelector((state: RootState) => state.auth);
    const activeMealPlan = useSelector((state: RootState) => state.mealPlans.activeMealPlan.data);
    const { userAccountAndRegionId, updateUserAccount, deliveryRegions } = useUserAccountAndRegionId();
    const [ cookies, setCookies ] = useCookies();

    const searchParams = new URLSearchParams(location.search);

    const [ isOpened, setIsOpened ] = useState(false);
    const [ showSelectRegionError, setShowSelectRegionError ] = useState(false);
    const [ regionConfirmPopup, setRegionConfirmPopup ] = useState('');
    const closePrompt = () => setIsOpened(false);
    const openPrompt = () => {
        setRegionConfirmPopup('');
        setIsOpened(true);
    };

    const region = userAccountAndRegionId ? userAccountAndRegionId.region : cookies.region;

    const updateRegion = (region: string) => {
        if (isAuthenticated && userAccountAndRegionId) {
            updateUserAccount({ account: { region }, id: userAccountAndRegionId.id });
            setCookies('region', region, { expires: new Date(Date.now() + (24 * 60 * 60 * 1000)) });
        } else if (!isAuthenticated) {
            setCookies('region', region, { expires: new Date(Date.now() + (24 * 60 * 60 * 1000)) });
        }
    };

    const handleRegionButtonClick = (regionName: string) => {
        setShowSelectRegionError(false);
        if (activeMealPlan?.meals_of_plan.length && region) {
            setRegionConfirmPopup(regionName);

            return;
        }

        updateRegion(regionName);
        closePrompt();
    };

    const onCloseButtonClicked = () => {
        if (!region) {
            setShowSelectRegionError(true);

            return;
        }

        closePrompt();
    }

    useEffect(() => {
        if (userAccountAndRegionId && !userAccountAndRegionId.region && !searchParams.get('redirectToCheckout')) {
            if (!cookies.region) {
                setIsOpened(true);
            } else if (cookies.region) {
                updateRegion(cookies.region)
            }
        }
    }, [ userAccountAndRegionId?.region, cookies.region ]);

    useEffect(() => {
        EventBus.on(SHOW_REGION_PROMPT, openPrompt);
        if (!isAuthenticated && !cookies.region) {
            openPrompt();
        }

        return () => void EventBus.off(SHOW_REGION_PROMPT, openPrompt);
    }, []);

    return (
        <>
            {/*@ts-ignore*/}
            <style global = 'true'>
                {
                    isOpened && `
                        #root {
                          position: relative;
                        }
                        
                        #root::after {
                          position: absolute;
                          top: 0;
                          left: 0;
                          height: 100%;
                          width: 100%;
                          display: block;
                          z-index: 110;
                          content: '';
                          background: rgba(34, 34, 34, 0.8);
                          backdrop-filter: blur(15px);
                      }
                    `
                }
            </style>
            <div className = { clsx(className, styles.container, {
                block: isOpened,
            }) }>
                <button
                    className = { styles.close }
                    onClick = { onCloseButtonClicked }>
                    <CloseIcon />
                </button>
                <h3 className = { styles.title }>
                    { regionConfirmPopup ? 'Confirm Region Change' : 'What emirate/area are you from?' }
                </h3>
                {
                    !regionConfirmPopup && (
                        <p className = { styles.message }>
                            We will suggest meals based on where you are from
                        </p>
                    )
                }
                {
                    showSelectRegionError && (
                        <p className = { styles.error }>
                            Please select a region before proceeding!
                        </p>
                    )
                }
                {regionConfirmPopup ? (
                    <div className = { styles.confirmRegion }>
                        <p className = { clsx(styles.message, 'my-10 md:mt-14 md:mb-16') }>
                            Your current selected region
                            is { region }.
                            Changing it to { regionConfirmPopup } might affect the meal prices and availability.
                            <br /><br />
                            Please confirm you still want to change your region to { regionConfirmPopup }.

                        </p>
                        <div className = { styles.buttonGrp }>
                            <button
                                className = { styles.buttonSecondary }
                                onClick = { () => {
                                    closePrompt();
                                } }>
                                Cancel
                            </button>
                            <button
                                className = { styles.buttonPrimary }
                                onClick = { () => {
                                    updateRegion(regionConfirmPopup);
                                    closePrompt();
                                } }>
                                Confirm
                            </button>
                        </div>
                    </div>
                ) :

                    <div className = 'grid grid-cols-2 md:grid-cols-3 gap-x-2 md:gap-x-7'>
                        {deliveryRegions.map(({ id, name }) => {
                            const isSelected = region === name;

                            return (
                                <RegionSelectButton
                                    className = 'mb-4'
                                    handleClick = { () => handleRegionButtonClick(name) }
                                    isSelected = { isSelected }
                                    key = { id }
                                    text = { name }
                                />
                            );
                        })}
                    </div>
                }
            </div>
        </>
    );
};
