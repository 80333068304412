import {
  ACTION_WIZARD_GET_RESULTS_LOAD,
  ACTION_WIZARD_GET_RESULTS_SUCCESS,
  ACTION_WIZARD_GET_RESULTS_ERROR,
  ACTION_WIZARD_GET_FOOD_PREFERENCES_LOAD,
  ACTION_WIZARD_GET_FOOD_PREFERENCES_SUCCESS,
  ACTION_WIZARD_GET_FOOD_PREFERENCES_ERROR,
  ACTION_WIZARD_GET_SUGGESTIONS_FROM_TARGETS_LOAD,
  ACTION_WIZARD_GET_SUGGESTIONS_FROM_TARGETS_SUCCESS,
  ACTION_WIZARD_GET_SUGGESTIONS_FROM_TARGETS_ERROR,
  ActionType,
} from '../action-types';

import { IWizardState } from 'src/types/state';

const initialState: IWizardState = {
  results: {
    loading: false,
    error: null,
    data: null
  },
  foodPreferences: {
    loading: false,
    error: null,
    data: null
  },
  suggestions: {
    loading: false,
    error: null,
    data: null,
  }
};

export const wizard = (state = initialState, { type, payload }: ActionType<any>): IWizardState => {
  switch (type) {
      case ACTION_WIZARD_GET_RESULTS_LOAD:
          return {
              ...state,
              results: {
                ...state.results,
                loading: true,
              },
          };
      case ACTION_WIZARD_GET_RESULTS_SUCCESS:
          return {
              ...state,
              results: {
                ...state.results,
                loading: false,
                data: payload
              }
          };
      case ACTION_WIZARD_GET_RESULTS_ERROR:
          return {
              ...state,
              results: {
                ...state.results,
                loading: false,
                error: payload
              },
          };
      case ACTION_WIZARD_GET_FOOD_PREFERENCES_LOAD:
        return {
            ...state,
            foodPreferences: {
              ...state.foodPreferences,
              loading: true,
            },
        };
      case ACTION_WIZARD_GET_FOOD_PREFERENCES_SUCCESS:
        return {
            ...state,
            foodPreferences: {
              ...state.foodPreferences,
              loading: false,
              data: payload
            }
        };
      case ACTION_WIZARD_GET_FOOD_PREFERENCES_ERROR:
        return {
            ...state,
            foodPreferences: {
              ...state.foodPreferences,
              loading: false,
              error: payload
            },
        };
      case ACTION_WIZARD_GET_SUGGESTIONS_FROM_TARGETS_LOAD:
        return {
            ...state,
            suggestions: {
              ...state.suggestions,
              loading: true,
            },
        };
      case ACTION_WIZARD_GET_SUGGESTIONS_FROM_TARGETS_SUCCESS:
        return {
            ...state,
            suggestions: {
              ...state.suggestions,
              loading: false,
              data: payload
            }
        };
      case ACTION_WIZARD_GET_SUGGESTIONS_FROM_TARGETS_ERROR:
        return {
            ...state,
            suggestions: {
              ...state.suggestions,
              loading: false,
              error: payload
            },
        };
      default:
          return state;
  }
};
