import React, { FC } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './MobileSlider.sass';

interface Props {
    children: React.ReactNode
}

const MobileSlider: FC<Props> = ({ children }) => {
    const settings = {
        className:      'center',
        centerMode:     true,
        focusOnSelect:  true,
        infinite:       true,
        arrows:         false,
        useTransform:   true,
        slidesToShow:   1,
        slidesToScroll: 1,
        speed:          500,
        responsive:     [
            {
                breakpoint: 375,
                settings:   {
                    centerPadding: '15%',
                },
            },
            {
                breakpoint: 400,
                settings:   {
                    centerPadding: '16%',
                },
            },
            {
                breakpoint: 450,
                settings:   {
                    centerPadding: '19%',
                },
            },
            {
                breakpoint: 500,
                settings:   {
                    centerPadding: '23%',
                },
            },
            {
                breakpoint: 550,
                settings:   {
                    centerPadding: '26%',
                },
            },
            {
                breakpoint: 600,
                settings:   {
                    centerPadding: '29%',
                },
            },
            {
                breakpoint: 650,
                settings:   {
                    centerPadding: '31%',
                },
            },
            {
                breakpoint: 700,
                settings:   {
                    centerPadding: '32%',
                },
            },
            {
                breakpoint: 800,
                settings:   {
                    centerPadding: '33%',
                },
            },
        ],
    };

    return (
        <Slider { ...settings }>
            {children}
        </Slider>
    );
};

export default MobileSlider;
