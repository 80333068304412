// Core
import React, { FC } from 'react';
import clsx from 'clsx';
import {  useKeenSlider } from 'keen-slider/react';

// Assets
import { ReactComponent as ClockIcon } from 'src/assets/media/icons/home/clock-icon.svg';
import { ReactComponent as DollarIcon } from 'src/assets/media/icons/home/dollar-icon.svg';
import { ReactComponent as WorkoutIcon } from 'src/assets/media/icons/home/workout-icon.svg';
import LogoImg from 'src/assets/media/trainx-white-logo.svg';

// Styles
import styles from '../whyEatRight.module.sass';
import 'keen-slider/keen-slider.min.css';

type WhyEatRightSectionType = FC<{ className?: string }>;


const tableBodyData = [
    [
        { content: null, id: -1 },
        {
            content: (
                <div className = 'flex items-center'>
                    <span className = 'inline-block md:mr-5'>
                        <ClockIcon />
                    </span>
                    <span className = { `${styles['table__row-name']} mr-2` }>
                        Time
                    </span>
                </div>
            ),
            id: 0,
        },
        {
            content: (
                <div className = 'flex items-center'>
                    <span className = 'inline-block md:mr-3'>
                        <DollarIcon />
                    </span>
                    <span className = { styles['table__row-name'] }>
                        Quality
                    </span>
                </div>
            ),
            id: 1,
        },
        {
            content: (
                <div className = 'flex items-center'>
                    <span className = 'inline-block md:mr-4'>
                        <WorkoutIcon />
                    </span>
                    <span className = { styles['table__row-name'] }>
                        Effort
                    </span>
                </div>
            ),
            id: 2,
        },
    ],
    [
        {
            content: (
                <img
                    alt = 'Eat Right Logo'
                    className = { styles.logo }
                    src = { LogoImg }
                />
            ),
            id: 3,
        },
        {
            content: (
                <p className = { styles['table__cell-text'] }>
                    ~5 minutes!<br />
                    per meal
                </p>
            ),
            id: 4,
        },
        {
            content: (
                <p className = { `${styles['table__cell-text-sm']}` }>
                   Highest quality, fresh meals prepared by industry-standard leading kitchen
                </p>
            ),
            id: 5,
        },
        {
            content: (
                <p className = { styles['table__cell-text-sm'] }>
                    Dietitian curated meals delivered to your door
                </p>
            ),
            id: 6,
        },
    ],
    [
        {
            content: (
                <p className = { styles['table__header-text'] }>
                    Dining Out
                </p>
            ),
            id: 7,
        },
        {
            content: (
                <p className = { styles['table__cell-text'] }>
                    ~1.5 hours<br/>
                    <span className = { styles['table__cell-caption'] }>per meal</span>
                </p>
            ),
            id: 8,
        },
        {
            content: (
                <p className = { `${styles['table__cell-text-sm']}` }>
                   Never know what quality to expect, good or bad
                </p>
            ),
            id: 9,
        },
        {
            content: (
                <p className = { styles['table__cell-text-sm'] }>
                    Travel time, parking, wait time, meal prep and service
                </p>
            ),
            id: 10,
        },
    ],
    [
        {
            id:      2,
            content: (
                <p className = { styles['table__header-text'] }>
                    Cooking
                </p>
            ),
        },
        {
            content: (
                <p className = { styles['table__cell-text'] }>
                    ~1 hour<br />
                    <span className = { styles['table__cell-caption'] }>per meal</span>
                </p>
            ),
            id: 11,
        },
        {
            content: (
                <p className = { `${styles['table__cell-text-sm']}` }>
                   High quality at home is at least 2 times the price of our meals
                </p>
            ),
            id: 12,
        },
        {
            content: (
                <p className = { styles['table__cell-text-sm'] }>
                    Planning, shopping, preparation and cook time
                </p>
            ),
            id: 13,
        },
    ],
];

type TableColType = FC<{
    className?: string;
    // columnData: typeof tableBodyData
    columnData: {
        content: JSX.Element | null,
        id: number
    }[];
}>

const TableCol: TableColType = ({ className, columnData }) => {
    return (
        <div
            className = { clsx(styles.table__col, className) }
            key = { columnData[0].id }>
            {
                columnData.map(({ id, content }) => (
                    <div
                        className = { styles.table__cell }
                        key = { id }
                        style={{ padding: [5].includes(id) ? '8px' : '' }}    
                    >
                        {content}
                    </div>
                ))
            }
        </div>
    );
};

function ArrowLeft(props: { disabled: boolean, onClick: (event: React.MouseEvent<SVGElement>) => void }) {
    const disabeld = props.disabled ? styles.arrowDisabled : '';

    return (
        <svg
            className = { clsx(styles.arrow, styles.arrowLeft, disabeld) }
            viewBox = '0 0 24 24'
            xmlns = 'http://www.w3.org/2000/svg'
            onClick = { props.onClick }>
            <path d = 'M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z' />
        </svg>
    );
}

function ArrowRight(props: { disabled: boolean, onClick: (event: React.MouseEvent<SVGElement>) => void }) {
    const disabeld = props.disabled ? styles.arrowDisabled : '';

    return (
        <svg
            className = { clsx(styles.arrow, styles.arrowRight, disabeld) }
            viewBox = '0 0 24 24'
            xmlns = 'http://www.w3.org/2000/svg'
            onClick = { props.onClick }>
            <path d = 'M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z' />
        </svg>
    );
}


export const WhyEatRightTable: WhyEatRightSectionType = ({
    className,
}) => {
    const [ currentSlide, setCurrentSlide ] = React.useState(0);
    const [ sliderRef, slider ] = useKeenSlider({
        spacing: 0,
        slideChanged(s) {
            setCurrentSlide(s.details().relativeSlide);
        },
    });

    return (
        <div className = { clsx('common-container flex justify-center px-0', className) }>
            <div className = { clsx(styles.table, 'hidden md:flex') }>
                {
                    tableBodyData.map((columnData, index) => (
                        <TableCol
                            className = { clsx({
                                [styles['table__col--highlight']]: index === 1,
                            }) }
                            columnData = { columnData }
                            key = { columnData[0].id }
                        />
                    ))
                }
            </div>
            <div className = { clsx(styles.table, 'flex md:hidden') }>
                <TableCol
                    className = { styles.tableColFirst }
                    columnData = { tableBodyData[0] }
                />
                <div
                    className = { styles.slider }
                    ref = { sliderRef as any }>
                    {
                        tableBodyData.slice(1).map((colData, index) => (
                            <div
                                className = { clsx('keen-slider__slide', styles.slide) }>
                                <TableCol
                                    className = { clsx({
                                        [styles['table__col--highlight']]: index === 0,
                                    }) }
                                    columnData = { colData }
                                    key = { colData[0].id }
                                />
                            </div>

                        ))
                    }
                </div>
                {slider && (
                    <>
                        <ArrowLeft
                            disabled = { currentSlide === 0 }
                            onClick = { (event) => {
                                event.stopPropagation();
                                slider.prev();
                            } }
                        />
                        <ArrowRight
                            disabled = { currentSlide === slider.details().size - 1 }
                            onClick = { (event) => {
                                event.stopPropagation();
                                slider.next();
                            } }
                        />
                    </>
                )}
            </div>
        </div>
    );
};
