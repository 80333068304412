import { takeLatest, put, call } from 'redux-saga/effects';
import { ActionType, ACTION_GET_LOYALTY_REDEMPTIONS_ERROR, ACTION_GET_LOYALTY_REDEMPTIONS_REQUEST, ACTION_GET_LOYALTY_REDEMPTIONS_SUCCESS, ACTION_GET_LOYALTY_WALLET_ERROR, ACTION_GET_LOYALTY_WALLET_REQUEST, ACTION_GET_LOYALTY_WALLET_SUCCESS } from 'src/store/action-types';
import api from '../../../managers/api';

export function* sagaLoyaltyWatcher() {
    yield takeLatest(ACTION_GET_LOYALTY_REDEMPTIONS_REQUEST, getLoyaltyRedemptionWorker);
    yield takeLatest(ACTION_GET_LOYALTY_WALLET_REQUEST, getLoyaltyWalletWorker);
}

function* getLoyaltyRedemptionWorker({ payload }: ActionType<{ page: number }>) {
    try {
      	const redemptions: any[] = yield call(() => api.get(`/loyalty-redemptions?per_page=5&page=${payload.page}`, {baseURL: process.env.REACT_APP_ALLRIGHT_BASE_API_URL }).then(res => res.data));
      	yield put({ type: ACTION_GET_LOYALTY_REDEMPTIONS_SUCCESS, payload: redemptions });
    } catch (error: any) {
      	yield put({ type: ACTION_GET_LOYALTY_REDEMPTIONS_ERROR, payload: error?.response?.data?.message})
    }
}

function* getLoyaltyWalletWorker({ payload }: ActionType<{ page: number }>) {
    try {
      	const wallet: any[] = yield call(() => api.get(`/loyalty-wallet?per_page=5&page=${payload.page}`, {baseURL: process.env.REACT_APP_ALLRIGHT_BASE_API_URL }).then(res => res.data));
      	yield put({ type: ACTION_GET_LOYALTY_WALLET_SUCCESS, payload: wallet });
    } catch (error: any) {
      	yield put({ type: ACTION_GET_LOYALTY_WALLET_ERROR, payload: error?.response?.data?.message})
    }
}