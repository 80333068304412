import {
    ACTION_GET_COUNTRIES_LOAD,
    ACTION_GET_COUNTRIES_SUCCESS,
    ACTION_GET_COUNTRIES_ERROR,
    ACTION_GET_REGIONS_LOAD,
    ACTION_GET_REGIONS_SUCCESS,
    ACTION_GET_REGIONS_ERROR,
    ACTION_GET_LANGUAGES_LOAD,
    ACTION_GET_LANGUAGES_SUCCESS,
    ACTION_GET_LANGUAGES_ERROR,
    ActionType,
} from '../action-types';

import { ILocaleState } from 'src/types/state';

const initialState: ILocaleState = {
    countryList: {
        loading: false,
        error: null,
        data: []
    },
    regionList: {
        loading: false,
        error: null,
        data: []
    },
    languageList: {
        loading: false,
        error: null,
        data: []
    }
};

export const locale = (state = initialState, { type, payload }: ActionType<any>): ILocaleState => {
    switch (type) {
        case ACTION_GET_COUNTRIES_LOAD:
            return {
                ...state,
                countryList: {
                    ...state.countryList,
                    loading: true,
                },
            };
        case ACTION_GET_COUNTRIES_SUCCESS:
            return {
                ...state,
                countryList: {
                    ...state.countryList,
                    loading: false,
                    data:    payload,
                },
            };
        case ACTION_GET_COUNTRIES_ERROR:
            return {
                ...state,
                countryList: {
                    ...state.countryList,
                    loading: false,
                    error:   payload,
                },
            };
        case ACTION_GET_REGIONS_LOAD:
            return {
                ...state,
                regionList: {
                    ...state.regionList,
                    loading: true,
                },
            };
        case ACTION_GET_REGIONS_SUCCESS:
            return {
                ...state,
                regionList: {
                    ...state.regionList,
                    loading: false,
                    data:    payload,
                },
            };
        case ACTION_GET_REGIONS_ERROR:
            return {
                ...state,
                regionList: {
                    ...state.regionList,
                    loading: false,
                    error:   payload,
                },
            };
        case ACTION_GET_LANGUAGES_LOAD:
            return {
                ...state,
                languageList: {
                    ...state.languageList,
                    loading: true,
                },
            };
        case ACTION_GET_LANGUAGES_SUCCESS:
            return {
                ...state,
                languageList: {
                    ...state.languageList,
                    loading: false,
                    data:    payload,
                },
            };
        case ACTION_GET_LANGUAGES_ERROR:
            return {
                ...state,
                languageList: {
                    ...state.languageList,
                    loading: false,
                    error:   payload,
                },
            };
        default:
            return state;
    }
};