import {
  ACTION_GET_MACRONUTRIENTS_LOAD,
  ACTION_GET_MACRONUTRIENTS_SUCCESS,
  ACTION_GET_MACRONUTRIENTS_ERROR,
  ActionType,
} from '../action-types';

import { IMacronutrientsState } from 'src/types/state';

const initialState: IMacronutrientsState = {
    loading: false,
    data: null,
    error: null,
};

export const macronutrients = (state = initialState, { type, payload }: ActionType<any>): IMacronutrientsState => {
  switch (type) {
      case ACTION_GET_MACRONUTRIENTS_LOAD:
          return {
              ...state,
              loading: true,
          };
      case ACTION_GET_MACRONUTRIENTS_SUCCESS:
          return {
            ...state,
            loading: false,
            data: payload,
          };
      case ACTION_GET_MACRONUTRIENTS_ERROR:
          return {
            ...state,
            loading: false,
            data: null,
            error: payload,
          };
      default:
          return state;
  }
};
