import React from 'react';
import ReactDOM from 'react-dom';

type PortalProps = React.FC<{ children: React.ReactNode }>

const Portal: PortalProps = ({ children }) => {
    const [ container ] = React.useState(() => {
        return document.createElement('div');
    });

    React.useEffect(() => {
        document.body.appendChild(container);

        return () => {
            document.body.removeChild(container);
        };
    }, []);

    return ReactDOM.createPortal(children, container);
};

export default Portal;
