import React, { useState, useEffect, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { Select, Tooltip } from 'antd';

import { tooltipStyle } from '../../../../styles/tooltip';
import Notification from 'src/components/Notification';
import { DesktopLayout, MobileLayout } from 'src/layouts';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import { MealNutrients } from 'src/components/Meal/components/MealNutrients';

import { ReactComponent as InfoIcon } from 'src/assets/media/icons/info.svg';
import { ReactComponent as MinusIcon } from 'src/assets/media/icons/minus.svg';
import { ReactComponent as PlusIcon } from 'src/assets/media/icons/plus.svg';
import { ReactComponent as UpArrowIcon } from 'src/assets/media/icons/arrow-up.svg';
import { ReactComponent as DownArrowIcon } from 'src/assets/media/icons/arrow-down.svg';
import { ReactComponent as LeftArrowIcon } from 'src/assets/media/icons/left-arrow.svg';
import { ReactComponent as CrossIcon } from 'src/assets/media/icons/cross.svg';
import { ReactComponent as CheckIcon } from 'src/assets/media/icons/check.svg';

import { ROUTE_CONSTANTS } from 'src/init/constants';
import {
    ACTION_ADD_MEAL_TO_MEAL_PLAN_REQUEST,
    ACTION_CLEAR_MEAL_DETAILS_REQUEST,
    ACTION_GET_ACTIVE_MEAL_PLAN_REQUEST,
    ACTION_GET_MACRONUTRIENTS_REQUEST,
    ACTION_GET_MEAL_DETAILS_REQUEST,
    ACTION_GET_MEAL_PLANS_REQUEST,
} from 'src/store/action-types';
import { GetMacronutrientsPayload } from 'src/store/actions/macronutrients';


import useGetUserRegion from 'src/hooks/api/deliveries/useGetUserRegion';
import { useMediaQuery } from 'react-responsive';
import useMealPlan from 'src/hooks/api/mealPlan/useMealPlan';
import { getItemFromLocalStorage, setItemInLocalStorage } from 'src/utils';

import { IRootState } from 'src/types/state';
import { IComponent } from 'src/types/component';

import styles from './PersonalizeAndAdd.module.sass';

const { Option } = Select;

const mapDayToResponseField: { [key: string]: string } = {
    MON: 'monday',
    TUE: 'tuesday',
    WED: 'wednesday',
    THU: 'thursday',
    FRI: 'friday',
    SAT: 'saturday',
    SUN: 'sunday',
};

interface Props {
    open: boolean;
    mealId: number | null;
    selectedMealPlanId: number;
    onModalClose?: () => void;
    refetchMeal?: boolean;
    clearMealOnClose?: boolean;
    closeModalLabel? : string;
}

const PersonalizeAndAdd: React.FC<Props> = ({
    open,
    mealId,
    selectedMealPlanId,
    onModalClose,
    refetchMeal = true,
    clearMealOnClose = true,
    closeModalLabel = 'Return to Meals',
}) => {
    const history = useHistory();

    const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });

    const auth = useSelector((state: IRootState) => state.auth);
    const mealDetails = useSelector((state: IRootState) => state?.meals?.mealDetails?.data);
    const mealPlans = useSelector((state: IRootState) => state.mealPlans.mealPlanList.data);
    const macronutrients = useSelector((state: IRootState) => state.macronutrients.data);
    const backendTargets = useSelector((state: IRootState) => state.targets.data);
    const localTargets = getItemFromLocalStorage('wizard-known-targets');

    const { activeMealPlan } = useMealPlan();

    const dispatch: Dispatch<any> = useDispatch();

    const region = useGetUserRegion();

    const weekdays = useMemo(() => {
        const weekdays = [
            { key: 'mon', label: 'Monday' },
            { key: 'tue', label: 'Tuesday' },
            { key: 'wed', label: 'Wednesday' },
            { key: 'thu', label: 'Thursday' },
            { key: 'fri', label: 'Friday' },
            { key: 'sat', label: 'Saturday' },
            { key: 'sun', label: 'Sunday' },
        ];

        return weekdays
    }, [ region ]);

    const macronutrientsDisplayModes = useMemo(() => {
        const macronutrientsDisplayModes = [
            'This Meal',
            'MON',
            'TUE',
            'WED',
            'THU',
            'FRI',
            'SAT',
            'SUN',
        ];

        return macronutrientsDisplayModes
    }, [ region ]);

    const [ selectedOptions, setSelectedOptions ] =
        useState<{ componentId: number, optionId: number, price: number }[]>([]);
    const [ , setUsePastSelection ] = useState(false);
    const [ mealsPerDay, setMealsPerDay ] = useState<{ [key: string]: number }>({
        Monday:    0,
        Tuesday:   0,
        Wednesday: 0,
        Thursday:  0,
        Friday:    0,
        Saturday:  0,
        Sunday:    0,
    });
    const [ macronutrientsDisplayMode, setMacronutrientsDisplayMode ] = useState('This Meal');
    const [ totalMacronutrients, setTotalMacronutrients ] = useState({
        proteins:   0,
        calories:   0,
        fat:        0,
        netCarbs:   0,
        sugar:      0,
        fiber:      0,
        totalCarbs: 0,
    });
    const [ totalCost, setTotalCost ] = useState(0);
    const [ daysError, setDaysError ] = useState(false);
    const [ optionsError, setOptionsError ] = useState(false);
    const [ targets, setTargets ] = useState<{ [key: string]: any } | null>(null);
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        if (auth.isAuthenticated) {
            setTargets(backendTargets || null);
        } else {
            const parsedTargets = localTargets ? JSON.parse(localTargets) : null;

            setTargets({
                calories:  parsedTargets?.calories ? Number(parsedTargets.calories) : 0,
                fat:       parsedTargets?.fat ? Number(parsedTargets.fat) : 0,
                net_carbs: parsedTargets?.netCarbs ? Number(parsedTargets.netCarbs) : 0,
                proteins:  parsedTargets?.protein ? Number(parsedTargets.protein) : 0,
            });
        }
    }, [ backendTargets, localTargets ]);

    // saved options from the past selected meal
    useEffect(() => {
        if (mealDetails) {
            // Preload component images
            mealDetails.components?.forEach((component) => {
                new Image().src = component.photo;
            });

            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            calculateTotalCost();
        }
    }, [ mealDetails ]);

    useEffect(() => {
        if (refetchMeal && mealId) {
            dispatch({ type: ACTION_GET_MEAL_DETAILS_REQUEST, payload: { mealId: mealId }});
        }
    }, [ mealId ]);

    useEffect(() => {
        if (open && mealId) {
            const payload :GetMacronutrientsPayload = {
                mealId,
                selectedOptions,
            };
            if (selectedMealPlanId) {
                payload.mealPlanId = selectedMealPlanId;
            }
            dispatch({ type: ACTION_GET_MACRONUTRIENTS_REQUEST, payload });
        }
    }, [ selectedMealPlanId, selectedOptions, open, mealId ]);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        calculateTotalCost();
    }, [ selectedOptions ]);

    useEffect(() => {
        if (macronutrients && mealDetails) {
            setTotalMacronutrients({
                proteins:   macronutrients.current_meal.proteins,
                calories:   macronutrients.current_meal.calories,
                fat:        macronutrients.current_meal.fat,
                netCarbs:   macronutrients.current_meal.net_carbs,
                sugar:      macronutrients.current_meal.sugar,
                fiber:      macronutrients.current_meal.fiber,
                totalCarbs: macronutrients.current_meal.total_carbs,
            });
        } else if (mealDetails && !selectedMealPlanId) {
            setTotalMacronutrients({
                proteins:   mealDetails.nutrients.proteins,
                calories:   mealDetails.nutrients.calories,
                fat:        mealDetails.nutrients.fat,
                netCarbs:   mealDetails.nutrients.net_carbs,
                sugar:      mealDetails.nutrients.sugar,
                fiber:      mealDetails.nutrients.fiber,
                totalCarbs: mealDetails.nutrients.total_carbs,
            });
        }
    }, [ macronutrients, mealDetails ]);

    useEffect(() => {
        if (!macronutrients) {
            return;
        }

        switch (macronutrientsDisplayMode) {
            case 'This Meal':
                setTotalMacronutrients({
                    proteins:   macronutrients.current_meal.proteins,
                    calories:   macronutrients.current_meal.calories,
                    fat:        macronutrients.current_meal.fat,
                    netCarbs:   macronutrients.current_meal.net_carbs,
                    sugar:      macronutrients.current_meal.sugar,
                    fiber:      macronutrients.current_meal.fiber,
                    totalCarbs: macronutrients.current_meal.total_carbs,
                });
                break;
            case 'This Meal on Your Plan':
                setTotalMacronutrients({
                    proteins:   macronutrients.current_meal.proteins + macronutrients.meal_plan.proteins,
                    calories:   macronutrients.current_meal.calories + macronutrients.meal_plan.calories,
                    fat:        macronutrients.current_meal.fat + macronutrients.meal_plan.fat,
                    netCarbs:   macronutrients.current_meal.net_carbs + macronutrients.meal_plan.net_carbs,
                    sugar:      macronutrients.current_meal.sugar + macronutrients.meal_plan.sugar,
                    fiber:      macronutrients.current_meal.fiber + macronutrients.meal_plan.fiber,
                    totalCarbs: macronutrients.current_meal.total_carbs + macronutrients.meal_plan.total_carbs,
                });
                break;
            case 'Your Plan':
                setTotalMacronutrients({
                    proteins:   macronutrients.meal_plan.proteins,
                    calories:   macronutrients.meal_plan.calories,
                    fat:        macronutrients.meal_plan.fat,
                    netCarbs:   macronutrients.meal_plan.net_carbs,
                    sugar:      macronutrients.meal_plan.sugar,
                    fiber:      macronutrients.meal_plan.fiber,
                    totalCarbs: macronutrients.meal_plan.total_carbs,
                });
                break;
            default: {
                const calcTotalForDay = (mode:string, field:string) =>{
                    const day = mapDayToResponseField[mode];
                    const dayCapitalized = day[0].toUpperCase() + day.substr(1);
                    const fromPlan =  macronutrients.meal_plan.days[day][field] || 0;
                    const fromMeal =  macronutrients.current_meal[field] || 0;

                    return (mealsPerDay[dayCapitalized] * fromMeal) + fromPlan;
                };
                setTotalMacronutrients({
                    proteins:   calcTotalForDay(macronutrientsDisplayMode, 'proteins'),
                    calories:   calcTotalForDay(macronutrientsDisplayMode, 'calories'),
                    fat:        calcTotalForDay(macronutrientsDisplayMode, 'fat'),
                    netCarbs:   calcTotalForDay(macronutrientsDisplayMode, 'net_carbs'),
                    sugar:      calcTotalForDay(macronutrientsDisplayMode, 'sugar'),
                    fiber:      calcTotalForDay(macronutrientsDisplayMode, 'fiber'),
                    totalCarbs: calcTotalForDay(macronutrientsDisplayMode, 'total_carbs'),
                });
                break;
            }
        }
    }, [ macronutrientsDisplayMode, mealsPerDay, macronutrients ]);

    // Clear old state on modal close
    useEffect(() => {
        if (!open) {
            setSelectedOptions([]);
            setMealsPerDay({
                Monday:    0,
                Tuesday:   0,
                Wednesday: 0,
                Thursday:  0,
                Friday:    0,
                Saturday:  0,
                Sunday:    0,
            });
            setTotalMacronutrients({
                proteins:   0,
                calories:   0,
                fat:        0,
                netCarbs:   0,
                sugar:      0,
                fiber:      0,
                totalCarbs: 0,
            });
            setTotalCost(0);
            setUsePastSelection(false);
        } else {
            // dispatch({ type:    ACTION_GET_MACRONUTRIENTS_REQUEST, payload: {
            //     mealId:          mealId,
            //     mealPlanId:      selectedMealPlanId,
            //     selectedOptions: [],
            // }});
        }
    }, [ open ]);

    const optionOnChangeHandler = (option: any, componentId: number) => {
        if (option.value === 0) {
            setSelectedOptions(selectedOptions.filter((option) => option.componentId !== componentId));

            return;
        }

        const price = option.children.props.children[1].props.children[0] || 0;
        const currentOption = { optionId: option.value, componentId, price };
        const index = selectedOptions.map((el) => el.componentId).indexOf(currentOption.componentId);

        // Check if we already have selected option for this component
        if (index >= 0) {
            const newSelectedOptions = [ ...selectedOptions ];
            newSelectedOptions[index] = currentOption;
            setSelectedOptions(newSelectedOptions);
        } else {
            setSelectedOptions(selectedOptions.concat(currentOption));
        }

        setOptionsError(false);
    };

    const incrementMealsPerDay = (day: string) => {
        setDaysError(false);
        setMealsPerDay((prevState: any) => (
            { ...mealsPerDay, [day]: prevState[day] + 1 }
        ));
    };

    const decrementMealsPerDay = (day: string) => {
        if (mealsPerDay[day] <= 0) {
            return;
        }

        setMealsPerDay((prevState: any) => (
            { ...mealsPerDay, [day]: prevState[day] - 1 }
        ));
    };

    const calculateTotalCost = () => {
        if (mealDetails) {
            let optionsPrice = 0;
            selectedOptions.forEach((option) => {
                optionsPrice += option.price;
            });

            const result = mealDetails.base_price + optionsPrice;
            setTotalCost(result);
        }
    };

    const addMealToMealPlan = (redirect?: boolean) => {
        setLoading(true);
        const payload = {
            mealId:            mealDetails?.id,
            mealPlanId:        selectedMealPlanId,
            mealsPerDay,
            selectedOptions,
            createNewMealPlan: !selectedMealPlanId,
            newMealPlanName:   !selectedMealPlanId ? `Meal plan ${mealPlans?.length ? mealPlans.length + 1 : ''}` : '',
            onSuccess:         () => {
                setLoading(false);
                // Refresh meal plans data
                dispatch({ type: ACTION_GET_MEAL_PLANS_REQUEST });
                dispatch({ type: ACTION_GET_ACTIVE_MEAL_PLAN_REQUEST });
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                closeModal();
                if (redirect) {
                    if (auth.isAuthenticated) {
                        history.push(selectedMealPlanId ? `/plans/${selectedMealPlanId}/edit` : '/orders');
                    } else {
                        history.push('/cart');
                    }
                }
            },
        };

        dispatch({ type: ACTION_ADD_MEAL_TO_MEAL_PLAN_REQUEST, payload });
    };

    const closeModal = () => {
        clearMealOnClose && dispatch({ type: ACTION_CLEAR_MEAL_DETAILS_REQUEST });
        setDaysError(false);
        setOptionsError(false);
        onModalClose && onModalClose();
    };

    const getDayStyle = (day: string) => {
        if (daysError) {
            return { border: '1px solid #EF1733' };
        }

        if (mealsPerDay[day] > 0) {
            return { border: '1px solid #90BF45' };
        }
    };

    const checkFormIsValid = () => {
        let isValid = true;

        if (!Object.values(mealsPerDay).some((day) => day !== 0)) {
            isValid = false;
            setDaysError(true);
        }
        if (selectedOptions.length === 0 && mealDetails?.meal_type !== 'pre-set') {
            isValid = false;
            setOptionsError(true);
        }

        return isValid;
    };

    const addMoreFoodHandler = () => {
        if (!checkFormIsValid()) {
            return;
        }

        setItemInLocalStorage('last-selected-options', JSON.stringify(selectedOptions));

        addMealToMealPlan();
        history.push('/meals');
    };

    const goToMyPlanHandler = () => {
        if (!checkFormIsValid()) {
            return;
        }

        setItemInLocalStorage('last-selected-options', JSON.stringify(selectedOptions));

        addMealToMealPlan(true);
    };

    const renderMealType = (mealType?: string) => {
        switch (mealType) {
            case 'pre-set':
                return (
                    <span
                        className = { styles.mealType }
                        style = {{ backgroundColor: '#90BF45' }}>
                        Pre-set meal
                    </span>
                );
            case 'customisable':
                return (
                    <span
                        className = { styles.mealType }
                        style = {{ backgroundColor: '#EF1733' }}>
                        Build Your Own
                    </span>
                );
            default:
                return null;
        }
    };

    const getMealQuantity = (day: string) => {
        let quantity = 0;

        if (!auth.isAuthenticated && activeMealPlan) {
            quantity = activeMealPlan.meals_of_plan.filter((meal) => meal.day_week === day.toLowerCase()).length || 0;
        } else {
            const planToCheck = activeMealPlan?.draft || activeMealPlan;
            quantity = planToCheck?.meals_of_plan
                .filter((meal) => meal.day_week === day.toLowerCase()).length || 0;
        }

        return quantity;
    };

    const renderMealQuantity = (day: string) => {
        const quantity = getMealQuantity(day);
        if (quantity && quantity === 1) {
            return `${quantity} meal`;
        } else if (quantity && quantity > 1) {
            return `${quantity} meals`;
        }

        return 'No meals';
    };

    const renderTargetsLink = () => {
        if (!targets) {
            return (
                <div className = { styles.targetsLink }>
                    You can set your nutrition target!
                    <Link to = { ROUTE_CONSTANTS.TARGETS }>Set Your Nutrition Targets</Link>
                </div>
            );
        }

        for (const key in targets) {
            if (
                targets.hasOwnProperty(key) &&
                targets[key] === 0 &&
                ![ 'calories', 'sugar', 'total_carbs' ].includes(key)
            ) {
                return (
                    <div className = { styles.targetsLink }>
                        You can set your nutrition target!
                        <Link to = { ROUTE_CONSTANTS.TARGETS }>Set Your Nutrition Targets</Link>
                    </div>
                );
            }
        }

        return null;
    };

    const renderComponentsOptions = (component: IComponent) => {
        component.options.sort((o1, o2) => {
            const name1 = o1.name.replace(/[0-9]/g, '');
            const name2 = o2.name.replace(/[0-9]/g, '');
            if (name1 > name2) {
                return 1;
            } else if (name1 < name2) {
                return -1;
            }

            return (o1.price || 0) - (o2.price || 0);
        });
        const allOptions = [{ id: 0, name: 'None' }, ...component.options ];
        const options = allOptions.map((option) => {
            if (option.id === 0) {
                return (
                    <Option
                        key = { option.id }
                        value = { option.id }>
                        {option.name}
                    </Option>
                );
            }

            return (
                <Option
                    key = { option.id }
                    value = { option.id }>
                    <div className = 'flex'>
                        <span className = { styles.truncate }>{option.name} - </span>
                        <span className = { styles.selectCurrency }>
                            { Number(option.price?.toFixed(2)) } AED
                        </span>
                    </div>
                </Option>
            );
        });

        return options;
    };

    if (open) {
        return (
            <>
                {/*@ts-ignore*/}

                {loading && <LoadingOverlay />}

                <style
                    global>
                    {
                        open && `
                            #root {
                              position: relative;
                            }
                        
                            #root::after {
                              position: absolute;
                              top: 0;
                              left: 0;
                              height: 100%;
                              width: 100%;
                              display: block;
                              z-index: 115;
                              content: '';
                              background: rgba(0, 0, 0, 0.7);
                          }
                          
                          .wizard-lower-container {
                            position: static;
                          }
                        `
                    }
                </style>
                <div
                    className = { styles.backdrop }
                    onClick = { () => {
                        closeModal();
                    } }>
                </div>
                <div className = { auth.isAuthenticated ? `${styles.container} ${styles['container--authenticated']}` : styles.container }>
                    <DesktopLayout>
                        <button
                            className = { styles.closeButton }
                            onClick = { closeModal }><CrossIcon />
                        </button>
                    </DesktopLayout>
                    <MobileLayout>
                        <div className = { styles.headerMobile }>
                            <button
                                className = { styles.closeButtonMobile }
                                onClick = { closeModal }>
                                <LeftArrowIcon />
                                <span>{ closeModalLabel }</span>
                            </button>
                        </div>
                    </MobileLayout>
                    <div>
                        <section className = { styles.mealSection }>
                            <div
                                className = { styles.mealImage }
                                style = {{
                                    backgroundImage: mealDetails?.photo_1 ? `url(${mealDetails.photo_1})` : '',
                                    margin:          '25px 0 0 15px',
                                }}>
                                {
                                    renderMealType(mealDetails?.meal_type)
                                }
                            </div>

                            <div style = {{ padding: !isDesktop ? '0 20px 0 20px' : '' }}>
                                <h1>{ mealDetails?.name && mealDetails.name }</h1>

                                <p className = { styles.description }>{ mealDetails?.description }</p>
                                {
                                    mealDetails && (mealDetails?.meal_type === 'customisable' ? mealDetails?.starting_at : mealDetails?.nutrients) && (
                                        <>
                                            <h3 className = 'mb-2'>Nutrition in this meal</h3>
                                            {mealDetails?.meal_type === 'customisable' && <h3 className = 'mb-1'>Starting from</h3> }
                                            <div className = { styles.nutritionContainerWrapper }>
                                                {mealDetails &&  (
                                                    <MealNutrients
                                                        className = { styles.nutritionContainer }
                                                        meal = { mealDetails }
                                                    />) }
                                            </div>
                                        </>

                                    )
                                }
                            </div>
                        </section>

                        <section className = { styles.componentsSection }>
                            <div className = { styles.componentsSectionTitle }>
                                {
                                    mealDetails?.meal_type === 'customisable' ?
                                        <h2>Please select how you'd like your {mealDetails?.name}</h2> :
                                        <h2>Want to add anything to {mealDetails?.name}?</h2>
                                }
                            </div>
                            <Notification
                                hideCloseButton
                                className = { styles.daysSectionNotification }
                                message = 'Select at least a single option'
                                show = { optionsError }
                                type = 'error'
                            />
                            <ul className = { styles.componentsGrid }>
                                {
                                    mealDetails?.components?.map((component) => (
                                        <li
                                            className = { styles.component }
                                            key = { component.id }>
                                            <div
                                                className = { styles.componentImage }
                                                style = {{ backgroundImage: `url(${component.photo})` }}
                                            />
                                            {/* position relative is required for getPopupContainer prop on select to work properly. */}
                                            <div style = {{ position: 'relative' }}>
                                                <span className = { styles.componentName }>{component.name}</span>
                                                <Select
                                                    className = { `${styles.componentSelect} er-select min-w-xs` }
                                                    dropdownClassName = { styles.componentSelectDropDown }
                                                    getPopupContainer = { (trigger) => trigger.parentElement }
                                                    value = {
                                                        selectedOptions.find(
                                                            (option) => option.componentId === component.id,
                                                            // eslint-disable-next-line no-undefined
                                                        )?.optionId || 0
                                                    }
                                                    onChange = {
                                                        (value, option) => optionOnChangeHandler(option, component.id)
                                                    }>
                                                    {
                                                        renderComponentsOptions(component)
                                                    }

                                                </Select>
                                            </div>
                                        </li>
                                    ))
                                }
                            </ul>
                        </section>

                        <section className = { styles.daysSection }>
                            <div className = { styles.componentsSectionTitle }>
                                <h2>When will you have {mealDetails?.name}?</h2>
                                <Tooltip
                                    overlayInnerStyle = { tooltipStyle }
                                    placement = 'right'
                                    title = 'Select days of the delivery and let us know when you want to have this delicious meal!'>
                                    <InfoIcon />
                                </Tooltip>
                            </div>
                            <Notification
                                hideCloseButton
                                className = { styles.daysSectionNotification }
                                message = 'Select at least one day '
                                show = { daysError }
                                type = 'error'
                            />
                            <ul className = { styles.daysGrid }>
                                {
                                    weekdays.map((day) => (
                                        <li
                                            className = { mealDetails?.[`available_${day.key}`] ? styles.dayContainer : `${styles.dayContainer} ${styles.dayContainerDisabled}` }
                                            key = { day.key }
                                            style = { getDayStyle(day.label) }>
                                            <div className = 'flex justify-start gap-5 md:gap-8 items-center'>
                                                <div className = 'flex justify-start gap-1 md:gap-3 items-center'>
                                                    <button
                                                        className = { styles.minusButton }
                                                        onClick = { () => decrementMealsPerDay(day.label) }>
                                                        <MinusIcon />
                                                    </button>
                                                    <input
                                                        readOnly
                                                        disabled = { mealsPerDay[day.label] === 0 }
                                                        type = 'text'
                                                        value = { mealsPerDay[day.label] }
                                                    />
                                                    <button
                                                        className = { styles.plusButton }
                                                        onClick = { () => incrementMealsPerDay(day.label) }>
                                                        <PlusIcon />
                                                    </button>
                                                </div>

                                                <div>
                                                    <h2>{day.label}</h2>
                                                    <h3>{renderMealQuantity(day.label)}</h3>
                                                </div>
                                            </div>
                                            {
                                                selectedMealPlanId && getMealQuantity(day.label) !== 0 ?
                                                    <Link to = { auth.isAuthenticated ? `/plans/${selectedMealPlanId}?redirect_day=${day.label}` : `/cart?redirect_day=${day.label}` }>
                                                        Details
                                                    </Link> : null
                                            }
                                        </li>
                                    ))
                                }

                            </ul>
                        </section>

                        <section className = { styles.macronutrientsSection }>
                            <h2>Total Macronutrients</h2>
                            <div className = { styles.macronutrientsFilterWrapper }>
                                <ul>
                                    { macronutrientsDisplayModes.map((mode) => (
                                        <li
                                            className = { `${styles.macronutrientsMode} ${mode === macronutrientsDisplayMode && styles.macronutrientsModeActive}` }
                                            key = { mode }
                                            onClick = { () => setMacronutrientsDisplayMode(mode) }>
                                            { mode }
                                        </li>
                                    )) }
                                </ul>
                            </div>

                            <div className = { styles.macronutrientsContainerWrapper }>
                                <div className = { styles.macronutrientsContainer }>
                                    <div className = { styles.macronutrientContainer }>
                                        <h2>Calories</h2>
                                        <div
                                            className = 'flex justify-between items-center'
                                            style = {{
                                                justifyContent: Math.round(totalMacronutrients.calories - (targets?.calories || 0)) === 0 ? 'flex-start' : '',
                                                gap:            Math.round(totalMacronutrients.calories - (targets?.calories || 0)) === 0 ? '10px' : '',
                                            }}>
                                            <span>{totalMacronutrients.calories.toFixed(1)}kcal</span>
                                            { (targets && targets?.calories !== 0)  &&
                                        // eslint-disable-next-line no-nested-ternary
                                        (Math.round(totalMacronutrients.calories - (targets?.calories || 0)) < 0 ?
                                            <span className = { styles.changeDown }>
                                                <DownArrowIcon />
                                                <span>
                                                    {
                                                        Math.round(
                                                            Math.abs(
                                                                // eslint-disable-next-line max-len
                                                                totalMacronutrients.calories - (targets?.calories || 0),
                                                            ),
                                                        )
                                                    }kcal
                                                </span>
                                            </span> :
                                            Math.round(totalMacronutrients.calories - (targets?.calories || 0)) === 0 ?
                                                <span className = { styles.changeDown }>
                                                    <CheckIcon />
                                                </span> :
                                                <span className = { styles.changeUp }>
                                                    <UpArrowIcon />
                                                    <span>
                                                        {
                                                            Math.round(
                                                                Math.abs(
                                                                    // eslint-disable-next-line max-len
                                                                    totalMacronutrients.calories - (targets?.calories || 0),
                                                                ),
                                                            )
                                                        }kcal
                                                    </span>
                                                </span>)
                                            }
                                        </div>
                                        { <h3>{ targets && targets?.calories !== 0 ? `Target ${targets?.calories}kcal` : 'Target not set'}</h3> }
                                    </div>
                                    <div className = { styles.macronutrientContainer }>
                                        <h2>Net Carbs</h2>
                                        <div
                                            className = 'flex justify-between items-center'
                                            style = {{
                                                justifyContent: Math.round(totalMacronutrients.netCarbs - (targets?.net_carbs || 0)) === 0 ? 'flex-start' : '',
                                                gap:            Math.round(totalMacronutrients.netCarbs - (targets?.net_carbs || 0)) === 0 ? '10px' : '',
                                            }}>
                                            <span>{totalMacronutrients.netCarbs.toFixed(1)}g</span>
                                            { (targets && targets?.net_carbs !== 0)  &&
                                        // eslint-disable-next-line no-nested-ternary
                                        (Math.round(totalMacronutrients.netCarbs - (targets?.net_carbs || 0)) < 0 ?
                                            <span className = { styles.changeDown }>
                                                <DownArrowIcon />
                                                <span>
                                                    {
                                                        Math.round(
                                                            Math.abs(
                                                                // eslint-disable-next-line max-len
                                                                totalMacronutrients.netCarbs - (targets?.net_carbs || 0),
                                                            ),
                                                        )
                                                    }g
                                                </span>
                                            </span> :
                                            Math.round(totalMacronutrients.netCarbs - (targets?.net_carbs || 0)) === 0 ?
                                                <span className = { styles.changeDown }>
                                                    <CheckIcon />
                                                </span> :
                                                <span className = { styles.changeUp }>
                                                    <UpArrowIcon />
                                                    <span>
                                                        {
                                                            Math.round(
                                                                Math.abs(
                                                                    // eslint-disable-next-line max-len
                                                                    totalMacronutrients.netCarbs - (targets?.net_carbs || 0),
                                                                ),
                                                            )
                                                        }g
                                                    </span>
                                                </span>)
                                            }
                                        </div>
                                        { <h3>{ targets && targets?.net_carbs !== 0 ? `Target ${targets?.net_carbs}g` : 'Target not set'}</h3> }
                                    </div>
                                    <div className = { styles.macronutrientContainer }>
                                        <h2>Fat</h2>
                                        <div
                                            className = 'flex justify-between items-center'
                                            style = {{
                                                justifyContent: Math.round(totalMacronutrients.fat - (targets?.fat || 0)) === 0 ? 'flex-start' : '',
                                                gap:            Math.round(totalMacronutrients.fat - (targets?.fat || 0)) === 0 ? '10px' : '',
                                            }}>
                                            <span>{totalMacronutrients.fat.toFixed(1)}g</span>
                                            { (targets && targets?.fat !== 0)  &&
                                        // eslint-disable-next-line no-nested-ternary
                                        (Math.round(totalMacronutrients.fat -  (targets?.fat || 0)) < 0 ?
                                            <span className = { styles.changeDown }>
                                                <DownArrowIcon />
                                                <span>
                                                    {
                                                        Math.round(
                                                            Math.abs(
                                                                totalMacronutrients.fat - (targets?.fat || 0),
                                                            ),
                                                        )
                                                    }g
                                                </span>
                                            </span> :
                                            Math.round(totalMacronutrients.fat - (targets?.fat || 0)) === 0 ?
                                                <span className = { styles.changeDown }>
                                                    <CheckIcon />
                                                </span> :
                                                <span className = { styles.changeUp }>
                                                    <UpArrowIcon />
                                                    <span>
                                                        {
                                                            Math.round(
                                                                Math.abs(
                                                                    // eslint-disable-next-line max-len
                                                                    totalMacronutrients.fat - (targets?.fat || 0),
                                                                ),
                                                            )
                                                        }g
                                                    </span>
                                                </span>)
                                            }
                                        </div>
                                        { <h3>{targets && targets?.fat !== 0 ? `Target ${targets?.fat}g` : 'Target not set'}</h3> }
                                    </div>
                                    <div className = { styles.macronutrientContainer }>
                                        <h2>Protein</h2>
                                        <div
                                            className = 'flex justify-between items-center'
                                            style = {{
                                                justifyContent: Math.round(totalMacronutrients.proteins - (targets?.proteins || 0)) === 0 ? 'flex-start' : '',
                                                gap:            Math.round(totalMacronutrients.proteins - (targets?.proteins || 0)) === 0 ? '10px' : '',
                                            }}>
                                            <span>{totalMacronutrients.proteins.toFixed(1)}g</span>
                                            {(targets && targets?.proteins !== 0)  &&
                                        // eslint-disable-next-line no-nested-ternary
                                        (Math.round(totalMacronutrients.proteins -  (targets?.proteins || 0)) < 0 ?
                                            <span className = { styles.changeDown }>
                                                <DownArrowIcon />
                                                <span>
                                                    {
                                                        Math.round(
                                                            Math.abs(
                                                                totalMacronutrients.proteins - (targets?.proteins || 0),
                                                            ),
                                                        )
                                                    }g
                                                </span>
                                            </span> :
                                            Math.round(totalMacronutrients.proteins - (targets?.proteins || 0)) === 0 ?
                                                <span className = { styles.changeDown }>
                                                    <CheckIcon />
                                                </span> :
                                                <span className = { styles.changeUp }>
                                                    <UpArrowIcon />
                                                    <span>
                                                        {
                                                            Math.round(
                                                                Math.abs(
                                                                    // eslint-disable-next-line max-len
                                                                    totalMacronutrients.proteins - (targets?.proteins || 0),
                                                                ),
                                                            )
                                                        }g
                                                    </span>
                                                </span>)
                                            }
                                        </div>
                                        { <h3>{targets && targets?.proteins !== 0 ? `Target ${targets?.proteins}g` : 'Target not set'}</h3> }
                                    </div>
                                    <div className = { styles.macronutrientContainer }>
                                        <h2>Sugar</h2>
                                        <div className = 'flex justify-between items-center'>
                                            <span>{totalMacronutrients.sugar.toFixed(1)}g</span>
                                        </div>
                                    </div>
                                    <div className = { styles.macronutrientContainer }>
                                        <h2>Fiber</h2>
                                        <div className = 'flex justify-between items-center'>
                                            <span>{totalMacronutrients.fiber.toFixed(1)}g</span>
                                        </div>
                                    </div>
                                    <div className = { styles.macronutrientContainer }>
                                        <h2>Total Carbs</h2>
                                        <div className = 'flex justify-between items-center'>
                                            <span>{totalMacronutrients.totalCarbs.toFixed(1)}g</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* {auth.isAuthenticated && renderTargetsLink()} */}
                        </section>

                        <section className = { styles.totalCostSection }>
                            <h2>Total Cost</h2>
                            <span>{totalCost.toFixed(2)} AED</span>
                        </section>

                        <section className = { styles.footer }>

                            <div className = 'flex justify-end gap-4'>
                                <Notification
                                    hideCloseButton
                                    className = { styles.footerNotification }
                                    message = 'You must select a day of the meal plan'
                                    show = { daysError }
                                    type = 'error'
                                />
                                <Notification
                                    hideCloseButton
                                    className = { styles.footerNotification }
                                    message = 'You must select at least a single option'
                                    show = { optionsError }
                                    type = 'error'
                                />
                            </div>

                            <div className = { styles.bottomButtonContainer }>
                                <button
                                    className = { styles.buttonSecondary }
                                    disabled = { loading }
                                    onClick = { addMoreFoodHandler }>
                                    Done, add more food
                                </button>
                                <button
                                    className = { styles.buttonPrimary }
                                    disabled = { loading }
                                    onClick = { goToMyPlanHandler }>
                                    {auth.isAuthenticated ? '  Done, go to my plan' : 'Done, go to my cart' }
                                </button>
                            </div>
                        </section>
                    </div>
                </div>
            </>
        );
    }

    return null;
};

export default PersonalizeAndAdd;
