import React, { useEffect } from 'react';

type UseClickOutsideType = (ref: React.RefObject<HTMLElement>, outsideClickHandler: () => void) => void

export const useClickOutside: UseClickOutsideType = (ref, outsideClickHandler) => {
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && event.target instanceof HTMLElement && !ref.current.contains(event.target)) {
                outsideClickHandler();
            }
        }
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ ref ]);
};
