const HOME = '/';
const PLANS = '/plans';
const ORDERS = '/orders';
const SINGLE_ORDER = `${ORDERS}/:orderId`;
const SINGLE_PLAN = `${PLANS}/:planId`;
const SINGLE_PLAN_EDIT = `${SINGLE_PLAN}/edit`;
const SINGLE_DELIVERY_EDIT = `${SINGLE_PLAN}/edit/delivery`;
const MEALS = '/meals';
const SINGLE_MEAL = `${MEALS}/:mealId`;
const TARGETS = '/targets';
const WIZARD = '/wizard';
const WIZARD_SUGGESTED_MEALS = `${WIZARD}/suggested-meals`;
const HELP_ME_CHOOSE = '/help-me-choose';
const HELP_ME_CHOOSE_SUGGESTED_MEALS = `${HELP_ME_CHOOSE}/suggested-meals`;
const FAQ = '/faq';
const CONTACTS = '/contacts';
const DASHBOARD = '/dashboard';
const SETTINGS = '/settings/:tab?';
const CART = '/cart';
const ABOUT = '/about';
const SLUG = '/:slug';
const VERIFY_EMAIL = '/verify-email/:id/:token';
const KICK_START_PLANS = '/kick-starter-plans';

const constantsObject = Object.freeze({
    HOME,
    PLANS,
    SINGLE_PLAN,
    SINGLE_PLAN_EDIT,
    SINGLE_DELIVERY_EDIT,
    ORDERS,
    SINGLE_ORDER,
    MEALS,
    SINGLE_MEAL,
    TARGETS,
    WIZARD,
    WIZARD_SUGGESTED_MEALS,
    HELP_ME_CHOOSE,
    HELP_ME_CHOOSE_SUGGESTED_MEALS,
    FAQ,
    CONTACTS,
    DASHBOARD,
    SETTINGS,
    CART,
    ABOUT,
    SLUG,
    VERIFY_EMAIL,
    KICK_START_PLANS
});

export const ROUTE_CONSTANTS: Readonly<typeof constantsObject> =
    constantsObject;
