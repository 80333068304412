import { lazy } from 'react';
import { RouteType } from '../types/route';
import { ROUTE_CONSTANTS } from '../init/constants';

export const routes: RouteType[] = [
    {
        title:     'Homepage',
        url:       ROUTE_CONSTANTS.HOME,
        Component: lazy(() => import('../pages/home')),
        props:     { exact: true },
        header:    {},
    },
    {
        title:     'Orders',
        url:       ROUTE_CONSTANTS.PLANS,
        Component: lazy(() => import('../pages/orders')),
        props:     { exact: true },
        header:    {},
    },
    {
        title:     'Plan Summary',
        url:       `${ROUTE_CONSTANTS.PLANS}/:id`,
        Component: lazy(
            () => import('../pages/plans/components/PlanDetails/PlanDetails'),
        ),
        props:  { exact: false },
        header: {},
    },
    {
        title:     'Orders',
        url:       `${ROUTE_CONSTANTS.ORDERS}/:id`,
        Component: lazy(
            () => import('../pages/orders/components/OrderDetails/OrderDetails'),
        ),
        props:  { exact: false },
        header: {},
    },
    {
        title:     'Meals',
        url:       ROUTE_CONSTANTS.MEALS,
        Component: lazy(() => import('../pages/meals')),
        props:     { exact: true },
        header:    {},
    },
    {
        title:     'Single meal',
        url:       ROUTE_CONSTANTS.SINGLE_MEAL,
        Component: lazy(() => import('../pages/singleMeal')),
        props:     { exact: false },
        header:    {},
    },
    {
        title:     'Wizard',
        url:       ROUTE_CONSTANTS.WIZARD,
        Component: lazy(() => import('../pages/wizard')),
        props:     { exact: false },
        header:    {},
    },
    {
        title:     'Help me choose',
        url:       ROUTE_CONSTANTS.HELP_ME_CHOOSE,
        Component: lazy(() => import('../pages/helpMeChoose')),
        props:     { exact: false },
        header:    {},
    },
    {
        title:     'Contacts',
        url:       ROUTE_CONSTANTS.CONTACTS,
        Component: lazy(() => import('../pages/contacts')),
        props:     { exact: true },
        header:    {},
    },
    {
        title:     'Cart',
        url:       ROUTE_CONSTANTS.CART,
        Component: lazy(() => import('../pages/cart')),
        props:     { exact: true },
        header:    {},
    },
    {
        title:     'Verify Email',
        url:       ROUTE_CONSTANTS.VERIFY_EMAIL,
        Component: lazy(() => import('../pages/verifyEmail')),
        props:     { exact: true },
        header:    {},
    },
    // {
    //     title:     'Kick Starter Plans',
    //     url:       ROUTE_CONSTANTS.KICK_START_PLANS,
    //     Component: lazy(() => import('../pages/kickStartPlans')),
    //     props:     { exact: true },
    //     header:    {},
    // },
];
