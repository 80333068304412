import api from '../../../managers/api';


export const sendEnquiry = async (payload:any) => {
    const url = `${process.env.REACT_APP_ALLRIGHT_BASE_API_URL}/enquiries`;

    try {
        const response = await api.post(url,  payload);
        const enquiry = response.data?.data;

        return enquiry;
    } catch (error) {
        console.error(error);

        return error;
    }
};
