import React from 'react';

import Loader from '../../components/Loader';

import styles from './styles.module.sass';

export default function LoadingOverlay() {
    return (
        <div className = { styles.loadingContainer }>
            <Loader className = 'py-24' />
        </div>
    );
}
