// Core
import React, { FC, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

// Elements
import { ReactComponent as FbIcon } from 'src/assets/media/icons/navigation/fb-icon.svg';
import { ReactComponent as InstIcon } from 'src/assets/media/icons/navigation/inst-icon.svg';
import { ReactComponent as YTIcon } from 'src/assets/media/icons/navigation/youtube-icon.svg';
import { ReactComponent as MailIcon } from 'src/assets/media/icons/navigation/mail-icon.svg';
import { ReactComponent as InputArrowIcon } from 'src/assets/media/icons/navigation/input-arrow-right.svg';
import LogoImg from 'src/assets/media/trainx-white-logo.svg';

import Notification from 'src/components/Notification';

import { ROUTE_CONSTANTS } from 'src/init/constants';
import { headerConfig } from 'src/init/config';
import useGetUserRegion from 'src/hooks/api/deliveries/useGetUserRegion';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/types/state';
import useCMSPages from 'src/hooks/api/cms/useCMSPages';
import { getCMSHeaderConfig } from 'src/utils/api/cms-pages';
import api from 'src/managers/api';
import logger from 'src/managers/logger.manager';

// Styles
import styles from './footer.module.sass';

type FooterType = FC<{ className?: string }>;

const socialMediaData = [
    { path: 'https://www.instagram.com/', Icon: FbIcon },
    { path: 'https://www.facebook.com/', Icon: InstIcon },
    { path: 'https://www.youtube.com/', Icon: YTIcon },
];

export const Footer: FooterType = ({
    className,
}) => {
    const region = useGetUserRegion();
    const regionList = useSelector((state: IRootState) => state.locale?.regionList?.data);
    const isAuthenticated = useSelector((state: IRootState) => state.auth.isAuthenticated);
    const regionId = useMemo(() => regionList?.find((item) => item.name === region)?.id, [ region, regionList, isAuthenticated ]);
    const { CMSPages } = useCMSPages(regionId, 'en');
    const CMSHeaderConfig = useMemo(() => CMSPages?.length ? getCMSHeaderConfig(CMSPages) : [], [ CMSPages ]);

    const [ email, setEmail ] = useState('');
    const [ notification, setNotification ] = useState<{ message: string; type: 'success' | 'error' } | null>(null);

    const subscribeToNewsletter = async () => {
        try {
            const payload = {
                email,
                page_url: window.location.href,
                page_title: window.document.title
            }

            const res = await api.post('/hubspot/newsletter/subscribe', payload);

            if (res.data?.data?.success) {
                setNotification({
                    message: 'Subscribed!',
                    type: 'success'
                })
            } else {
                setNotification({
                    message: res.data?.data?.message || 'Something went wrong',
                    type: 'error'
                })
            }

        } catch (error) {
            setNotification({ type: 'error', message: (error as any).response?.data?.data?.message || (error as any).response.data?.message || 'Something went wrong' })
            logger.error(error)
        }
    }

    return (
        <footer className = { clsx(className, 'text-base leading-6 text-neutral-40') }>
            <div className = { clsx(className, 'common-section bg-neutral-100 border-b border-neutral-80 border-solid') }>
                <div className = { clsx('common-container', styles.container__top) }>
                    <div className = 'flex flex-col items-center md:items-start w-full lg:w-5/12 xl:w-4/12 md:mb-10 xl:mb-0'>
                        <div className = 'mb-6'>
                            <Link to = { ROUTE_CONSTANTS.HOME }>
                                <img
                                    alt = 'Eat Right Logo'
                                    className = { styles.logo }
                                    src = { LogoImg }
                                />
                            </Link>
                        </div>
                        <p className = 'mb-12 text-center md:text-left'>
                            More than just calories in/calories out. More than a generic formula or
                            one size fits all approach. Eating Right is about creating an
                            individualised plan for using nutrition and supplementation to
                            create sustainable long term results.
                        </p>
                        <div className = 'flex mb-16 md:mb-0'>
                            {
                                socialMediaData.map(({ path, Icon }) => (
                                    <a
                                        className = 'cursor-pointer mr-3'
                                        href = { path }
                                        key = { path.slice(0, -5) }
                                        rel = 'noreferrer'
                                        target = '_blank'>
                                        <Icon className = { styles.social__media } />
                                    </a>
                                ))
                            }
                        </div>
                    </div>
                    <div className = { styles['col-2'] }>
                        <div className = 'flex flex-col items-center md:items-start'>
                            <p className = 'text-neutral-60 hover:text-neutral-40 mb-5'>Information</p>
                            {
                                headerConfig.map(({ name, path }) => path ? (
                                    <Link
                                        className = 'hover:text-white cursor-pointer 10 mb-6'
                                        key = { name.slice(0, 5) }
                                        to = { path }>
                                        {name}
                                    </Link>
                                ) : (
                                    <span className = 'hover:text-white cursor-pointer mb-6'>
                                        {name}
                                    </span>
                                ))
                            }
                            {
                                CMSHeaderConfig?.filter(({ name }) => ['About', 'Contacts', 'FAQ'].includes(name))
                                    .map(({ name, path }) => path ? (
                                        <Link
                                            className = 'hover:text-white cursor-pointer 10 mb-6'
                                            key = { name.slice(0, 5) }
                                            to = { path }>
                                            {name}
                                        </Link>
                                    ) : (
                                        <span className = 'hover:text-white cursor-pointer mb-6'>
                                            {name}
                                        </span>
                                    ))
                            }
                        </div>
                    </div>
                    <div className = { styles['col-3'] }>
                        <p className = 'text-neutral-60 mb-6'>Don’t miss any update</p>
                        <div className = 'flex mb-16'>
                            <input
                                className = { styles.input }
                                placeholder = 'Your email to get newsletter...'
                                type = 'email'
                                required
                                pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'
                                value = {email}
                                onChange = { (e) => setEmail(e.target.value) }
                            />
                            <button className = { styles['input-button'] } onClick = {subscribeToNewsletter}>
                                <span className = 'md:hidden'>
                                    <InputArrowIcon />
                                </span>
                                <span className = 'hidden md:inline'>
                                    Subscribe
                                </span>
                            </button>
                        </div>
                        <p className = 'text-neutral-60 mb-5'>Get in touch</p>
                        <div className = 'flex flex-col md:flex-row items-center md:items-start text-neutral-40'>
                            <a
                                className = 'flex items-center'
                                href = 'mail:support@theeatright.com' >
                                <MailIcon />
                                <span className = 'ml-2.5 inline-block'>support@theeatright.com</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className = { clsx(className, 'common-section bg-neutral-100 text-neutral-60') }>
                <div className = { clsx('common-container', styles.container__bottom) }>
                    <p className = { clsx(styles['container__bottom-text'], 'mb-5 md:mb-0') }>2021 - EatRight DMCC - All Rights Reserved.</p>
                    <div className = 'flex'>
                        <Link
                            className = { clsx(styles['container__bottom-text'], 'mr-7') }
                            to = '/privacy-policy'>
                            Privacy Policy
                        </Link>
                        <Link
                            className = { styles['container__bottom-text'] }
                            to = '/terms-and-conditions'>
                            Terms & Conditions
                        </Link>
                    </div>
                </div>
            </div>

            <Notification 
                show = { !!notification }
                message= { notification?.message || '' }
                type = { notification?.type || 'info' }
                className = { `${styles.notification} ${notification?.type === 'success' ? styles.success : ''}` }
                hideCloseButton
                timeout = {4000}
                onCloseHandler = {() => setNotification(null) } 
            />
        </footer>
    );
};
