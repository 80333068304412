import useSWR from 'swr';
import api from '../../../managers/api';

interface UseCMSPages {
    isLoading: boolean
    CMSPages: any[]
}

export default function useCMSPages(regionId?: number, language?: string): UseCMSPages {
    let queryString = new URLSearchParams('');

    if (language) {
        queryString.set('filter[language]', language);
    }

    const { data, error } = useSWR<any>(`/cms-pages/menu?${queryString.toString()}`, api.get, { shouldRetryOnError: false });

    const isLoading = !data && !error;
    
    const CMSPages = data?.data?.data?.pages || [];
   
    return {
        isLoading,
        CMSPages,
    };
}
