import React from 'react';
import { Pagination as AntdPagination } from 'antd';

import { ReactComponent as RightArrow } from 'src/assets/media/icons/right-arrow.svg';
import { ReactComponent as LeftArrow } from 'src/assets/media/icons/left-arrow.svg';

import styles from './Pagination.module.sass';

interface Props {
    currentPage: number;
    itemsPerPage: number;
    total: number;
    onChange: (page: number) => void;
    className?: string
}

const renderPaginationItem = (page: number, type: string, element: any) => {
    switch (type) {
        case 'prev':
            return <LeftArrow />;
        case 'next':
            return <RightArrow />;
        case 'jump-prev':
        case 'jump-next':
            return <span className = { styles.jumpItem }>...</span>;
        default:
            return element;
    }
};

const Pagination: React.FC<Props> = (props) => {
    return (
        <AntdPagination
            showLessItems
            className = { props.className }
            current = { props.currentPage }
            itemRender = { renderPaginationItem }
            pageSize = { props.itemsPerPage }
            showSizeChanger = { false }
            total = { props.total }
            onChange = { (page) => props.onChange && props.onChange(page) }
        />
    );
};


export default Pagination;
