import React from 'react';
import { Link } from 'react-router-dom';
import { DesktopLayout, MobileLayout } from 'src/layouts';

import { ROUTE_CONSTANTS } from 'src/init/constants';

import styles from './CtaArea.module.sass';
import clsx from 'clsx';

interface Props {
    title: string
    preTitle: string
    desc: string
}

const CtaArea: React.FC<Props> = ({ title, preTitle, desc }) => {
    return (
        <>
            <MobileLayout>
                <section className = { styles.container }>
                    <h2 style = {{ marginTop: '215px' }}>{preTitle}</h2>
                    <h1>{title}</h1>
                    <p>
                       {desc}
                    </p>

                    <Link to = { ROUTE_CONSTANTS.HELP_ME_CHOOSE }>
                        <button className = { styles.buttonPrimary }>What Should I eat next week?</button>
                    </Link>

                    <span
                        className = { clsx(styles.messageContainer, styles['slide-animation--mobile']) }
                        id = 'message1'
                        style = {{ transform: 'scale(0.9)', left: '45px', top: '40px' }}>
                        <img
                            src = '/images/users/user10.png'
                            style = {{ marginRight: '11px' }}
                        />
                        I practice exercise everyday for 10 min
                        <span className = 'relative'>
                            <span
                                className = { styles.messageArrow }
                                style = {{ right: '195px', top: '20px' }}
                            />
                        </span>
                    </span>

                    <span
                        className = { clsx(styles.messageContainer, styles['slide-animation--mobile']) }
                        style = {{ left: '200px', top: '20px', transform: 'scale(0.7)', animationDelay: '0.4s', marginTop: 25 }}>
                        I'm a Vegan
                        <img
                            src = '/images/users/user6.png'
                            style = {{ marginLeft: '11px' }}
                        />
                        <span className = 'relative'>
                            <span
                                className = { styles.messageArrow }
                                style = {{ right: '-14px', top: '16px', transform: 'rotate(45deg) scale(0.8)' }}
                            />
                        </span>
                    </span>

                    <span
                        className = { clsx(styles.messageContainer, styles['slide-animation--mobile']) }
                        style = {{ top: '100px', right: '171px', transform: 'scale(0.60)', animationDelay: '0.8s' }}>
                        I do cradio everyday
                        <img
                            src = '/images/users/user11.png'
                            style = {{ marginLeft: '11px' }}
                        />
                        <span className = 'relative'>
                            <span
                                className = { styles.messageArrow }
                                style = {{ right: '-13px', top: '17px', transform: 'rotate(45deg) scale(0.70)' }}
                            />
                        </span>
                    </span>

                    <span
                        className = { clsx(styles.messageContainer, styles['slide-animation--mobile']) }
                        style = {{ top: '83px', right: '200px', transform: 'scale(0.65)', animationDelay: '1.2s', marginTop: 25 }}>
                        <img
                            src = '/images/users/user7.png'
                            style = {{ marginRight: '11px' }}
                        />
                        I'm a Male
                        <span className = 'relative'>
                            <span
                                className = { styles.messageArrow }
                                style = {{ right: '70px', top: '20px' }}
                            />
                        </span>
                    </span>

                    <span
                        className = { clsx(styles.messageContainer, styles['slide-animation--mobile']) }
                        style = {{ top: '140px', right: '30px', transform: 'scale(1)', animationDelay: '1.8s', marginTop: 5 }}>
                        I need delivered Mon-Fri
                        <img
                            src = '/images/users/user4.png'
                            style = {{ marginLeft: '11px' }}
                        />
                        <span className = 'relative'>
                            <span
                                className = { styles.messageArrow }
                                style = {{ right: '-13px', top: '16px', transform: 'rotate(45deg) scale(0.9)' }}
                            />
                        </span>
                    </span>

                    <span
                        className = { clsx(styles.messageContainer, styles['slide-animation--mobile']) }
                        style = {{ top: '170px', right: '108px', transform: 'scale(0.8)', animationDelay: '2.6s', marginTop: 5 }}>
                        <img
                            src = '/images/users/user9.png'
                            style = {{ marginRight: '11px' }}
                        />
                        I want to loose weight
                        <span className = 'relative'>
                            <span
                                className = { styles.messageArrow }
                                style = {{ right: '125px', top: '20px' }}
                            />
                        </span>
                    </span>
                </section>
            </MobileLayout>

            <DesktopLayout>
                <section className = { styles.container }>
                    <h2>{preTitle}</h2>
                    <h1>{title}</h1>
                    <p>
                        {desc}
                    </p>

                    <Link to = { ROUTE_CONSTANTS.HELP_ME_CHOOSE }>
                        <button className = { styles.buttonPrimary }>What Should I eat next week?</button>
                    </Link>

                    <span
                        className = { clsx(styles.messageContainer, styles['slide-animation']) }
                        id = 'message1'
                        style = {{ top: '280px', right: '153px' }}>
                        <img
                            src = '/images/users/user10.png'
                            style = {{ marginRight: '11px' }}
                        />
                        I practice exercise everyday for 10 min
                        <span className = 'relative'>
                            <span
                                className = { styles.messageArrow }
                                style = {{ right: '265px' }}
                            />
                        </span>
                    </span>

                    <span
                        className = { clsx(styles.messageContainer, styles['slide-animation']) }

                        style = {{ top: '280px', right: '119px', transform: 'scale(0.8)', animationDelay: '0.4s', marginTop: 25 }}>
                        I'm a Vegan
                        <img
                            src = '/images/users/user6.png'
                            style = {{ marginLeft: '11px' }}
                        />
                        <span className = 'relative'>
                            <span
                                className = { styles.messageArrow }
                                style = {{ right: '-15px', transform: 'rotate(45deg)' }}
                            />
                        </span>
                    </span>

                    <span
                        className = { clsx(styles.messageContainer, styles['slide-animation']) }

                        style = {{ top: '280px', right: '352px', transform: 'scale(0.70)', animationDelay: '0.8s' }}>
                        I do cradio everyday
                        <img
                            src = '/images/users/user11.png'
                            style = {{ marginLeft: '11px' }}
                        />
                        <span className = 'relative'>
                            <span
                                className = { styles.messageArrow }
                                style = {{ right: '-13px', transform: 'rotate(45deg)' }}
                            />
                        </span>
                    </span>

                    <span
                        className = { clsx(styles.messageContainer, styles['slide-animation']) }

                        style = {{ top: '280px', right: '260px', transform: 'scale(0.75)', animationDelay: '1.2s' }}>
                        <img
                            src = '/images/users/user7.png'
                            style = {{ marginRight: '11px' }}
                        />
                        I'm a Male
                        <span className = 'relative'>
                            <span
                                className = { styles.messageArrow }
                                style = {{ right: '92px' }}
                            />
                        </span>
                    </span>

                    <span
                        className = { clsx(styles.messageContainer, styles['slide-animation']) }

                        style = {{ top: '280px', right: '117px', transform: 'scale(1.1)', animationDelay: '1.8s', marginTop: 5 }}>
                        I need delivered Mon-Fri
                        <img
                            src = '/images/users/user4.png'
                            style = {{ marginLeft: '11px' }}
                        />
                        <span className = 'relative'>
                            <span
                                className = { styles.messageArrow }
                                style = {{ right: '-13px', transform: 'rotate(45deg)' }}
                            />
                        </span>
                    </span>

                    <span
                        className = { clsx(styles.messageContainer, styles['slide-animation']) }
                        style = {{ top: '280px', right: '267px', transform: 'scale(0.8)', animationDelay: '2.6s' }}>
                        <img
                            src = '/images/users/user9.png'
                            style = {{ marginRight: '11px' }}
                        />
                        I want to loose weight
                        <span className = 'relative'>
                            <span
                                className = { styles.messageArrow }
                                style = {{ right: '165px' }}
                            />
                        </span>
                    </span>
                </section>
            </DesktopLayout>
        </>
    );
};

export default CtaArea;
