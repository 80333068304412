import Discounts from './tabs/discounts/discounts.component';

import { SettingsContent, SettingsSectionsType } from './settings.types';

export const SETTINGS_SECTIONS: {
    [Option in Uppercase<SettingsSectionsType>]: SettingsSectionsType
} = {
    PREFFERED_LANGUAGE: 'preffered_language',
    GIFT_CARDS:         'gift_cards',
    LOYALTY:            'loyalty',
    DISCOUNTS:          'discounts',
};

export const content: SettingsContent[] = [
    {
        key:       SETTINGS_SECTIONS.DISCOUNTS,
        label:     'Coupons/Discounts',
        component: Discounts,
    },
];
