// Core
import { takeLatest, put, call } from 'redux-saga/effects';

import api from '../../managers/api';
import {
  ACTION_GET_TARGETS_REQUEST,
  ACTION_GET_TARGETS_LOAD,
  ACTION_GET_TARGETS_SUCCESS,
  ACTION_GET_TARGETS_ERROR,
  ACTION_SAVE_TARGETS_REQUEST,
  ACTION_SAVE_TARGETS_LOAD,
  ACTION_SAVE_TARGETS_SUCCESS,
  ACTION_SAVE_TARGETS_ERROR,
  ActionType,
} from '../action-types';

interface IResponse {
  [key: string]: any;
}

export function* sagaTargetsWatcher() {
    yield takeLatest(ACTION_GET_TARGETS_REQUEST, getTargetsWorker);
    yield takeLatest(ACTION_SAVE_TARGETS_REQUEST, saveTargetsWorker);
}

function* getTargetsWorker() {
  try {
    yield put({ type: ACTION_GET_TARGETS_LOAD });
    const targets = (yield call(() =>
      api.get('/user/account/target').then((res) => res.data.data)
    )) as IResponse;
    yield put({ type: ACTION_GET_TARGETS_SUCCESS, payload: targets });
  } catch (error) {
    yield put({ type: ACTION_GET_TARGETS_ERROR, payload: { error }});
  }
}

function* saveTargetsWorker({ payload }: ActionType<{
  selectedDay: number;
  protein: number;
  netCarbs: number;
  fiber: number;
  fat: number;
  calories: number;
  wizardResponseId?: number;
  onSuccess?: () => void;
}>) {
  try {
    const reqBodyObj: any = {
      proteins: Math.round(payload.protein),
      net_carbs: Math.round(payload.netCarbs),
      fiber: Math.round(payload.fiber),
      fat: Math.round(payload.fat),
      calories: Math.round(payload.calories),
      sugar: 0,
      total_carbs: 0
    }
    if (payload.wizardResponseId) {
      reqBodyObj.wizard_response_id = payload.wizardResponseId;
    }

    yield put({ type: ACTION_SAVE_TARGETS_LOAD });
    const newTargets = (yield call(() =>
      api.post('/targets', reqBodyObj).then((res) => res.data.data)
    )) as IResponse;

    yield put({ type: ACTION_SAVE_TARGETS_SUCCESS, payload: newTargets });
    payload.onSuccess && payload.onSuccess();
  } catch (error) {
    yield put({ type: ACTION_SAVE_TARGETS_ERROR, payload: { error }});
  }
}


