import React from 'react';

import styles from './about.module.sass';

interface AboutProps {
    preTitle: string
    title: string
    heroImage: string
    mainText: string
}

const About: React.FC<AboutProps> = (props) => {
    const createMarkup = (val: string) => {
        return {__html: val};
    }
    return (
        <div className = { styles.about }>
            <h1 className = { styles.title }>{ props.title }<span>{ props.preTitle }</span></h1>
            <div className = { styles.aboutImg }>
                <img
                    alt = 'about'
                    src = { props.heroImage }
                />
            </div>
            <div className = { styles.body } dangerouslySetInnerHTML={ createMarkup(props.mainText) } />
        </div>
    );
};

export default About;
