// Core
import React, { FC } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

// Components
import { MealNutrientCard } from '../MealNutrientCard';
import { IRootState } from 'src/types/state';

// Types
import { IMeal } from 'src/types/meal.type';

type MealNutrientsType = FC<{
    className?: string;
    showTotalMacros?: boolean;
    meal: Pick<IMeal, 'nutrients' | 'starting_at' | 'meal_type'>
}>;
export  const getNutrientAmount = (value: number | string | null | undefined) => {
    if (typeof value === 'number') {
        return value.toFixed(1);
    }
    if (typeof value === 'string') {
        getNutrientAmount(Number(value));
    }

    return '-';
};

export const MealNutrients: MealNutrientsType = ({
    className,
    showTotalMacros,
    meal: { nutrients, starting_at, meal_type },
}) => {
    const defaultMacros = meal_type === 'customisable' ? starting_at || nutrients : nutrients;
    const totalMacros = useSelector((state: IRootState) => state.macronutrients.data);

    const nutrientsData = showTotalMacros ?
    [
        { title: 'Calories', nutrientAmount: `${getNutrientAmount(totalMacros?.current_meal?.calories)}kcal`, isAmountZero: totalMacros?.current_meal?.calories === 0  },
        { title: 'Net Carbs', nutrientAmount: `${getNutrientAmount(totalMacros?.current_meal?.net_carbs)}g`, isAmountZero: totalMacros?.current_meal?.net_carbs === 0  },
        { title: 'Fat', nutrientAmount: `${getNutrientAmount(totalMacros?.current_meal?.fat)}g`, isAmountZero: totalMacros?.current_meal?.fat === 0  },
        { title: 'Protein', nutrientAmount: `${getNutrientAmount(totalMacros?.current_meal?.proteins)}g`, isAmountZero: totalMacros?.current_meal?.proteins === 0  },
        { title: 'Sugar', nutrientAmount: `${getNutrientAmount(totalMacros?.current_meal?.sugar)}g`, isAmountZero: totalMacros?.current_meal?.sugar === 0  },
        { title: 'Fiber', nutrientAmount: `${getNutrientAmount(totalMacros?.current_meal?.fiber)}g`, isAmountZero: totalMacros?.current_meal?.fiber === 0  },
        { title: 'Total Carbs', nutrientAmount: `${getNutrientAmount(totalMacros?.current_meal?.total_carbs)}g`, isAmountZero: totalMacros?.current_meal?.total_carbs === 0  },
    ]
        : 
    [
        { title: 'Calories', nutrientAmount: `${getNutrientAmount(defaultMacros?.calories)}kcal`, isAmountZero: defaultMacros?.calories === 0  },
        { title: 'Net Carbs', nutrientAmount: `${getNutrientAmount(defaultMacros?.net_carbs)}g`, isAmountZero: defaultMacros?.net_carbs === 0  },
        { title: 'Fat', nutrientAmount: `${getNutrientAmount(defaultMacros?.fat)}g`, isAmountZero: defaultMacros?.fat === 0  },
        { title: 'Protein', nutrientAmount: `${getNutrientAmount(defaultMacros?.proteins)}g`, isAmountZero: defaultMacros?.proteins === 0  },
        { title: 'Sugar', nutrientAmount: `${getNutrientAmount(defaultMacros?.sugar)}g`, isAmountZero: defaultMacros?.sugar === 0  },
        { title: 'Fiber', nutrientAmount: `${getNutrientAmount(defaultMacros?.fiber)}g`, isAmountZero: defaultMacros?.fiber === 0  },
        { title: 'Total Carbs', nutrientAmount: `${getNutrientAmount(defaultMacros?.total_carbs)}g`, isAmountZero: defaultMacros?.total_carbs === 0  },
    ];

    return (
        <div className = { clsx(className) }>
            <div className = 'flex flex-wrap gap-3'>
                {
                    nutrientsData.map((nutrient, i) => {
                        return nutrient.isAmountZero ?
                            null :
                            (
                                <MealNutrientCard
                                    className = ''
                                    key={i}
                                    { ...nutrient }
                                />
                            );
                    })
                }
            </div>
        </div>
    );
};
