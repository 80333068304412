import { takeLatest, put, call, select } from 'redux-saga/effects';

import { getItemFromLocalStorage } from 'src/utils';
import { IRootState } from 'src/types/state';
import api from '../../managers/api';
import { cookieManager } from '../../managers/cookieManager';

import {
  ACTION_WIZARD_GET_RESULTS_REQUEST,
  ACTION_WIZARD_GET_RESULTS_LOAD,
  ACTION_WIZARD_GET_RESULTS_SUCCESS,
  ACTION_WIZARD_GET_RESULTS_ERROR,
  ACTION_WIZARD_GET_FOOD_PREFERENCES_REQUEST,
  ACTION_WIZARD_GET_FOOD_PREFERENCES_LOAD,
  ACTION_WIZARD_GET_FOOD_PREFERENCES_SUCCESS,
  ACTION_WIZARD_GET_FOOD_PREFERENCES_ERROR,
  ACTION_WIZARD_GET_SUGGESTIONS_FROM_TARGETS_REQUEST,
  ACTION_WIZARD_GET_SUGGESTIONS_FROM_TARGETS_LOAD,
  ACTION_WIZARD_GET_SUGGESTIONS_FROM_TARGETS_SUCCESS,
  ACTION_WIZARD_GET_SUGGESTIONS_FROM_TARGETS_ERROR,
  ActionType,
} from '../action-types';

interface IResponse {
  [key: string]: any;
}

export function* sagaWizardWatcher() {
    yield takeLatest(ACTION_WIZARD_GET_RESULTS_REQUEST, getResultsWorker);
    yield takeLatest(ACTION_WIZARD_GET_FOOD_PREFERENCES_REQUEST, getFoodPreferencesWorker);
    yield takeLatest(ACTION_WIZARD_GET_SUGGESTIONS_FROM_TARGETS_REQUEST, getSuggestionsFromTargetsWorker);
}

function* getResultsWorker({ payload }: ActionType<any>) {
  try {
    const sessionId = getItemFromLocalStorage('session-id');
    const dataObj = {
        session: sessionId,
        sex: payload.gender,
        age: payload.age,
        height: payload.height,
        height_unit: payload.heightUnit,
        weight: payload.weight,
        weight_unit: payload.weightUnit,
        bodyfat_percentage: payload.bodyFat,
        activity: payload.activity,
        goal: payload.goal,
        budget: payload.budget,
        meals_per_day: payload.mealsPerDay,
        meal_days: payload.deliveryDays,
        foods_liked: payload.likes,
        foods_disliked: payload.dislikes,
        restrictions: payload.restrictions,
        weight_training_experience: payload.weightTrainingExperience,
        weight_training_sun: payload.weightTrainingDays.includes('Sunday'),
        weight_training_mon: payload.weightTrainingDays.includes('Monday'),
        weight_training_tue: payload.weightTrainingDays.includes('Tuesday'),
        weight_training_wed: payload.weightTrainingDays.includes('Wednesday'),
        weight_training_thu: payload.weightTrainingDays.includes('Thursday'),
        weight_training_fri: payload.weightTrainingDays.includes('Friday'),
        weight_training_sat: payload.weightTrainingDays.includes('Saturday'),
        cardio_exercise_sun: !!payload.cardioTrainingDays['Sundays'],
        cardio_exercise_level_sun: payload.cardioTrainingDays['Sundays']?.intensity ? payload.cardioTrainingDays['Sundays'].intensity : '0',
        cardio_exercise_duration_sun: payload.cardioTrainingDays['Sundays']?.durationValue ? payload.cardioTrainingDays['Sundays'].durationValue : '0',
        cardio_exercise_mon: !!payload.cardioTrainingDays['Mondays'],
        cardio_exercise_level_mon: payload.cardioTrainingDays['Mondays']?.intensity ? payload.cardioTrainingDays['Mondays'].intensity : '0',
        cardio_exercise_duration_mon: payload.cardioTrainingDays['Mondays']?.durationValue ? payload.cardioTrainingDays['Mondays'].durationValue : '0',
        cardio_exercise_tue: !!payload.cardioTrainingDays['Tuesdays'],
        cardio_exercise_level_tue: payload.cardioTrainingDays['Tuesdays']?.intensity ? payload.cardioTrainingDays['Tuesdays'].intensity : '0',
        cardio_exercise_duration_tue: payload.cardioTrainingDays['Tuesdays']?.durationValue ? payload.cardioTrainingDays['Tuesdays'].durationValue : '0',
        cardio_exercise_wed: !!payload.cardioTrainingDays['Wednesdays'],
        cardio_exercise_level_wed: payload.cardioTrainingDays['Wednesdays']?.intensity ? payload.cardioTrainingDays['Wednesdays'].intensity : '0',
        cardio_exercise_duration_wed: payload.cardioTrainingDays['Wednesdays']?.durationValue ? payload.cardioTrainingDays['Wednesdays'].durationValue : '0',
        cardio_exercise_thu: !!payload.cardioTrainingDays['Thursdays'],
        cardio_exercise_level_thu: payload.cardioTrainingDays['Thursdays']?.intensity ? payload.cardioTrainingDays['Thursdays'].intensity : '0',
        cardio_exercise_duration_thu: payload.cardioTrainingDays['Thursdays']?.durationValue ? payload.cardioTrainingDays['Thursdays'].durationValue : '0',
        cardio_exercise_fri: !!payload.cardioTrainingDays['Fridays'],
        cardio_exercise_level_fri: payload.cardioTrainingDays['Fridays']?.intensity ? payload.cardioTrainingDays['Fridays'].intensity : '0',
        cardio_exercise_duration_fri: payload.cardioTrainingDays['Fridays']?.durationValue ? payload.cardioTrainingDays['Fridays'].durationValue : '0',
        cardio_exercise_sat: !!payload.cardioTrainingDays['Saturdays'],
        cardio_exercise_level_sat: payload.cardioTrainingDays['Saturdays']?.intensity ? payload.cardioTrainingDays['Saturdays'].intensity : '0',
        cardio_exercise_duration_sat: payload.cardioTrainingDays['Saturdays']?.durationValue ? payload.cardioTrainingDays['Saturdays'].durationValue : '0',
    }

    yield put({ type: ACTION_WIZARD_GET_RESULTS_LOAD });

    let region: string = cookieManager.get('region');
    if (!region) {
        region =  yield select((state: IRootState) => state.auth.userInfo?.accounts[0]?.regionInfo?.region)
    }

    const res = (yield call(() =>
      api.post('/wizard', dataObj).then((res) => res.data.data)
    )) as IResponse;

    const results  = (yield call(() =>
      api.get(`/wizard/results/${res.id}?region=${region}`).then((res) => res.data.data)
    )) as IResponse;

    yield put({ type: ACTION_WIZARD_GET_RESULTS_SUCCESS, payload: results });
  } catch (error) {
    yield put({ type: ACTION_WIZARD_GET_RESULTS_ERROR, payload: { error }});
  }
}

function* getFoodPreferencesWorker() {
  try {
    yield put({ type: ACTION_WIZARD_GET_FOOD_PREFERENCES_LOAD });

    const foodPreferences  = (yield call(() =>
      api.get('wizard/food-preferences').then((res) => res.data.data)

    )) as IResponse;

    yield put({ type: ACTION_WIZARD_GET_FOOD_PREFERENCES_SUCCESS, payload: foodPreferences });
  } catch (error) {
    yield put({ type: ACTION_WIZARD_GET_FOOD_PREFERENCES_ERROR, payload: { error }});
  }
}

function* getSuggestionsFromTargetsWorker({ payload }: ActionType<{
  proteins: number,
  fat: number,
  net_carbs: number,
  meals_per_day: number,
  deliveryDays: number[],
  region: string,
  budget:         string,
  foods_liked:    number[],
  foods_disliked: number[],
  session: string
  onSuccess?: () => void,
}>) {
  try {
    yield put({ type: ACTION_WIZARD_GET_SUGGESTIONS_FROM_TARGETS_LOAD });
  
    const suggestions  = (yield call(() =>
      api.post('/wizard/results-from-targets', { 
        proteins: payload.proteins, 
        fat: payload.fat, 
        net_carbs: payload.net_carbs, 
        region: payload.region, 
        meals_per_day: 
        payload.meals_per_day,
        meal_days: payload.deliveryDays,
        budget: payload.budget,
        foods_liked: payload.foods_liked,
        foods_disliked: payload.foods_disliked,
        session: payload.session
      }).then((res) => res.data.data)

    )) as IResponse;

    yield put({ type: ACTION_WIZARD_GET_SUGGESTIONS_FROM_TARGETS_SUCCESS, payload: suggestions });

    payload.onSuccess?.();
  } catch (error) {
    yield put({ type: ACTION_WIZARD_GET_SUGGESTIONS_FROM_TARGETS_ERROR, payload: { error }});
  }
}
