// Core
import React from 'react';
import clsx from 'clsx';

// Types
import { LibraryButtonType } from './types';

// Styles
import styles from './libraryButtonsStyles.module.sass';

export const BaseButton: LibraryButtonType = ({
    className,
    children,
    disabled,
    type = 'primary',
    style,
    ...rest
}) => (
    <button
        className = { clsx(className, styles.button, styles[type], { [styles.disabled]: disabled }) }
        style = { style }
        { ...rest }>
        { children }
    </button>
);
