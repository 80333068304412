import { takeLatest, put, call } from 'redux-saga/effects';

import api from '../../managers/api';
import {
  ACTION_GET_MACRONUTRIENTS_REQUEST,
  ACTION_GET_MACRONUTRIENTS_LOAD,
  ACTION_GET_MACRONUTRIENTS_SUCCESS,
  ACTION_GET_MACRONUTRIENTS_ERROR,
  ActionType,
} from '../action-types';

interface IResponse {
  [key: string]: any;
}

export function* sagaMacronutrientsWatcher() {
    yield takeLatest(ACTION_GET_MACRONUTRIENTS_REQUEST, getMacronutrientsWorker);
}
export type GetMacronutrientsPayload ={
  mealId: number;
  mealPlanId?: number;
  selectedOptions?: any;
}
function* getMacronutrientsWorker({ payload }: ActionType<GetMacronutrientsPayload>) {
  try {
    const reqBodyObj: any = {
      meal_id: payload.mealId,
      meal_plan_id: payload.mealPlanId,
      currency_id: 1
    }

    if (payload.selectedOptions?.length) {
      const options: { option_id: number, component_id: number }[] = [];
      payload.selectedOptions.forEach((option: any) => {
          options.push({ option_id: option.optionId, component_id: option.componentId })
      });

      reqBodyObj.options = options;
    }

    yield put({ type: ACTION_GET_MACRONUTRIENTS_LOAD });
    const macronutrients = (yield call(() =>
      api.post('/macronutrients', reqBodyObj).then((res) => res.data.data)
    )) as IResponse;
    yield put({ type: ACTION_GET_MACRONUTRIENTS_SUCCESS, payload: macronutrients });
  } catch (error) {
    yield put({ type: ACTION_GET_MACRONUTRIENTS_ERROR, payload: { error }});
  }
}
