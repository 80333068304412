import moment from 'moment';
import { takeLatest, put, call } from 'redux-saga/effects';

import api from '../../managers/api';
import logger from 'src/managers/logger.manager';
import {
    ACTION_GET_SUBSCRIPTION_REQUEST,
    ACTION_GET_SUBSCRIPTION_LOAD,
    ACTION_GET_SUBSCRIPTION_SUCCESS,
    ACTION_GET_SUBSCRIPTION_ERROR,
    ACTION_GET_ACTIVE_SUBSCRIPTION_REQUEST,
    ACTION_GET_ACTIVE_SUBSCRIPTION_LOAD,
    ACTION_GET_ACTIVE_SUBSCRIPTION_SUCCESS,
    ACTION_GET_ACTIVE_SUBSCRIPTION_ERROR,
    ACTION_GET_CANCEL_SUBSCRIPTION_REQUEST,
    ACTION_GET_CANCEL_SUBSCRIPTION_LOAD,
    ACTION_GET_CANCEL_SUBSCRIPTION_SUCCESS,
    ACTION_GET_CANCEL_SUBSCRIPTION_ERROR,
    ACTION_GET_SUBSCRIPTION_PAUSES_REQUEST,
    ACTION_GET_SUBSCRIPTION_PAUSES_LOAD,
    ACTION_GET_SUBSCRIPTION_PAUSES_SUCCESS,
    ACTION_GET_SUBSCRIPTION_PAUSES_ERROR,
    ACTION_PAUSE_SUBSCRIPTION_REQUEST,
    ACTION_PAUSE_SUBSCRIPTION_LOAD,
    ACTION_PAUSE_SUBSCRIPTION_SUCCESS,
    ACTION_PAUSE_SUBSCRIPTION_ERROR,
    ACTION_RESUME_SUBSCRIPTION_REQUEST,
    ACTION_RESUME_SUBSCRIPTION_LOAD,
    ACTION_RESUME_SUBSCRIPTION_SUCCESS,
    ACTION_RESUME_SUBSCRIPTION_ERROR,
    ACTION_GET_RESUME_CHARGE_DATE_REQUEST,
    ACTION_GET_NEXT_CHARGE_DATE_REQUEST,
    ActionType,
} from '../action-types';

interface IResponse {
    [key: string]: any;
}

export function* sagaSubscriptionsWatcher() {
    yield takeLatest(ACTION_GET_SUBSCRIPTION_REQUEST, getSubscriptionWorker);
    yield takeLatest(ACTION_GET_ACTIVE_SUBSCRIPTION_REQUEST, getActiveSubscriptionWorker);
    yield takeLatest(ACTION_GET_CANCEL_SUBSCRIPTION_REQUEST, cancelSubscriptionsWorker);
    yield takeLatest(ACTION_GET_SUBSCRIPTION_PAUSES_REQUEST, getSubscriptionPausesWorker);
    yield takeLatest(ACTION_PAUSE_SUBSCRIPTION_REQUEST, pauseSubscriptionWorker);
    yield takeLatest(ACTION_RESUME_SUBSCRIPTION_REQUEST, resumeSubscriptionWorker);
    yield takeLatest(ACTION_GET_RESUME_CHARGE_DATE_REQUEST, getResumeSubscriptionChargeDateWorker);
    yield takeLatest(ACTION_GET_NEXT_CHARGE_DATE_REQUEST, getSubscriptionNextChargeDateWorker);
}

function* getSubscriptionWorker({ payload }: ActionType<{ subscriptionId: number }>) {
    try {
        yield put({ type: ACTION_GET_SUBSCRIPTION_LOAD });

        const subscription = (yield call(() => api.get(`/subscriptions/${payload.subscriptionId}`).then((res) => res.data.data))) as IResponse;

        yield put({ type: ACTION_GET_SUBSCRIPTION_SUCCESS, payload: subscription });
    } catch (error) {
        logger.error(error);
        yield put({ type: ACTION_GET_SUBSCRIPTION_ERROR, payload: { error }});
    }
}

function* getActiveSubscriptionWorker() {
    try {
        yield put({ type: ACTION_GET_ACTIVE_SUBSCRIPTION_LOAD });

        const activeSubscription = (yield call(() => api.get('/subscriptions/active').then((res) => res.data.data))) as IResponse;

        yield put({ type: ACTION_GET_ACTIVE_SUBSCRIPTION_SUCCESS, payload: Array.isArray(activeSubscription) && !activeSubscription.length ? null : activeSubscription });
    } catch (error) {
        logger.error(error);
        yield put({ type: ACTION_GET_ACTIVE_SUBSCRIPTION_ERROR, payload: { error }});
    }
}

function* cancelSubscriptionsWorker({ payload }: ActionType<{ subscriptionId: number, takeVoucher: boolean, onSuccess: any }>) {
    try {
        yield put({ type: ACTION_GET_CANCEL_SUBSCRIPTION_LOAD });

        const cancelSubscription = (yield call(() => api.get(`/subscriptions/${payload.subscriptionId}/cancel?take_voucher=${payload.takeVoucher}`).then((res) => res.data.data))) as IResponse;

        payload.onSuccess?.()

        yield put({ type: ACTION_GET_CANCEL_SUBSCRIPTION_SUCCESS, payload: Array.isArray(cancelSubscription) && !cancelSubscription.length ? null : cancelSubscription });
    } catch (error) {
        logger.error(error);
        yield put({ type: ACTION_GET_CANCEL_SUBSCRIPTION_ERROR, payload: { error }});
    }
}

function* getSubscriptionPausesWorker({ payload }: ActionType<{ subscriptionId: number, onSuccess: () => void }>) {
    try {
        yield put({ type: ACTION_GET_SUBSCRIPTION_PAUSES_LOAD });

        const pauses = (yield call(() => api.get(`/subscriptions/${payload.subscriptionId}/pauses`).then((res) => res.data.data))) as IResponse;

        payload.onSuccess?.()

        yield put({ type: ACTION_GET_SUBSCRIPTION_PAUSES_SUCCESS, payload: pauses });
    } catch (error) {
        logger.error(error);
        yield put({ type: ACTION_GET_SUBSCRIPTION_PAUSES_ERROR, payload: { error }});
    }
}

function* pauseSubscriptionWorker({ payload }: ActionType<{ subscriptionId: number, startPause: string, endPause?: string, showError?: (message1: string, errorMessages: string[]) => void, onSuccess: () => void }>) {
    try {
        yield put({ type: ACTION_PAUSE_SUBSCRIPTION_LOAD });

        const reqBody: { subscription_id: number; start_pause: string; end_pause?: string } = { subscription_id: payload.subscriptionId, start_pause: payload.startPause }
        if (payload.endPause) {
            reqBody.end_pause = payload.endPause;
        }

        const res = (yield call(() => api.post('/scheduled-pauses', reqBody).then((res) => res.data.data))) as IResponse;

        payload.onSuccess?.()

        yield put({ type: ACTION_PAUSE_SUBSCRIPTION_SUCCESS, payload: res });
    } catch (error: any) {
        logger.error(error);
        const errorMessages: string[] = [];
        //Obtain specific errors from the error object and pass to showError function
        if (error?.response?.data?.errors && typeof (error?.response?.data?.errors) === 'object') {
            Object.values(error?.response?.data?.errors).forEach((errorObject: any) => {
                if (Array.isArray(errorObject)) {
                    errorMessages.push(...errorObject)
                }
            });
        }
        payload.showError?.(error?.response?.data?.message || error?.response?.data?.data?.message || "Error", errorMessages);
        yield put({ type: ACTION_PAUSE_SUBSCRIPTION_ERROR, payload: { error }});
    }
}

function* resumeSubscriptionWorker({ payload }: ActionType<{ subscriptionId: number, onSuccess: () => void }>) {
    try {
        yield put({ type: ACTION_RESUME_SUBSCRIPTION_LOAD });

        const res = (yield call(() => api.patch(`/subscriptions/${payload.subscriptionId}/resume`).then((res) => res.data.data))) as IResponse;

        payload.onSuccess?.()

        yield put({ type: ACTION_RESUME_SUBSCRIPTION_SUCCESS, payload: res });
    } catch (error) {
        logger.error(error);
        yield put({ type: ACTION_RESUME_SUBSCRIPTION_ERROR, payload: { error }});
    }
}

function* getResumeSubscriptionChargeDateWorker({ payload }: ActionType<{ subscriptionId: number, startPause: string, endPause: string, showError?: (message: string) => void, onSuccess?: (chargeDate: number) => void }>) {
    try {

        const res = (yield call(() => api.get(`/stripe/subscriptions/pause?subscription_id=${payload.subscriptionId}&pause_start_date=${payload.startPause}&pause_end_date=${payload.endPause}`).then((res) => res.data.data))) as IResponse;

        if(res) {
            const nextChargePhases: {start_date: number}[] = res?.phases.filter((phas: {start_date: number}) => {
                return !phas.hasOwnProperty("trial_end") && moment.unix(phas.start_date).isAfter(moment().subtract(1, 'd'));
            });
            
            if(nextChargePhases.length > 0) {
                const nextChargeDate: number | undefined = nextChargePhases.sort((a, b) => b.start_date - a.start_date)[0].start_date;
                payload.onSuccess?.(nextChargeDate);
            }
        }
    } catch (error: any) {
        logger.error(error);
        payload.showError?.(error?.response?.data?.message || error?.response?.data?.data?.message || "Error");
    }
}

function* getSubscriptionNextChargeDateWorker({ payload }: ActionType<{ subscriptionId: number, today_date: string, onSuccess?: (chargeDate: number) => void }>) {
    try {

        const res = (yield call(() => api.get(`/stripe/subscriptions/resume?subscription_id=${payload.subscriptionId}&resume_start_date=${payload.today_date}`).then((res) => res.data.data))) as IResponse;

        if(res) {
            const nextChargePhases: {start_date: number}[] = res?.phases.filter((phas: {start_date: number}) => {
                return !phas.hasOwnProperty("trial_end") && moment.unix(phas.start_date).isAfter(moment().subtract(1, 'd'));
            });
            
            if(nextChargePhases.length > 0) {
                const nextChargeDate: number | undefined = nextChargePhases[0].start_date;

                payload.onSuccess?.(nextChargeDate);
            }
        }
    } catch (error: any) {
        logger.error(error);
    }
}