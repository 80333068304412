import {
    ACTION_GET_SUBSCRIPTION_LOAD,
    ACTION_GET_SUBSCRIPTION_SUCCESS,
    ACTION_GET_SUBSCRIPTION_ERROR,
    ACTION_GET_ACTIVE_SUBSCRIPTION_LOAD,
    ACTION_GET_ACTIVE_SUBSCRIPTION_SUCCESS,
    ACTION_GET_ACTIVE_SUBSCRIPTION_ERROR,
    ACTION_GET_CANCEL_SUBSCRIPTION_LOAD,
    ACTION_GET_CANCEL_SUBSCRIPTION_SUCCESS,
    ACTION_GET_CANCEL_SUBSCRIPTION_ERROR,
    ACTION_GET_SUBSCRIPTION_PAUSES_LOAD,
    ACTION_GET_SUBSCRIPTION_PAUSES_SUCCESS,
    ACTION_GET_SUBSCRIPTION_PAUSES_ERROR,
    ActionType,
} from '../action-types';

import { ISubscriptionsState } from 'src/types/state';

const initialState: ISubscriptionsState = {
    subscription: {
        loading: false,
        error: null,
        data: null,
    },
    activeSubscription: {
        loading: false,
        error: null,
        data: null,
    },
    cancelSubscription: {
        loading: false,
        error: null,
        data: null,
    },
    subscriptionPauses: {
        loading: false,
        error: null,
        data: [],
    }
};

export const subscriptions = (state = initialState, { type, payload }: ActionType<any>): ISubscriptionsState => {
    switch (type) {
        case ACTION_GET_SUBSCRIPTION_LOAD:
            return {
                ...state,
                subscription: {
                    ...state.activeSubscription,
                    loading: true,
                },
            };
        case ACTION_GET_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                subscription: {
                    ...state.activeSubscription,
                    loading: false,
                    data:    payload,
                },
            };
        case ACTION_GET_SUBSCRIPTION_ERROR:
            return {
                ...state,
                subscription: {
                    ...state.activeSubscription,
                    loading: false,
                    data: null,
                    error:   payload,
                },
            };
        case ACTION_GET_ACTIVE_SUBSCRIPTION_LOAD:
            return {
                ...state,
                activeSubscription: {
                    ...state.activeSubscription,
                    loading: true,
                },
            };
        case ACTION_GET_ACTIVE_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                activeSubscription: {
                    ...state.activeSubscription,
                    loading: false,
                    data:    payload,
                },
            };
        case ACTION_GET_ACTIVE_SUBSCRIPTION_ERROR:
            return {
                ...state,
                activeSubscription: {
                    ...state.activeSubscription,
                    loading: false,
                    error:   payload,
                },
            };
        case ACTION_GET_CANCEL_SUBSCRIPTION_LOAD:
            return {
                ...state,
                cancelSubscription: {
                    ...state.cancelSubscription,
                    loading: true,
                },
            };
        case ACTION_GET_CANCEL_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                cancelSubscription: {
                    ...state.cancelSubscription,
                    loading: false,
                    data:    payload,
                },
            };
        case ACTION_GET_CANCEL_SUBSCRIPTION_ERROR:
            return {
                ...state,
                cancelSubscription: {
                    ...state.cancelSubscription,
                    loading: false,
                    error:   payload,
                },
            };
        case ACTION_GET_SUBSCRIPTION_PAUSES_LOAD:
            return {
                ...state,
                subscriptionPauses: {
                    ...state.subscriptionPauses,
                    loading: true,
                },
            };
        case ACTION_GET_SUBSCRIPTION_PAUSES_SUCCESS:
            return {
                ...state,
                subscriptionPauses: {
                    ...state.subscriptionPauses,
                    loading: false,
                    data:    payload,
                },
            };
        case ACTION_GET_SUBSCRIPTION_PAUSES_ERROR:
            return {
                ...state,
                subscriptionPauses: {
                    ...state.subscriptionPauses,
                    loading: false,
                    error:   payload,
                },
            };
        default:
            return state;
    }
};
