import React from 'react';

import profileImage from 'src/assets/media/homePage/contentType/cardProfile.png';
import quotesImage from 'src/assets/media/homePage/contentType/quotesImg.png';

import styles from './TestimonialCardSection.module.sass';

interface Props {
    content1: string;
    content1name: string;
    content1role: string;
    image1: string;
    content2: string;
    content2name: string;
    content2role: string;
    image2: string;
    content3: string;
    content3name: string;
    content3role: string;
    image3: string;
}

const TestimonialCardSection: React.FC<Props> = ({
    content1,
    content1name,
    content1role,
    image1,
    content2,
    content2name,
    content2role,
    image2,
    content3,
    content3name,
    content3role,
    image3,
}) => {
    return (
        <div className = { styles.container }>
            <div className = { styles.cardsContainer }>

                <div className = { styles.testimonialCard }>
                    <p className = { styles.testimonialText }>
                        { content1 }
                    </p>
                    <img
                        alt = 'profileImage1'
                        className = { styles.testimonialCardProfile }
                        src = { image1 || profileImage }
                    />
                    <div className = { styles.testimonialName }>
                        <h4>{ content1name }</h4>
                        <p>{ content1role }</p>
                    </div>
                    <div className = { styles.quotesImage }>
                        <img
                            alt = 'quotesImage'
                            src = { quotesImage }
                        />
                    </div>
                </div>

                <div className = { styles.testimonialCard }>
                    <p className = { styles.testimonialText }>
                        { content2 }
                    </p>
                    <img
                        alt = 'profileImage2'
                        className = { styles.testimonialCardProfile }
                        src = { image2 || profileImage }
                    />
                    <div className = { styles.testimonialName }>
                        <h4>{ content2name }</h4>
                        <p>{ content2role }</p>
                    </div>
                    <div className = { styles.quotesImage }>
                        <img
                            alt = 'quotesImage'
                            src = { quotesImage }
                        />
                    </div>
                </div>

                <div className = { styles.testimonialCard }>
                    <p className = { styles.testimonialText }>
                       { content3 }
                    </p>
                    <img
                        alt = 'profileImage3'
                        className = { styles.testimonialCardProfile }
                        src = { image3 || profileImage }
                    />
                    <div className = { styles.testimonialName }>
                        <h4>{ content3name }</h4>
                        <p>{ content3role }</p>
                    </div>
                    <div className = { styles.quotesImage }>
                        <img
                            alt = 'quotesImage'
                            src = { quotesImage }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TestimonialCardSection;
