// Core
import React, { Suspense, useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { v4 as uuidv4 } from 'uuid';

// Components
import MainLayout from './layouts/MainLayout';
import { ProtectedRoute, ProtectedRouteProps } from './routers/ProtectedRoute';
import { RegionPrompt } from './components/RegionPrompt';
import Loader, { FullScreenLoader } from './components/Loader';

// Tools
import { routes } from './routers/routes';
import { useSeo } from './hooks/useSeo';
import { cookieManager } from './managers/cookieManager';
import { getItemFromLocalStorage, setItemInLocalStorage } from './utils';

// Config
import authRoutes from './routers/authRoutes';

// Types
import { 
    ACTION_GET_COUNTRIES_REQUEST, 
    ACTION_GET_REGIONS_REQUEST, 
    ACTION_LOGIN_REQUEST, 
    ACTION_GET_LANGUAGES_REQUEST, 
    ACTION_LOGOUT_REQUEST 
} from './store/action-types';
import { IRootState } from './types/state';
import { useLocation } from 'react-router';
import useReferral from './hooks/api/referral/useReferral';
import Content from './pages/content';
import { ROUTE_CONSTANTS } from './init/constants';
import useTracking from './hooks/api/crm/useTracking';
import { IAccount } from './types/account';
import Toast from './components/LR/toast/toast.component';

declare const window: any;

// Reviewed Top Level Folders

function App() {
    useSeo();
    useReferral();
    useTracking();
    
    const dispatch: Dispatch<any> = useDispatch();
    const history = useHistory();
    const { pathname } = useLocation();

    const regionRequiredPages = ["/meals", "/cart", "/plans", "/kick-starter-plans"];

    const auth = useSelector((state: IRootState) => state.auth);

    const searchParams = new URLSearchParams(location.search);

    const [ productionAuth, setProductionAuth ] = useState(false);

    useEffect(() => {
        //handle temp site password
        const userAllowed = getItemFromLocalStorage('trainx-thorin')

        if (!userAllowed) {
            const passwordInput = prompt("Enter Password:");
            if (passwordInput !== "kal##2892PIM!Qj") {
                history.push('/404');
            } else {
                setItemInLocalStorage('trainx-thorin', "true");
            }
        }
    
        // set session id, even if user is not authenticated
        const sessionId = uuidv4();
        //Implement Smartlook Identify API
        const win_smartlook = window.smartlook;
        if (!getItemFromLocalStorage('session-id') || searchParams.get('redirectToCheckout') || searchParams.get('from_checkout')) {
            setItemInLocalStorage('session-id', sessionId);

            //Send smartlook request
            if(win_smartlook) {
                if(auth.isAuthenticated){
                    win_smartlook('identify', auth.userInfo?.email || sessionId, {
                        email: auth.userInfo?.email,
                        firstName: auth.userInfo?.first_name,
                        lastName: auth.userInfo?.last_name
                    });
                } else {
                    win_smartlook('identify', sessionId);
                }
            }
        } else {
            if(win_smartlook) {
                if(auth.isAuthenticated){
                    win_smartlook('identify', auth.userInfo?.email || getItemFromLocalStorage('session-id'), {
                        email: auth.userInfo?.email,
                        firstName: auth.userInfo?.first_name,
                        lastName: auth.userInfo?.last_name
                    });
                } else {
                    win_smartlook('identify', getItemFromLocalStorage('session-id'));
                }
            }
        }
        
        const authCookie = cookieManager.get('auth');
        const accessTokenCookie = cookieManager.get('access_token');

        if (authCookie && accessTokenCookie) {
            if (!JSON.parse(authCookie).accounts.find((acc: IAccount) => acc.type === "client")) {
                // logout if user doesn't have a client account
                dispatch ({ type: ACTION_LOGOUT_REQUEST });
                return;
            }

            dispatch({ type: ACTION_LOGIN_REQUEST, payload: { userInfo: authCookie } });
            // Don't show home page for authenticated users
            if (window.location.pathname === '/') {
                history.push('/dashboard');
            }
        } else {
            dispatch ({ type: ACTION_LOGOUT_REQUEST });
            // Show CMS home page for unauthenticated users
            if (window.location.pathname === '/') {
                // history.push('/home');
                window.location.assign('/home');
            }
        }

        dispatch({ type: ACTION_GET_COUNTRIES_REQUEST });
        dispatch({ type: ACTION_GET_REGIONS_REQUEST });
        dispatch({ type: ACTION_GET_LANGUAGES_REQUEST });
    }, []);

    useEffect(() => {
        //handle temp site password
        const userAllowed = getItemFromLocalStorage('trainx-thorin')

        if (!userAllowed) {
            const passwordInput = prompt("Enter Password:");
            if (passwordInput !== "kal##2892PIM!Qj") {
                history.push('/404');
            } else {
                setItemInLocalStorage('trainx-thorin', "true");
            }
        }

        window.scrollTo(0, 0);
        
        // Show CMS home page for unauthenticated users
        if (window.location.pathname === '/' && !auth.isAuthenticated) {
            // history.push('/home');
            window.location.assign('/home');
        }
    }, [ pathname ]);

    useEffect(() => {
        // refetch locale data on logout
        if (!auth.isAuthenticated) {
            dispatch({ type: ACTION_GET_COUNTRIES_REQUEST });
            dispatch({ type: ACTION_GET_REGIONS_REQUEST });
            dispatch({ type: ACTION_GET_LANGUAGES_REQUEST });
        }
    }, [ auth.isAuthenticated ]);

    const defaultProtectedRouteProps: ProtectedRouteProps = {
        isAuthenticated:    !!cookieManager.get('auth'),
        authenticationPath: '/',
    };

    return (
        <MainLayout isAuthenticated = { auth.isAuthenticated }>
            <Suspense fallback = { searchParams.get('redirectToCheckout') ? <FullScreenLoader /> : <Loader className = 'w-full h-screen' /> }>
                <Switch>
                    {
                        routes.map((route) => (
                            <Route
                                exact
                                key = { route.url }
                                path = { route.url }
                                { ...route.props }>
                                <route.Component />
                            </Route>
                        ))
                    }
                    {
                        authRoutes.map((route) => (
                            <ProtectedRoute
                                { ...defaultProtectedRouteProps }
                                exact
                                component = { route.Component }
                                key = { route.url }
                                path = { route.url }
                            />
                        ))
                    }

                    <Route
                        exact
                        path = { ROUTE_CONSTANTS.SLUG }>
                        <Content />
                    </Route>
                </Switch>
                <Toast />

                {
                    regionRequiredPages.includes(pathname) &&
                    <RegionPrompt />
                }
            </Suspense>
        </MainLayout>
    );
}

export default App;
