function getContents(data: any): any[] {
  return data.contents || []
}

export function parseFaqContent(data: any) {
  const contents = getContents(data)
  const titles = contents.filter(c => c.key === 'title').map(a => a.value)
  const answers = contents.filter(c => c.key === 'answer').map(a => a.value)
  return titles.map((title, index) => ({
    title,
    answer: answers[index]
  }))
}

export function parseHeroContent(data: any) {
  const contents = getContents(data)
  const preTitle = contents.find(c => c.key === 'pre_title')?.value || ''
  const title = contents.find(c => c.key === 'title')?.value || ''
  const desc = contents.find(c => c.key === 'description')?.value || ''
  const buttonText = contents.find(c => c.key === 'button_text')?.value || ''
  const buttonUrl = contents.find(c => c.key === 'button_url')?.value || ''
  const backgroundImage = contents.find(c => c.key === 'background_image')?.value || ''

  return {
    preTitle,
    title,
    desc,
    buttonText,
    buttonUrl,
    backgroundImage,
  }
}

export function parseCtaContent(data: any) {
  return parseHeroContent(data)
}

export function parseSideIconsContent(data: any) {
  const contents = getContents(data)
  const titles = contents.filter(c => c.key === 'title').map(a => a.value)
  const desc = contents.filter(c => c.key === 'description').map(a => a.value)
  const icons = contents.filter(c => c.key === 'icon').map(a => a.value)
  return titles.map((title, index) => ({
    title,
    desc: desc[index],
    icon: icons[index],
  }))
}

export function parseLifestyleContent(data: any) {
  const contents = getContents(data)
  
  const title = contents.find(c => c.key === 'title')?.value || ''
  const text = contents.find(c => c.key === 'text')?.value || ''
  const buttonText = contents.find(c => c.key === 'button_text')?.value || ''
  const image1 = contents.find(c => c.key === 'image_1')?.value || ''
  const image2 = contents.find(c => c.key === 'image_2')?.value || ''
  const image3 = contents.find(c => c.key === 'image_3')?.value || ''
  const image4 = contents.find(c => c.key === 'image_4')?.value || ''
  const image5 = contents.find(c => c.key === 'image_5')?.value || ''
  const image6 = contents.find(c => c.key === 'image_6')?.value || ''

  return {
    title,
    text,
    buttonText,
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
  }
}

export function parseImageVideoText(data: any) {
  const contents = getContents(data)
  
  const title = contents.find(c => c.key === 'title')?.value || ''
  const mainText = contents.find(c => c.key.startsWith('main_tex'))?.value || ''
  const buttonText = contents.find(c => c.key === 'button_text')?.value || ''
  const buttonUrl = contents.find(c => c.key === 'button_url')?.value || ''
  const image = contents.find(c => c.key === 'image')?.value || ''
  const video = contents.find(c => c.key === 'video_url')?.value || ''

  return {
    title,
    mainText,
    buttonText,
    buttonUrl,
    image,
    video,
  }
}

export function parseVideoTestimonial(data: any) {
  const contents = getContents(data)
  
  const video1 = contents.find(c => c.key === 'video_1')?.value || ''
  const video1name = contents.find(c => c.key === 'video_1_name')?.value || ''
  const video1role = contents.find(c => c.key === 'video_1_role')?.value || ''
  const video2 = contents.find(c => c.key === 'video_2')?.value || ''
  const video2name = contents.find(c => c.key === 'video_2_name')?.value || ''
  const video2role = contents.find(c => c.key === 'video_2_role')?.value || ''

  return {
    video1,
    video1name,
    video1role,
    video2,
    video2name,
    video2role,
  }
}

export function parseTestimonial(data: any) {
  const contents = getContents(data)
  
  const content1 = contents.find(c => c.key === 'content_1')?.value || ''
  const content1name = contents.find(c => c.key === 'content_1_name' || c.key === 'content_name_1')?.value || ''
  const content1role = contents.find(c => c.key === 'content_1_role' || c.key === 'content_role_1')?.value || ''
  const image1 = contents.find(c => c.key === 'image_1')?.value || ''
  const content2 = contents.find(c => c.key === 'content_2')?.value || ''
  const content2name = contents.find(c => c.key === 'content_2_name' || c.key === 'content_name_2')?.value || ''
  const content2role = contents.find(c => c.key === 'content_2_role' || c.key === 'content_role_2')?.value || ''
  const image2 = contents.find(c => c.key === 'image_2')?.value || ''
  const content3 = contents.find(c => c.key === 'content_3')?.value || ''
  const content3name = contents.find(c => c.key === 'content_3_name' || c.key === 'content_name_3')?.value || ''
  const content3role = contents.find(c => c.key === 'content_3_role' || c.key === 'content_role_3')?.value || ''
  const image3 = contents.find(c => c.key === 'image_3')?.value || ''

  return {
    content1,
    content1name,
    content1role,
    image1,
    content2,
    content2name,
    content2role,
    image2,
    content3,
    content3name,
    content3role,
    image3
  }
}

export function parseAboutContent(data: any) {
  const contents = getContents(data)
  const preTitle = contents.find(c => c.key === 'pre_title')?.value || ''
  const title = contents.find(c => c.key === 'title')?.value || ''
  const heroImage = contents.find(c => c.key === 'background_image')?.value || ''
  const mainText = contents.find(c => c.key === 'main_text')?.value || ''

  return {
    preTitle,
    title,
    heroImage,
    mainText,
  }
}
