// Core
import { useEffect, useState } from 'react';

// Tols
import api from '../../../managers/api';
import { IDeliveryRegion } from '../../../types/delivery';

export const useGetDeliveryRegions = () => {
    const [ deliveryRegions, setDeliveryRegions ] = useState<IDeliveryRegion[]>([]);

    const handleGetDeliveryRegions = async () => {
        try {
            const regionsResponse = await api.get('/delivery-regions');
            setDeliveryRegions(regionsResponse.data.data as IDeliveryRegion[]);
        } catch (error) {
            console.error('Error while fetching delivery regions');
        }
    };

    useEffect(() => {
        handleGetDeliveryRegions();
    }, []);

    return { deliveryRegions };
};
