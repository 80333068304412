import React, { useState } from 'react';
import { Transition } from 'react-transition-group';
import clsx from 'clsx';

import downArrowIcon from 'src/assets/media/icons/down-arrow.svg';

import styles from './Question.module.sass';

const duration = 300;

const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity:    0,
};

const transitionStyles = {
    entering:  { opacity: 0 },
    entered:   { opacity: 1 },
    exiting:   { opacity: 0 },
    exited:    { opacity: 0 },
    unmounted: { opacity: 0 },
};

interface QuestionProps {
    question: {
        title: string
        answer: string
    }
    // key: number | string
    num: number
}

const Question: React.FC<QuestionProps> = (props) => {
    const { question, num } = props;
    const [ active, setActive ] = useState(false);

    const handleArrowClick = () => {
        setActive(!active);
    };

    return (
        <div className = { styles.question }>
            <div
                className = { styles.questionTitle }
                onClick = { handleArrowClick }>
                <h3>
                    {num + '. '}{question.title}
                </h3>
                <div>
                    <img
                        alt = 'downArrowIcon'
                        className = { clsx({ [styles.upArrow]: active }) }
                        src = { downArrowIcon }
                    />
                </div>
            </div>
            <Transition
                unmountOnExit
                in = { active }
                timeout = { duration }>
                {
                    (state) => {
                        return (
                            <div
                                className = { styles.questionBody }
                                style = {{
                                    ...defaultStyle,
                                    ...transitionStyles[state],
                                }}
                                onClick = { handleArrowClick }>
                                { question.answer }
                            </div>
                        );
                    }
                }
            </Transition>
        </div>
    );
};


export default Question;
