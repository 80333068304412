import { takeLatest, put, call } from 'redux-saga/effects';

import api from '../../managers/api';
import {
    ACTION_GET_COUNTRIES_REQUEST,
    ACTION_GET_COUNTRIES_LOAD,
    ACTION_GET_COUNTRIES_SUCCESS,
    ACTION_GET_COUNTRIES_ERROR,
    ACTION_GET_REGIONS_REQUEST,
    ACTION_GET_REGIONS_LOAD,
    ACTION_GET_REGIONS_SUCCESS,
    ACTION_GET_REGIONS_ERROR,
    ACTION_GET_LANGUAGES_REQUEST,
    ACTION_GET_LANGUAGES_LOAD,
    ACTION_GET_LANGUAGES_ERROR,
    ACTION_GET_LANGUAGES_SUCCESS,
} from '../action-types';

interface IResponse {
    [key: string]: any;
}

export function* sagaLocaleWatcher() {
    yield takeLatest(ACTION_GET_COUNTRIES_REQUEST, getCountriesWorker);
    yield takeLatest(ACTION_GET_REGIONS_REQUEST, getRegionsWorker);
    yield takeLatest(ACTION_GET_LANGUAGES_REQUEST, getLanguagesWorker);
}

function* getCountriesWorker() {
    try {
        yield put({ type: ACTION_GET_COUNTRIES_LOAD });

        const countries = (yield call(() => api.get('/countries', { baseURL: process.env.REACT_APP_ALLRIGHT_BASE_API_URL }).then((res) => res.data.data))) as IResponse;

        yield put({ type: ACTION_GET_COUNTRIES_SUCCESS, payload: countries });
    } catch (error) {
        yield put({ type: ACTION_GET_COUNTRIES_ERROR, payload: { error }});
    }
}

function* getRegionsWorker() {
    try {
        yield put({ type: ACTION_GET_REGIONS_LOAD });

        const regions = (yield call(() => api.get('/delivery-regions').then((res) => res.data.data))) as IResponse;

        yield put({ type: ACTION_GET_REGIONS_SUCCESS, payload: regions });
    } catch (error) {
        yield put({ type: ACTION_GET_REGIONS_ERROR, payload: { error }});
    }
}

function* getLanguagesWorker() {
    try {
        yield put({ type: ACTION_GET_LANGUAGES_LOAD });

        const languages = (yield call(() => api.get('/languages').then((res) => res.data.data))) as IResponse;

        yield put({ type: ACTION_GET_LANGUAGES_SUCCESS, payload: languages });
    } catch (error) {
        yield put({ type: ACTION_GET_LANGUAGES_ERROR, payload: { error }});
    }
}