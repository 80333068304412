// Core
import { takeLatest, put, call } from 'redux-saga/effects';

import { IUserInfo } from '../../../types/account';

// Tools
import api from "../../../managers/api";
import { cookieManager } from '../../../managers/cookieManager';

// Types
import {
    ACTION_LOGIN_REQUEST,
    ACTION_LOGIN_SUCCESS,
    ACTION_LOGIN_ERROR,
    ACTION_LOGOUT_REQUEST,
    ACTION_LOGOUT_SUCCESS,
    ACTION_LOGOUT_ERROR,
    ACTION_GET_USER_ACCOUNT_REQUEST,
    ACTION_GET_USER_ACCOUNT_SUCCESS,
    ACTION_UPDATE_USER_ACCOUNT_REGION_REQUEST,
    ACTION_UPDATE_USER_ACCOUNT_REGION_SUCCESS,
    ACTION_UPDATE_USER_PROFILE_REQUEST,
    ACTION_UPDATE_USER_PROFILE_LOAD,
    ACTION_UPDATE_USER_PROFILE_SUCCESS,
    ACTION_UPDATE_USER_PROFILE_ERROR,
    ActionType,
} from '../../action-types';
import {IAccount, IuserAccountAndRegionId} from "../../../types/account";
import {IUpdateUserAccountRequestAction} from "./types";

export function* sagaAuthWatcher() {
    // logger.info('AUTH SAGA INIT');
    yield takeLatest(ACTION_LOGIN_REQUEST, loginWorker);
    yield takeLatest(ACTION_LOGOUT_REQUEST, logoutWorker);
    yield takeLatest(ACTION_GET_USER_ACCOUNT_REQUEST, userAccountRegionWorker);
    yield takeLatest(ACTION_UPDATE_USER_ACCOUNT_REGION_REQUEST, updateUserAccountRegionWorker);
    yield takeLatest(ACTION_UPDATE_USER_PROFILE_REQUEST, updateUserProfileWorker);
}

function* loginWorker({ payload }: ActionType<{ userInfo: string, onSuccess?: () => void }>) {
    try {
        const userInfo: IUserInfo = JSON.parse(payload.userInfo);
        const account = userInfo.accounts.find((account: IAccount) => account.is_current);
        const defaultAddress = account?.addresses?.find((address) => address.is_default);
       
        // Hubspot CRM integration
        const _hsq = (window as any)._hsq = (window as any)._hsq || [];
        _hsq.push(["identify", {
            email: userInfo?.email || '',
            firstname: userInfo?.first_name || '',
            lastname: userInfo?.last_name || '',
            gender: userInfo?.gender || '',
            date_of_birth: userInfo?.birthday || '',
            hs_language: account?.profile?.language?.code || '',
            phone: account?.profile?.phone_number || '',
            country: defaultAddress?.country?.name_english || '',
            zip: defaultAddress?.postal_code || '',
            state: defaultAddress?.region || '',
            city: defaultAddress?.city || '',
            address: defaultAddress?.address || '',
        }]);

        yield put({ type: ACTION_LOGIN_SUCCESS, payload: userInfo });
        
        payload.onSuccess && payload.onSuccess();
    } catch (error) {
        yield put({ type: ACTION_LOGIN_ERROR, payload: { error }});
    }
}

function* logoutWorker() {
    try {
        cookieManager.remove('access_token');
        cookieManager.remove('auth');

        yield put({ type: ACTION_LOGOUT_SUCCESS });
    } catch (error) {
        yield put({ type: ACTION_LOGOUT_ERROR, payload: { error }});
    }
}

function* userAccountRegionWorker({ payload }: ActionType<{ accountId: number }>) {
    try {
        const userRegionInfo: IuserAccountAndRegionId = yield call(() => api.get(`/accounts/${payload.accountId}`).then((res) => res.data.data))
        yield put({ type: ACTION_GET_USER_ACCOUNT_SUCCESS, payload: userRegionInfo });
    } catch (error) {
        console.error('userAccountRegionWorker error', error)
    }
}

function* updateUserAccountRegionWorker({ payload }: IUpdateUserAccountRequestAction) {
    try {
        const newUserAccountInfo: IuserAccountAndRegionId = yield call(() => api.patch(`/accounts/${payload.id}/region`, payload.account).then((res) => res.data.data))
        yield put({ type: ACTION_UPDATE_USER_ACCOUNT_REGION_SUCCESS, payload: newUserAccountInfo });
        // cookieManager.set('region', newUserAccountInfo.region);
    } catch (error) {
        console.error('updateUserAccountRegionWorker error', error)
    }
}

function* updateUserProfileWorker({ payload }: ActionType<{
    addressId: number;
    address: string;
    postalCode: string;
    city: string;
    area: string;
    phoneNo: string;
    firstName: string;
    lastName: string;
    onSuccess?: () => void
}>) {
    try {
        yield put({ type: ACTION_UPDATE_USER_PROFILE_LOAD });

        const reqBodyObj = {
            profile: {
                phone_number: payload.phoneNo || ''
            },
            addresses: [
                {
                    id: payload.addressId,
                    address: payload.address,
                    postal_code: payload.postalCode,
                    city: payload.city,
                    area: payload.area,
                    is_default: true
                }
            ],
            ...(payload.firstName && { first_name: payload.firstName }),
            ...(payload.lastName && { last_name: payload.lastName }),
        }

        const userInfo = (yield call(() => api.put('/user/generic-profile', reqBodyObj, { baseURL: process.env.REACT_APP_ALLRIGHT_BASE_API_URL }).then((res) => res.data.data))) as {  [key: string]: any; };

        yield put({ type: ACTION_UPDATE_USER_PROFILE_SUCCESS, payload: userInfo });

        // add region info
        yield put({ type: ACTION_GET_USER_ACCOUNT_REQUEST, payload: { accountId: userInfo.accounts.find((account: IAccount) => account.is_current)?.id || '' }});

        // save user info in auth cookie
        cookieManager.set('auth', JSON.stringify(userInfo));

        payload.onSuccess && payload.onSuccess()
    } catch (error) {
        yield put({ type: ACTION_UPDATE_USER_PROFILE_ERROR });
        console.error('updateProfileWorker error', error)
    }
}
