import React from 'react';
import MediaQuery from 'react-responsive';

interface Props {
    children: React.ReactNode
}

export const DesktopLayout: React.FC<Props> = ({ children }) => (
    <MediaQuery minWidth = { 768 }>
        { React.Children.count(children) ? children : null }
    </MediaQuery>
);

export const MobileLayout: React.FC<Props> = ({ children }) => (
    <MediaQuery maxWidth = { 767 }>
        { React.Children.count(children) ? children : null }
    </MediaQuery>
);
