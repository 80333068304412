// Core
import React, { FC } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

// Elements
import HeroImage from 'src/assets/media/home/hero-image.png';
import { ROUTE_CONSTANTS } from 'src/init/constants';

// Styles
import styles from './Hero.module.sass';
import { BaseButton } from 'src/components/BaseButton';

type HeroType = FC<{ 
    className?: string, 
    preTitle: string, 
    title: string, 
    desc: string,
    buttonText: string,
    buttonUrl: string,
    backgroundImage: string, 
}>;

const Hero: HeroType = ({
    className,
    title,
    preTitle,
    desc,
    buttonText,
    buttonUrl,
    backgroundImage,
}) => {
    return (
        <section className = { clsx('common-section', className, 'overflow-hidden lg:pt-14 lg:pb-1') }>
            <div className = 'common-container flex flex-col lg:flex-row'>
                <div className = { styles['container-1'] }>
                    <h3 className = { styles.subtitle }>{preTitle}</h3>
                    <h1 className = { styles.title }>{title}</h1>
                    <p className = { styles.description }>
                        {desc}
                    </p>
                    <div className = { styles.buttonContainer }>
                        {/* <a href = {ROUTE_CONSTANTS.KICK_START_PLANS}>
                            <BaseButton className = { styles.kickstarterbutton }>
                                Kick Starter Plans
                            </BaseButton>
                        </a> */}
                        <a href = {ROUTE_CONSTANTS.MEALS}>
                            <BaseButton className = { styles.button }>
                                {buttonText}
                            </BaseButton>
                        </a>
                    </div>
                </div>
                <div className = { styles['container-2'] }>
                    <img
                        alt = 'hero'
                        className = { styles['hero-img'] }
                        src = { backgroundImage || HeroImage }
                    />
                </div>
            </div>
        </section>
    );
};

export default Hero;
