import React, { useState, useEffect } from 'react';

export type AnimatorProps = {
    value: number;
    duration: number;
    func?: (x: number) => number;
    children: React.ComponentType<{value: number}>;
};

const Animator = ({ value, duration, func, children: Children }: AnimatorProps) => {
    const [ val, setVal ] = useState(value);
    const [ startTime, setStartTime ] = useState(0);
    useEffect(() => {
        setStartTime(new Date().getTime());
    }, [ value ]);
    useEffect(() => {
        // eslint-disable-next-line
        frame();
    }, [ startTime ]);
    const frame = () => {
        // eslint-disable-next-line
        if (val === value) { return; }
        const progress = (new Date().getTime() - startTime) / duration;
        // eslint-disable-next-line
        if (progress >= 1) { return setVal(value); }
        const linear = (x:number) => x;
        // eslint-disable-next-line
        setVal(val + (value - val) * (func || linear)(progress));
        requestAnimationFrame(frame);
    };

    return <Children value = { val }/>;
};
Animator.LINEAR = (x:number) => x;
Animator.EASE_OUT = (x:number) => Number(Math.sin(x * Math.PI / 2).toFixed(5));
// eslint-disable-next-line
Animator.EASE_IN = (x:number) => Number((Math.sin(Math.PI * 3 / 2 + x * Math.PI / 2) + 1).toFixed(5));
// eslint-disable-next-line
Animator.EASE = (x:number) => Number(((Math.cos(Math.PI + x * Math.PI) + 1) / 2).toFixed(5));
// eslint-disable-next-line
Animator.SPRING = (volume:number = 1) => (x:number) => Number((-((x - 1) ** 2) * Math.cos(x * volume * Math.PI * 2) + 1).toFixed(5));
export default Animator;
