import * as Yup from 'yup';

export const validationSchemaContacts = Yup.object({
    first_name: Yup.string().required('This value is required!'),
    last_name:  Yup.string().required('This value is required!'),
    phone:      Yup.string().optional(),
    message:    Yup.string().min(25, 'Message must be min 25 characters')
        .required('This value is required!'),
    email: Yup.string().email('Invalid email')
        .required('This value is required!'),
});
