import React from 'react';
import parse from 'html-react-parser';

import styles from './FullText.module.sass';

interface Props {
    text: string;
}

const FullText: React.FC<Props> = ({ text }) => {
    return (
        <div className = { styles.container }>
            <div>
                {parse(text)}
            </div>
        </div>
    );
};

export default FullText;
