import React, { useState, useRef } from 'react';
import { useEvent } from './useEvent';

type SwipeType = {
    x: number;
    y: number;
}
// eslint-disable-next-line
export const useSwipe = (ref: React.RefObject<HTMLElement>, minSwipe:(e:SwipeType) => boolean, onSwipeEnd: (e:SwipeType) => void) => {
    const swiping = useRef<boolean>(false);
    const swipeStart = useRef<SwipeType>({ x: 0, y: 0 });
    const [ startData, setStartData ] = useState<SwipeType>({ x: 0, y: 0 });
    const [ currentData, setCurrentData ] = useState<SwipeType>({ x: 0, y: 0 });
    const getTouchPoints = (event: TouchEvent) => ({
        x: event.changedTouches[0].clientX,
        y: event.changedTouches[0].clientY,
    });
    useEvent('touchstart', (event) => {
        if (ref.current?.contains(event.target as HTMLElement)) {
            swiping.current = true;
            setStartData(getTouchPoints(event as TouchEvent));
            setCurrentData(getTouchPoints(event as TouchEvent));
            swipeStart.current = getTouchPoints(event as TouchEvent);
        }
    });
    useEvent('touchend', (event) => {
        if (!swiping.current) {
            return;
        }
        swiping.current = false;
        const { x, y } = getTouchPoints(event as TouchEvent);
        const progress = { x: x - swipeStart.current.x, y: y - swipeStart.current.y };
        if (minSwipe(progress)) {
            onSwipeEnd({ x: x - swipeStart.current.x, y: y - swipeStart.current.y });
            setTimeout(() =>{
                setStartData({ x: 0, y: 0 });
                setCurrentData({ x: 0, y: 0 });
            }, 400);
        } else {
            setStartData({ x: 0, y: 0 });
            setCurrentData({ x: 0, y: 0 });
        }
    });
    useEvent('touchmove', (event) => {
        if (swiping.current) {
            setCurrentData(getTouchPoints(event as TouchEvent));
        }
    });

    return { x: currentData.x - startData.x, y: currentData.y - startData.y };
};
