import React, { useState } from 'react';
import styles from './mobileFooter.module.sass';
import clsx from 'clsx';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import { ReactComponent as HomeIcon } from 'src/assets/media/icons/home.svg';
import { ReactComponent as PlanIcon } from 'src/assets/media/icons/plan.svg';
import { ReactComponent as MealIcon } from 'src/assets/media/icons/meal.svg';
import { ReactComponent as SettingsIcon } from 'src/assets/media/icons/settings.svg';
import { ReactComponent as TargetIcon } from 'src/assets/media/icons/target.svg';
import { ReactComponent as MoreIcon } from 'src/assets/media/icons/more-vertical.svg';
import { ReactComponent as UserIcon } from 'src/assets/media/icons/user.svg';
import { ReactComponent as LogOutIcon } from 'src/assets/media/icons/log-out.svg';
import { ReactComponent as PlateIcon } from 'src/assets/media/icons/covered-plate.svg';
import MobileMoreDrawer from 'src/components/LR/mobile-more-drawer/mobile-more-drawer.component';

import { ACTION_LOGOUT_REQUEST } from 'src/store/action-types';

import { getIdentityURL } from 'src/utils';
import { ROUTE_CONSTANTS } from 'src/init/constants';

const MobileFooter = () => {
    const [ moreDrawerOpen, setMoreDrawerOpen ] = useState(false);
    const { pathname } = useLocation();
    const dispatch: Dispatch<any> = useDispatch();
    const history = useHistory();
    const auth = useSelector((state: RootStateOrAny) => state.auth);
    const { isAuthenticated } = auth;

    const handleLogout = () => {
        dispatch({ type: ACTION_LOGOUT_REQUEST });
        history.push('/');
        setMoreDrawerOpen(false);
    };

    const menuItems = [
        { name: 'Home', url: ROUTE_CONSTANTS.DASHBOARD, Icon: HomeIcon },
        { name: 'Orders', url: ROUTE_CONSTANTS.ORDERS, Icon: PlanIcon },
        { name: 'Meals', url: ROUTE_CONSTANTS.MEALS, Icon: MealIcon },
        // { name: 'Targets', url: ROUTE_CONSTANTS.TARGETS, Icon: TargetIcon },
        { name: 'More', Icon: MoreIcon, onClick: () => setMoreDrawerOpen(true) },
    ];

    const moreItems = [
        { name: 'Profile', url: getIdentityURL('/profile'), Icon: UserIcon },
        { name: 'Meals', url: ROUTE_CONSTANTS.MEALS, Icon: MealIcon },
        // { name: 'Targets', url: ROUTE_CONSTANTS.TARGETS, Icon: TargetIcon },
        // { name: 'Starter Plans', url: ROUTE_CONSTANTS.KICK_START_PLANS, Icon: PlateIcon },
        { name: 'Settings', url: ROUTE_CONSTANTS.SETTINGS.split('/:')[0], Icon: SettingsIcon },
        { name: 'Log Out', onClick: () => handleLogout(), Icon: LogOutIcon },
    ];

    return (
        isAuthenticated && (
            <div className = { styles.footerWrapper }>
                <nav className = { styles.navBox }>
                    {menuItems.map(({ name, url, Icon, onClick }) => {
                        const child = (
                            <div
                                className = {
                                    clsx({
                                        [styles.navItem]:            true,
                                        [styles['navItem--active']]: pathname === url,
                                    }) }
                                key = { url }>
                                <Icon className = { styles.navIcon }/>
                                <span className = { styles.navTitle }>{ name }</span>
                            </div>
                        );

                        return url ? (
                            <Link
                                key = { name }
                                to = { url }>
                                {child}
                            </Link>
                        ) : (
                            <div
                                key = { name }
                                onClick = { onClick }>
                                {child}
                            </div>
                        );
                    })}
                </nav>
                <MobileMoreDrawer
                    isOpen = { moreDrawerOpen }
                    menuItems = { moreItems }
                    onClose = { () => setMoreDrawerOpen(false) }
                />
            </div>
        )
    );
};

export default MobileFooter;
