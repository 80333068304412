// Core
import React, { FC } from 'react';
import clsx from 'clsx';

// Styles
import styles from './MealNutrient.module.sass';

type MealNutrientCardType = FC<{
    className?: string;
    title: string;
    nutrientAmount: string;
}>;

export const MealNutrientCard: MealNutrientCardType = ({
    className,
    title,
    nutrientAmount,
}) => {
    return (
        <div className = { clsx(className, styles.card) }>
            <h5 className = { clsx(styles.title, 'mb-2') }>{title}</h5>
            <p className = { styles.amount }>{nutrientAmount}</p>
        </div>
    );
};
