import React, { ReactElement, ReactChildren } from 'react';
import { Table, TableProps } from 'antd';

import Styles from './table.styles';

type SettingsDataTableProps<R> = {
    className?: string,
    children?: ReactChildren | JSX.Element[],
    pagination?: boolean,
    footer?: () => JSX.Element,
} & TableProps<R>;

const SettingsDataTable = <R extends object>({
    columns,
    dataSource,
    children,
    ...rest
}: SettingsDataTableProps<R>): ReactElement => {
    return (
        <Styles>
            <Table
                className = 'table'
                columns = { columns }
                dataSource = { dataSource }
                { ...rest }>
                { children }
            </Table>
        </Styles>
    );
};

export default SettingsDataTable;
