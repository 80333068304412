import styled from 'styled-components';

import { mediaQueries } from 'src/enums/screen-sizes.enum';
import { getColorCarry } from 'src/pipes/theme-color.pipe';

export default styled.div`
    display: flex;
    flex-direction: column;
    gap: 0;
`;

export const SectionTitle = styled.h2`
    color: #2E2F31;
    font-weight: bold;
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 20px;
`;

export const CreditDetails = styled.h2`
    color: #2E2F31;
    font-weight: bold;
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 20px;

    & .title {
        color: #90BF45;
    }
`;

export const Section = styled.section`
    padding: 10px 0 30px;
`;

export const InputContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;

    & .input {
        width: 500px;
        box-shadow: 0px 0px 40px rgba(230, 45, 71, 0.03);
        border-radius: 10px;

        input {
            height: 60px;
        }

        &.error {
            input {
                border: 2px solid ${getColorCarry('error')};
                margin-bottom: 4px;
            }
        }
    }

    @media ${mediaQueries.MOBILE} {
        flex-direction: column;

        & .input {
            width: 100%;
        }
    }
`;

export const PrimaryButton = styled.button`
    align-self: flex-end;
    color: white;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    width: fit-content;
    height: 60px;
    padding: 14px 50px;
    background: #90BF45;
    border-radius: 10px;

    &:hover {
        background-color: #AFC88A;
        color: white;
        border-color: inherit;
    }

    @media ${mediaQueries.MOBILE} {
        width: 100%;
    }
`;

export const TabularData = styled.table`
    width: 100%;
    margin-bottom: 10px;

    &.title {
        width: 50%;
    }

    & .value {
        width: 50%;
        text-align: right;
        padding: 4px;
    }

    & .removeButton {
        display: flex;
        justify-content: end;
        margin-left: auto;
        color: red;
    }  cursor: pointer;

    & .status {
        padding: 8px 16px;
        border: 1px dashed #EF1733;
        background-color: #FFEBEB;
        border-radius: 10px;
        color: #EF1733;
        text-transform: capitalize;
    }
`;
