import { Input as AntdInput } from 'antd';
import React, {
    ChangeEvent,
    FocusEventHandler,
    forwardRef,
    KeyboardEventHandler,
    ReactNode,
    useEffect,
    useRef,
} from 'react';

import FormError from '../../forms/form-error/form-error.component';
import Error from '../error/error.component';
import Label from '../label/label.component';
import Styles from './input.styles';

export interface InputProps {
    id: string
    type?: 'text' | 'password'
    label?: string
    placeholder?: string
    size?: 'sm'
    suffix?: ReactNode
    prefix?: ReactNode
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void
    defaultValue?: string | number
    value?: string | number
    onClick?: any
    onFocus?: FocusEventHandler<HTMLInputElement>
    readOnly?: boolean
    className?: string
    disabled?: boolean
    name?: string
    onBlur?: FocusEventHandler
    labelComponent?: ReactNode
    max?: number
    error?: string
    shouldScrollTo?: Boolean
    onKeyPress?: KeyboardEventHandler<HTMLInputElement>
    onKeyDown?: KeyboardEventHandler<HTMLInputElement>
}

const Input = forwardRef<any, InputProps>(
    (
        {
            id,
            label,
            type = 'text',
            placeholder,
            size,
            suffix,
            prefix,
            defaultValue,
            value,
            onChange,
            onClick,
            onFocus,
            readOnly,
            className,
            disabled,
            name,
            onBlur,
            labelComponent,
            max,
            error,
            shouldScrollTo,
            onKeyPress,
            onKeyDown,
        },
        ref,
    ) => {
        const scrollRef = useRef<HTMLLabelElement>(null);
        const handleScrollTo = () => {
            scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
        };

        useEffect(() => {
            if (shouldScrollTo) {
                handleScrollTo();
            }
        }, [ shouldScrollTo ]);

        const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
            onChange?.(event);
        };

        return (
            <Styles
                $disabled = { disabled }
                $size = { size }
                className = { className }
                onClick = { onClick }>
                {label && (
                    <Label
                        className = 'input__label'
                        htmlFor = { id }
                        ref = { scrollRef }>
                        {labelComponent}

                        {label}
                    </Label>
                )}
                <AntdInput
                    className = 'input__input'
                    defaultValue = { defaultValue }
                    disabled = { disabled }
                    id = { id }
                    maxLength = { max }
                    placeholder = { placeholder }
                    prefix = { prefix }
                    readOnly = { readOnly }
                    ref = { ref }
                    suffix = { suffix }
                    type = { type }
                    value = { value }
                    onBlur = { onBlur }
                    onChange = { handleChange }
                    onFocus = { onFocus }
                    onKeyDown = { onKeyDown }
                    onKeyPress = { onKeyPress }
                />
                {name && <FormError name = { name }/>}
                {error && <Error name = { error } />}
            </Styles>
        );
    },
);

export default Input;
