import React from 'react';
import clsx from 'clsx';
import Styles from './form-input-labeled.styles';
import { Field, FieldProps } from 'formik';
import FormError from '../form-error/form-error.component';
import { ReactComponent as WarningIcon } from 'src/assets/media/icons/warning.svg';
import { classes } from '../../../../pipes/classes.pipe';

type Props = {
    name: string,
    label: string,
    type?: string,
    icon?: React.ReactNode,
    onUpdate?: (name: string, value: string) => void,
    className?: string,
    disabled?: boolean,
};
const FormInputLabeled = ({ name, label, type, onUpdate, icon, className, disabled }: Props) => {
    return (
        <Field name = { name }>
            {
                ({ field, form }: FieldProps) => (
                    <Styles className = { classes(
                        'text_input__wrapper',
                        form.errors[name] && form.touched[name] && 'text_input__error',
                        icon && 'text_input__icon',

                    ) }>
                        <label className = { 'text_input__cont' }>
                            <div className = { 'text_input__label' }>{label}</div>
                            <div className = { clsx('text_input__content', className) }>
                                <input
                                    className = { 'text_input__input' }
                                    disabled = { Boolean(disabled) }
                                    name = { name }
                                    type = { type || 'text' }
                                    value = { field.value }
                                    onBlur = { field.onBlur }
                                    onChange = { (event) => {
                                        form.setFieldValue(name, event.target.value);
                                        onUpdate && onUpdate(name, event.target.value);
                                    } }
                                />
                                {icon || null}
                                {
                                    form.errors[name] && form.touched[name] ? (
                                        <WarningIcon className = { 'text_input__error' }/>
                                    ) : null
                                }
                            </div>
                        </label>
                        <FormError name = { name }/>
                    </Styles>
                )}
        </Field>
    );
};

export default FormInputLabeled;
