import React from 'react';
import { useSelector } from 'react-redux';

import { IRootState } from 'src/types/state';
import { ReactComponent as RightArrowIcon } from 'src/assets/media/icons/right-arrow.svg';
import { ReactComponent as LeftArrowIcon } from 'src/assets/media/icons/left-arrow.svg';

import styles from './User.module.sass';

export interface Props {
    showFooter: boolean;
    toggleFooter: () => void;
}

const User: React.FC<Props> = (props) => {
    const userInfo = useSelector((state: IRootState) => state.auth.userInfo);

    return (
        <div
            className = { styles.container }>
            <div>
                <div className = { styles.avatarFallback }>
                    {`${userInfo?.first_name.charAt(0)}${userInfo?.last_name.charAt(0)}`}
                </div>
            </div>

            <div
                className = { styles.user }
                onClick = { props.toggleFooter }>
                <div
                    className = { styles.userName }>
                    {`${userInfo?.first_name} ${userInfo?.last_name}`}
                </div>
                <div>
                    { props.showFooter ? <LeftArrowIcon /> : <RightArrowIcon /> }
                </div>
            </div>

        </div>
    );
};

export default User;
