import { spawn } from 'redux-saga/effects';
import { sagaAuthWatcher } from './auth/saga';
import { sagaMealsWatcher } from './meals/saga';
import { sagaMealPlansWatcher } from './meal-plans';
import { sagaWizardWatcher } from './wizard';
import { sagaMacronutrientsWatcher } from './macronutrients';
import { sagaTargetsWatcher } from './targets';
import { sagaDeliveriesWatcher } from './deliveries';
import { sagaPaymentWatcher } from './payment';
import { sagaSubscriptionsWatcher } from './subscriptions';
import { sagaCouponsWatcher } from './settings/coupons';
import { sagaLocaleWatcher } from './locale';
import { sagaLoyaltyWatcher } from './settings/loyalty';
import { sagaOrdersWatcher } from './orders';

export default function* rootSaga() {
    yield spawn(sagaAuthWatcher);
    yield spawn(sagaMealsWatcher);
    yield spawn(sagaMealPlansWatcher);
    yield spawn(sagaWizardWatcher);
    yield spawn(sagaMacronutrientsWatcher);
    yield spawn(sagaTargetsWatcher);
    yield spawn(sagaDeliveriesWatcher);
    yield spawn(sagaPaymentWatcher);
    yield spawn(sagaSubscriptionsWatcher);
    yield spawn(sagaCouponsWatcher);
    yield spawn(sagaLocaleWatcher);
    yield spawn(sagaLoyaltyWatcher);
    yield spawn(sagaOrdersWatcher);
}
