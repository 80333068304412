import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { SidebarFooter } from './SidebarFooter';
import { ReactComponent as HomeIcon } from 'src/assets/media/icons/home.svg';
import { ReactComponent as PlanIcon } from 'src/assets/media/icons/plan.svg';
import { ReactComponent as MealIcon } from 'src/assets/media/icons/meal.svg';
import { ReactComponent as PlateIcon } from 'src/assets/media/icons/covered-plate.svg';
import { ReactComponent as TargetIcon } from 'src/assets/media/icons/target.svg';
import LogoImg from 'src/assets/media/trainx-logo.svg';

import { ROUTE_CONSTANTS } from 'src/init/constants';
import { RegionTooltip } from '../Header/components';

import styles from './Sidebar.module.sass';

type MenuItemType = {
    name: string;
    Icon: React.ComponentType,
    url: string;
    type?: string;
}

const menuItems: MenuItemType[] = [
    { name: 'Home', url: ROUTE_CONSTANTS.DASHBOARD, Icon: HomeIcon },
    { name: 'Orders', url: ROUTE_CONSTANTS.ORDERS, Icon: PlanIcon },
    { name: 'Meals', url: ROUTE_CONSTANTS.MEALS, Icon: MealIcon },
    // { name: 'Starter Plans', url: ROUTE_CONSTANTS.KICK_START_PLANS, Icon: PlateIcon },
    // { name: 'Targets', url: ROUTE_CONSTANTS.TARGETS, Icon: TargetIcon },
];

export interface Props {
    showFooter: boolean;
    toggleFooter: () => void;
}

export const DesktopSidebar: React.FC<Props> = (props) => {
    const { pathname } = useLocation();

    return (
        <aside className = { styles.container }>
            <div className = { styles.containerFixed }>
                <div className = { styles.logo }>
                    <Link to = { ROUTE_CONSTANTS.DASHBOARD }>
                        <img
                            alt = 'Eat right logo'
                            src = { LogoImg }
                            style = {{ width: '130px', height: '71px' }}
                        />
                    </Link>
                </div>

                <nav>
                    <RegionTooltip
                        isAuthenticated
                        className = 'mb-4'
                    />
                    <div className = { `${styles.divider} mb-8 mx-auto w-11/12` } />
                    <ul>
                        {
                            menuItems.map(({ name, url, Icon }) => (
                                <Link
                                    key = { url }
                                    to = { url }>
                                    <li
                                        className = {
                                            clsx({
                                                [styles.navItem]:            true,
                                                [styles['navItem--active']]: pathname.includes(url),
                                            }) }>

                                        <Icon/>

                                        <span>{ name }</span>
                                    </li>
                                </Link>
                            ))
                        }
                    </ul>

                </nav>
            </div>

            <div>
                <SidebarFooter
                    open = { props.showFooter }
                    onClose = { props.toggleFooter }
                />
            </div>
        </aside>
    );
};
