import { takeLatest, put, call } from 'redux-saga/effects';

import {
	ActionType,
	ACTION_GET_USER_COUPONS_ERROR,
	ACTION_GET_USER_COUPONS_REQUEST,
	ACTION_GET_USER_COUPONS_SUCCESS,
	ACTION_POST_USER_COUPONS_ERROR,
	ACTION_POST_USER_COUPONS_REQUEST,
	ACTION_POST_USER_COUPONS_SUCCESS,
	ACTION_REMOVE_USER_COUPONS_REQUEST,
	ACTION_REMOVE_USER_COUPONS_SUCCESS,
	ACTION_REMOVE_USER_COUPONS_ERROR,
} from 'src/store/action-types';
import api from '../../../managers/api';
import logger from 'src/managers/logger.manager';

export function* sagaCouponsWatcher() {
    yield takeLatest(ACTION_GET_USER_COUPONS_REQUEST, getUserCouponsWorker);
	yield takeLatest(ACTION_POST_USER_COUPONS_REQUEST, postUserCouponsWorker);
	yield takeLatest(ACTION_REMOVE_USER_COUPONS_REQUEST, removeUserCouponsWorker);
}

function* getUserCouponsWorker({ payload }: ActionType<{ page: number }>) {
    try {
      	const coupons: any[] = yield call(() => api.get(`/apply-coupons?per_page=5&page=${payload.page}`).then(res => res.data));
      	yield put({ type: ACTION_GET_USER_COUPONS_SUCCESS, payload: coupons });
    } catch (error: any) {
      	yield put({ type: ACTION_GET_USER_COUPONS_ERROR, payload: error?.response?.data?.message})
    }
}

function* postUserCouponsWorker({ payload }: ActionType<{ code: string }> ) {
    try {
      	const coupons: any[] = yield call(() => api.post('/apply-coupons', payload).then(res => res.data));
      	yield put({ type: ACTION_POST_USER_COUPONS_SUCCESS, payload: coupons });
		yield put({ type: ACTION_GET_USER_COUPONS_REQUEST, payload: { page: 1 }});
    } catch (error: any) {
      	yield put({ type: ACTION_POST_USER_COUPONS_ERROR, payload: error.response?.data?.errors?.code
			? error.response?.data?.errors?.code.reduce((acc: string, e: string) => (acc + ' ' + e.replace('code', 'Coupon')), '')
			: error.response?.data?.message
			? error.response?.data?.message
			: error.response?.message})
    }
}

function* removeUserCouponsWorker({ payload }: ActionType<{ couponId: number }> ) {
    try {
      	const coupons: any[] = yield call(() => api.delete(`/applied-coupons/${payload.couponId}`).then(res => res.data));
		  logger.info("DELETED", coupons)
      	yield put({ type: ACTION_REMOVE_USER_COUPONS_SUCCESS, payload: { couponId: payload.couponId } });
    } catch (error: any) {
      	yield put({ type: ACTION_REMOVE_USER_COUPONS_ERROR, payload: error.response?.data?.message
			? error.response?.data?.message
			: error.response?.message})
    }
}