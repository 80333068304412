// Core
import React, { FC } from 'react';
import clsx from 'clsx';

// Tools
import { EventBus } from 'src/managers/EventEmitter';
import { SHOW_REGION_PROMPT } from 'src/init/constants';

// Elements
import { ReactComponent as GlobeIcon } from 'src/assets/media/icons/navigation/globe.svg';

// Styles
import styles from 'src/layouts/MainLayout/Header/components/RegionTooltip/regionTooltip.module.sass';
import useGetUserRegion from 'src/hooks/api/deliveries/useGetUserRegion';

type RegionTooltipType = FC<{
    className?: string;
    isAuthenticated?: boolean;
}>;

export const RegionTooltip: RegionTooltipType = ({
    className,
    isAuthenticated = false,
}) => {
    const region = useGetUserRegion();

    return (
        <div
            className = { clsx(className, styles.container, isAuthenticated
                ? styles['container--authenticated'] : styles['container--unauthenticated']) }
            onClick = { () => void EventBus.emit(SHOW_REGION_PROMPT) }>
            <GlobeIcon className = { clsx('mr-2.5', { hidden: !isAuthenticated }) } />
            <div>
                <h4 className = { clsx(styles.caption, 'mb-1') }>Viewing meals for </h4>
                <p className = { styles.city }>
                    {region || 'No region'}
                </p>
            </div>
        </div>
    );
};
