import { takeLatest, put, call, select } from 'redux-saga/effects';
import { IMeal } from 'src/types/meal.type';
import api from '../../../managers/api';

import {
    ACTION_GET_MEALS_REQUEST,
    ACTION_GET_MEALS_LOAD,
    ACTION_GET_MEALS_SUCCESS,
    ACTION_GET_MEALS_ERROR,
    ACTION_GET_MEAL_DETAILS_REQUEST,
    ACTION_GET_MEAL_DETAILS_LOADING,
    ACTION_GET_MEAL_DETAILS_SUCCESS,
    ACTION_GET_MEAL_DETAILS_ERROR,
    ACTION_CLEAR_MEAL_DETAILS_REQUEST,
    ACTION_CLEAR_MEAL_DETAILS,
    ActionType, ACTION_GET_MEALS_TAGS_SUCCESS, ACTION_GET_MEALS_TAGS_REQUEST, ACTION_GET_MAIN_PROTIENS_OPTIONS_SUCCESS, ACTION_GET_MAIN_PROTIENS_OPTIONS_REQUEST,
} from '../../action-types';
import { IMealTag, IRootState, IMainProteinOptions } from '../../../types/state';
import { IGetMealsRequestAction } from './types';
import { cookieManager } from '../../../managers/cookieManager';

interface IResponse {
    [key: string]: any
}

export function* sagaMealsWatcher() {
    yield takeLatest(ACTION_GET_MEALS_REQUEST, getMealsWorker);
    yield takeLatest(ACTION_GET_MEAL_DETAILS_REQUEST, getMealDetailsWorker);
    yield takeLatest(ACTION_CLEAR_MEAL_DETAILS_REQUEST, clearMealDetailsWorker);
    yield takeLatest(ACTION_GET_MEALS_TAGS_REQUEST, getMealTagsWorker);
    yield takeLatest(ACTION_GET_MAIN_PROTIENS_OPTIONS_REQUEST, getMainProteinsWorker);
}

function* getMealsWorker({ payload }: IGetMealsRequestAction) {
    try {
        yield put({ type: ACTION_GET_MEALS_LOAD });

        const selectIsAuthenticated = (state: IRootState): boolean => state.auth.isAuthenticated;
        const isAuthenticated: boolean = yield select(selectIsAuthenticated);
        let tagsOnlyMeals: any[] = [];

        let mealsRegion: string = cookieManager.get('region');
        if (isAuthenticated) {
            mealsRegion =  yield select((state: IRootState) => state.auth.userInfo?.accounts[0]?.regionInfo?.region)
        }

        let urlString = `/meals?page=${payload?.page || '1'}`;


        if (payload?.filter?.mainProtein) {
            urlString = urlString.concat(`&main_protein=${payload.filter.mainProtein}`);
        }
        if (payload?.filter?.mealType) {
            urlString = urlString.concat(`&meal_type=${payload.filter.mealType}`);
        }
        if (payload?.filter?.availableDay) {
            urlString = urlString.concat(`&available_days=${payload.filter.availableDay}`);
        }
        if (payload?.filter?.search) {
            urlString = urlString.concat(`&search=${payload.filter.search}`);
        }
        if (payload?.tags && payload.tags.length > 0 && !payload?.hasFilterTagNameMatch) {
            urlString = urlString.concat(`&tags=${payload.tags.join(',')}`);
        }
        if (mealsRegion) {
            urlString = urlString.concat(`&region=${mealsRegion}`);
        }
        if (payload?.hasFilterTagNameMatch && payload?.tags && payload.tags.length > 0) {
            const tagsURLString = `/meals?page=${payload?.page || '1'}&tags=${payload.tags.join(',')}`;
            const tagResponse = (yield call(() => api.get(tagsURLString).then((res) => res.data))) as IResponse;
            tagsOnlyMeals = tagResponse.data;
        }

        const response = (yield call(() => api.get(urlString).then((res) => res.data))) as IResponse;
        const data: any[] = response.data;
        const meta = response.meta;
        yield put({ type: ACTION_GET_MEALS_SUCCESS, payload: { data: [...data, ...tagsOnlyMeals], meta }});
    } catch (error) {
        console.error(error);
        yield put({ type: ACTION_GET_MEALS_ERROR, payload: { error }});
    }
}

function* getMealDetailsWorker({ payload }: ActionType<{ mealId: number }>) {
    try {
        yield put({ type: ACTION_GET_MEAL_DETAILS_LOADING });

        const selectIsAuthenticated = (state: IRootState): boolean => state.auth.isAuthenticated;
        const isAuthenticated: boolean = yield select(selectIsAuthenticated);

        let mealRegion: string = cookieManager.get('region');
        if (!mealRegion) {
            mealRegion =  yield select((state: IRootState) => state.auth.userInfo?.accounts[0]?.regionInfo?.region)
        }

        let urlString = `/meals/${payload.mealId}?currency_id=1&include=selectableComponents.options.prices`;

        if (mealRegion) {
            urlString = urlString.concat(`&region=${mealRegion}`);
        }

        const meal = (yield call(() => api.get(urlString).then((res) => res.data.data))) as IMeal[];

        yield put({ type: ACTION_GET_MEAL_DETAILS_SUCCESS, payload: meal });
    } catch (error) {
        yield put({ type: ACTION_GET_MEAL_DETAILS_ERROR, payload: { error }});
    }
}

function* clearMealDetailsWorker() {
    yield put({ type: ACTION_CLEAR_MEAL_DETAILS });
}

function* getMealTagsWorker() {
    try {
        const mealTags: IMealTag[] = yield call(() => api.get('/tags').then((res) => res.data.data));

        yield put({ type: ACTION_GET_MEALS_TAGS_SUCCESS, payload: mealTags });
    } catch (error) {
        console.error('Meal tags fetch failed, getMealTagsWorker.ts:79', error);
    }
}

function* getMainProteinsWorker() {
    try {
        const mainProteins: IMainProteinOptions[] = yield call(() => api.get('/main-proteins').then((res) => res.data.data));

        yield put({ type: ACTION_GET_MAIN_PROTIENS_OPTIONS_SUCCESS, payload: mainProteins });
    } catch (error) {
        console.error('Meal tags fetch failed, getMealTagsWorker.ts:79', error);
    }
}
