import {
  ACTION_GET_INVOICES_LOAD,
  ACTION_GET_INVOICES_SUCCESS,
  ACTION_GET_INVOICES_ERROR,
  ACTION_CREATE_INVOICE_LOAD,
  ACTION_CREATE_INVOICE_SUCCESS,
  ACTION_CREATE_INVOICE_ERROR,
  ACTION_GET_PAYMENTS_LOAD,
  ACTION_GET_PAYMENTS_SUCCESS,
  ACTION_GET_PAYMENTS_ERROR,
  ActionType,
} from '../action-types';

import { IPaymentState } from 'src/types/state';

const initialState: IPaymentState = {
    paymentList: {
      loading: false,
      error: null,
      data: []
    },
    invoiceList: {
      loading: false,
      error: null,
      data: []
    },
    loadingCreateInvoice: false,
    successCreateInvoice: false,
    errorCreateInvoice: null
};

export const payment = (state = initialState, { type, payload }: ActionType<any>): IPaymentState => {
  switch (type) {
      case ACTION_GET_INVOICES_LOAD:
          return {
            ...state,
            invoiceList: {
              ...state.invoiceList,
              loading: true
            }
          };
      case ACTION_GET_INVOICES_SUCCESS:
          return {
            ...state,
            invoiceList: {
              ...state.invoiceList,
              loading: false,
              data: payload
            }
          };
      case ACTION_GET_INVOICES_ERROR:
          return {
            ...state,
            invoiceList: {
              ...state.invoiceList,
              loading: false,
              error: payload
            }
          };
      case ACTION_CREATE_INVOICE_LOAD:
          return {
            ...state,
            successCreateInvoice: false,
            loadingCreateInvoice: true,
          };
      case ACTION_CREATE_INVOICE_SUCCESS:
          return {
            ...state,
            loadingCreateInvoice: false,
            successCreateInvoice: true,
            errorCreateInvoice: null,
          };
      case ACTION_CREATE_INVOICE_ERROR:
          return {
            ...state,
            loadingCreateInvoice: false,
            errorCreateInvoice: payload,
          };
      case ACTION_GET_PAYMENTS_LOAD:
          return {
            ...state,
            paymentList: {
              ...state.paymentList,
              loading: true
            }
          };
      case ACTION_GET_PAYMENTS_SUCCESS:
          return {
            ...state,
            paymentList: {
              ...state.paymentList,
              loading: false,
              data: payload
            }
          };
      case ACTION_GET_PAYMENTS_ERROR:
          return {
            ...state,
            paymentList: {
              ...state.paymentList,
              loading: false,
              error: payload
            }
          };
      default:
          return state;
  }
};
