import styled from 'styled-components';

export default styled.nav`
  display: block;
  .more {
    &__menu {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    
    &__item {
      a {
        padding: 15px 0;
        font-weight: 500;
        font-size: 14px;
        ${(p) => p.theme.extend.flexCenter}
        color: ${(p) => p.theme.vars.colors.primaryDark};
      }
      svg {
        display: block;
        margin-right: 20px;
        height: 22px;
        width: auto;
      }
      &:last-child {
        padding-bottom: 30px;
      }
    }
    &__item-wrapper {
      margin: 0 20px;
      width: auto;
      border-bottom: 1px solid #EDEDED;
    }
    &__label {
      margin-right: auto;
      color: #9E9E9E;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
    }
  }
`;
