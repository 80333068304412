import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import MealCard from 'src/components/MealCard/MealCard';
import PersonalizeAndAdd from 'src/pages/meals/components/PersonalizeAndAdd/PersonalizeAndAdd';
import { DesktopLayout, MobileLayout } from 'src/layouts';
import MobileSlider from 'src/components/MobileSlider';

import { ROUTE_CONSTANTS } from 'src/init/constants';

import { IMeal } from 'src/types/meal.type';

import styles from './HighlightedMeals.module.sass';

interface Props {
    meals: IMeal[];
}

const HighlightedMeals: React.FC<Props> = ({ meals }) => {
    const { push } = useHistory();

    const [ showPersonalizeAndAdd, setShowPersonalizeAndAdd ] = useState(false);
    const [ selectedMealId, setSelectedMealId ] = useState<number | null>(null);

    const openPersonalizeAndAddHandler = (mealId: number) => {
        setSelectedMealId(mealId);
        setShowPersonalizeAndAdd(true);
    };

    const closePersonalizeAndAddHandler = () => {
        setSelectedMealId(null);
        setShowPersonalizeAndAdd(false);
    };

    const handleMealCardClick = (meal: IMeal) => {
        push(`${ROUTE_CONSTANTS.MEALS}/${meal.id}`);
    };

    const mealsList =  meals.filter(Boolean).map((meal) => (
        <MealCard
            calories = { Math.round(meal?.nutrients.calories) }
            className = { styles.meals }
            customButtonLabel = 'I want to eat this, too'
            imageUrl = { meal?.photo_1 }
            key = { meal?.id }
            name = { meal?.name }
            openPersonalizeAndAdd = { () => handleMealCardClick(meal) }
            type = { meal?.meal_type }
            onClick = { () => handleMealCardClick(meal) }
        />

    ));

    return (
        <section className = { clsx('common-section', styles.section) }>
            <div className = 'common-container'>
                <h3 className = { styles.title }>Here’s What People Are Ordering</h3>
                <DesktopLayout>
                    <div className = { styles.mealCards }>
                        {mealsList}
                    </div>
                </DesktopLayout>
                <MobileLayout>
                    <MobileSlider>
                        {mealsList}
                    </MobileSlider>
                </MobileLayout>
                <button
                    className = { styles.toOrderingButton }
                    onClick = { () => push(ROUTE_CONSTANTS.MEALS) }>See all meals people are ordering
                </button>
            </div>
            <PersonalizeAndAdd
                mealId = { selectedMealId }
                open = { showPersonalizeAndAdd }
                selectedMealPlanId = { 0 }
                onModalClose = { closePersonalizeAndAddHandler }
            />
        </section>
    );
};

export default HighlightedMeals;
