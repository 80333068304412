import { ROUTE_CONSTANTS } from '../constants';

export const headerConfig = Object.freeze([
    { name: 'Meals', path: ROUTE_CONSTANTS.MEALS },
]);

export const headerConfigOld = [
    {
        localisationKey: 'home',
        url:             '/',
    },
    {
        localisationKey: 'meals',
        url:             '/meals',
    },
    {
        localisationKey: 'helpMeChoose',
        url:             '/help-me-choose',
    },
    {
        localisationKey: 'about',
        url:             '/about',
    },
    {
        localisationKey: 'faq',
        url:             '/faq',
    },
    {
        localisationKey: 'contacts',
        url:             '/contacts',
    },
];
