import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import clsx from 'clsx';
import Styles from './form-textarea.styles';
import { Field, FieldProps, FormikProps } from 'formik';
import FormError from '../form-error/form-error.component';

type Props = {name:string, label: string, onUpdate?:(name:string, value:string)=>void, className?: string};
const FormTextarea = ({ name, label, onUpdate, className }: Props) => {
    const maxHeight = 300;
    const [ height, setHeight ] = useState(0);
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const handleUpdate = (event: ChangeEvent<HTMLTextAreaElement>, form: FormikProps<any>) => {
        form.setFieldValue(name, (event?.target).value);
        onUpdate && onUpdate(name, event.target.value);
        event.target.style.height = '';
        const height = event.target.scrollHeight + 2;
        event.target.style.height = Math.min(height, maxHeight) + 'px';
        setHeight(Math.min(height, maxHeight));
    };
    useEffect(() => {
        if (inputRef?.current) {
            setHeight(Math.min(inputRef.current.scrollHeight + 2, maxHeight));
        }
    }, [ inputRef ]);

    return (
        <Field name = { name }>
            {
                ({ field, form }: FieldProps) => (
                    <Styles className = { 'textarea__wrapper' }>
                        <label className = { clsx('text_input__cont', className) }>
                            <div className = { 'text_input__label' }>{label}</div>
                            <textarea
                                className = { 'text_input__input' }
                                name = { name }
                                ref = { inputRef }
                                style = { height ? { height: `${height}px` } : {} }
                                value = { field.value }
                                onBlur = { field.onBlur }
                                onChange = { (event) => handleUpdate(event, form) }
                            />
                        </label>
                        <FormError name = { name }/>
                    </Styles>
                )}
        </Field>
    );
};

export default FormTextarea;
