// Core
import storage from 'redux-persist/lib/storage'

// Reducers
import { combineReducers } from 'redux';
import { auth } from './auth';
import { meals } from './meals';
import { mealsPlansReducer } from './meal-plans';
import { wizard } from './wizard';
import { macronutrients } from './macronutrients';
import { targets } from './targets';
import { deliveries } from './deliveries';
import { payment } from './payment';
import { subscriptions } from './subscriptions';
import { settingsCoupons } from './settingsCoupons';
import { locale } from './locale';
import { settingsLoyalty } from './settingsLoyalty';
import { orders } from './orders';

export const appReducer = combineReducers({
    auth: auth,
    meals: meals,
    mealPlans: mealsPlansReducer,
    wizard: wizard,
    macronutrients: macronutrients,
    targets: targets,
    deliveries: deliveries,
    payment: payment,
    subscriptions: subscriptions,
    settingsCoupons: settingsCoupons,
    locale: locale, 
    settingsLoyalty: settingsLoyalty,
    orders: orders,
});

const rootReducer: typeof appReducer = (state, action) => {
    if (action.type === 'ACTION_LOGOUT_SUCCESS') {
        storage.removeItem('persist:root');
        // localStorage.removeItem('persist:root');

        return appReducer(undefined, action);
    }

    return appReducer(state, action);
}

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>
