import { ICoupons } from "src/types/state/settingsCoupons";
import {
    ActionType,
    ACTION_GET_USER_COUPONS_ERROR,
    ACTION_GET_USER_COUPONS_REQUEST,
    ACTION_GET_USER_COUPONS_SUCCESS,
    ACTION_POST_USER_COUPONS_ERROR,
    ACTION_POST_USER_COUPONS_REQUEST,
    ACTION_POST_USER_COUPONS_SUCCESS,
    ACTION_SET_USER_COUPONS_APPLY_SUCCESS,
    ACTION_REMOVE_USER_COUPONS_SUCCESS,
    ACTION_REMOVE_USER_COUPONS_ERROR,
    ACTION_SET_USER_COUPONS_REMOVE_SUCCESS,
} from "../action-types";


const initialState: ICoupons = {
    coupons: {
      loading: false,
      data: [],
      error: null,
      currentPage: 1,
      perPage: 0,
      total: 0,
    },
    applyCoupon: {
        success: false,
        loading: false,
        error: null
    }
};
  
export const settingsCoupons = (state = initialState, { type, payload }: ActionType<any>): ICoupons => {
    switch (type) {
        case ACTION_GET_USER_COUPONS_REQUEST:
            return {
                ...state,
                coupons: {
                    ...state.coupons,
                    loading: true,
                }

            };
        case ACTION_GET_USER_COUPONS_SUCCESS:
            return {
                ...state,
                coupons: {
                    ...state.coupons,
                    loading: false,
                    data: payload.data,
                    currentPage: payload.meta.current_page,
                    perPage: payload.meta.per_page,
                    total: payload.meta.total,
                    error: null
                },
                applyCoupon: {
                    ...state.applyCoupon,
                    error: null
                },
            };
        case ACTION_GET_USER_COUPONS_ERROR:
            return {
                ...state,
                coupons: {
                    ...state.coupons,
                    loading: false,
                    error: payload,
                }
            };
        case ACTION_POST_USER_COUPONS_REQUEST:
            return {
                ...state,
                applyCoupon: {
                    ...state.applyCoupon,
                    loading: true,
                }

            };
        case ACTION_POST_USER_COUPONS_SUCCESS:
            return {
                ...state,
                applyCoupon: {
                    ...state.applyCoupon,
                    success: true,
                    loading: false,
                    error: null
                },
            };
        case ACTION_POST_USER_COUPONS_ERROR:
            return {
                ...state,
                applyCoupon: {
                    ...state.applyCoupon,
                    loading: false,
                    error: payload,
                }
            };
        case ACTION_SET_USER_COUPONS_APPLY_SUCCESS:
            return {
                ...state,
                applyCoupon: {
                    ...state.applyCoupon,
                    success: false
                }
            };
        case ACTION_REMOVE_USER_COUPONS_SUCCESS:
            let newCouponData = [...state.coupons.data].filter(coup => coup.id !== payload.couponId)
            return {
                ...state,
                coupons: {
                    ...state.coupons,
                    data: newCouponData,
                    error: null
                }
            };
        case ACTION_REMOVE_USER_COUPONS_ERROR:
            return {
                ...state,
                coupons: {
                    ...state.coupons,
                    error: payload
                }
            };
        case ACTION_SET_USER_COUPONS_REMOVE_SUCCESS:
            return {
                ...state,
                coupons: {
                    ...state.coupons,
                    error: null
                }
            };
        default:
            return state;
    }
};
  