import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import clsx from 'clsx';

import { IRootState } from 'src/types/state';
import { ParamsType as SettingsParamsType } from 'src/pages/settings/settings.types';

import { ACTION_CREATE_MEAL_PLAN_REQUEST } from 'src/store/action-types';
import { ROUTE_CONSTANTS, SHOW_REGION_PROMPT } from 'src/init/constants';

import { content as settingsContent } from 'src/pages/settings/settings.context';

import LogoImg from 'src/assets/media/trainx-white-logo.svg';

import { ReactComponent as GlobeIcon } from 'src/assets/media/icons/navigation/globe.svg';
import { ReactComponent as LeftArrowIcon } from 'src/assets/media/icons/left-arrow.svg';

import { useClickOutside } from 'src/hooks/useClickOutside';
import { EventBus } from 'src/managers';
import { RegionTooltip } from '../../MainLayout/Header/components';

import styles from './mobileHeader.module.sass';

const HeaderTop = () => (
    <div className = { styles.headerTitleBox }>
        <Link to = { ROUTE_CONSTANTS.DASHBOARD }>
            <img
                alt = 'Logo'
                className = { styles.logoIcon }
                src = { LogoImg }
            />
        </Link>
    </div>
);

const CreateNewPlanHandlerBtn = () => {
    const history = useHistory();
    const dispatch: Dispatch<any> = useDispatch();
    const mealPlans = useSelector((state: IRootState) => state.mealPlans.mealPlanList.data);

    const createNewPlanHandler = () => {
        dispatch({
            type:    ACTION_CREATE_MEAL_PLAN_REQUEST,
            payload: {
                name:      `Meal plan ${mealPlans?.length ? mealPlans.length + 1 : ''}`,
                onSuccess: (mealPlanId: number) => history.push(`/meals?meal_plan_id=${mealPlanId}`),
            },
        });
    };

    return (
        <button
            className = { styles.buttonPrimary }
            onClick = { () => createNewPlanHandler() }>
            Create Plan
        </button>
    );
};

const EditPlanHandlerBtn = () => {
    return (
        <Link to = { `${window.location.pathname}/edit` }>
            <button
                className = { styles.buttonPrimary }
                onClick = { () => null }>
                Edit Plan
            </button>
        </Link>
    );
};

const ReturnBtn = () => {
    const edit = location.pathname.includes('edit');
    const mealPlan = useSelector((state: IRootState) => state.mealPlans.mealPlan.data);

    return (
        <div className = { styles.goBackLink }>
            <Link to = { edit ? `/plans/${mealPlan?.id}` : '/meals' }><LeftArrowIcon /> { edit ? 'Return to Overview Plan' : 'Return to Meals' }</Link>
        </div>
    );
};

const SettingsReturnBtn = () => {
    const onSettings = location.pathname === ROUTE_CONSTANTS.SETTINGS.split('/:')[0];

    return (
        <div className = { styles.goBackLink }>
            <Link to = { onSettings ? ROUTE_CONSTANTS.DASHBOARD : ROUTE_CONSTANTS.SETTINGS.split('/:')[0] }>
                <LeftArrowIcon />
                <span className = { styles.goBackText }>{ onSettings ? 'Return to Dashboard' : 'Return to Account Settings' }</span>
            </Link>
        </div>
    );
};

const WizardHeader = () => (
    <header className = { clsx(styles.header, styles.wizardPageHeader) }>
        <HeaderTop />

        <div className = { styles.headerContentBox }>
            <h2 className = { styles.pageTitle }>
                Pick the Right Plan
            </h2>
        </div>
    </header>
);

const MealsHeader = () => (
    <header className = { clsx(styles.header, styles.mealsPageHeader) }>
        <HeaderTop />

        <div className = { styles.mealsPageHeaderContentBox }>
            <h2 className = { styles.pageTitle }>
                Explore our delicious meals
            </h2>
        </div>
    </header>
);

const MealHeader = () => {
    const searchParams = new URLSearchParams(location.search);

    const getBackLink = searchParams.get('return') || ROUTE_CONSTANTS.MEALS;
    const title = searchParams.get('return')?.includes(ROUTE_CONSTANTS.WIZARD) ? 'Wizard' : 'Meals';

    return (<header className = { clsx(styles.header, styles.mealPageHeader) }>
        <div className = { styles.goBackLink }>
            <Link to = { getBackLink }><LeftArrowIcon /> Go Back to {title}</Link>
        </div>
    </header>
)
};

const PlansHeader = () => (
    <header className = { clsx(styles.header, styles.plansPageHeader) }>
        <HeaderTop />

        <div className = { styles.plansPageHeaderContentBox }>
            <h2 className = { styles.pageTitle }>
                Your Meal Plans
            </h2>
            <CreateNewPlanHandlerBtn />
        </div>
    </header>
);

const OrderDetailHeader = () => (
    <header className = { clsx(styles.header, styles.planPageHeader) }>
        <div className = { styles.goBackLink }>
            <Link to = { ROUTE_CONSTANTS.ORDERS }><LeftArrowIcon />Return to Orders</Link>
        </div>

        <div className = { styles.planPageHeaderContentBox }>
            <h2 className = { styles.pageTitle }>
                Overview Order
            </h2>
        </div>
    </header>
);

const PlanHeader = () => (
    <header className = { clsx(styles.header, styles.planPageHeader) }>
        <ReturnBtn />

        <div className = { styles.planPageHeaderContentBox }>
            <h2 className = { styles.pageTitle }>
                Overview Plan
            </h2>
            <EditPlanHandlerBtn />
        </div>
    </header>
);

const KickStarterPlansHeader = () => (
    <header className = { clsx(styles.header, styles.planPageHeader) }>
        <HeaderTop />

        <div className = { styles.planPageHeaderContentBox }>
            <h2 className = { styles.pageTitle }>
                Kick Starter Plans
            </h2>
        </div>
    </header>
);

const EditPlanHeader = () => (
    <header className = { clsx(styles.header, styles.planPageHeader) }>
        <ReturnBtn />

        <h2 className = { styles.pageTitle }>
            Editing Meal Plans
        </h2>
    </header>
);

const EditDeliveryHeader = () => {
    const searchParams = new URLSearchParams(location.search);

    const weekDay = searchParams.get('day') || "";

    return (
        <header className = { clsx(styles.header, styles.planPageHeader) }>
            <ReturnBtn />

            <h2 className = { styles.pageTitle }>
                Editing Delivery {weekDay}
            </h2>
        </header>
    )
};

const TargetsHeader = () => (
    <header className = { clsx(styles.header, styles.targetsPageHeader) }>
        <HeaderTop />
    </header>
);

const DashboardHeader = () => {
    const [ isRegionTooltipOpened, setIsRegionTooltipOpened ] = useState(false);
    const globeButtonRef = useRef<null | HTMLDivElement>(null);

    useClickOutside(globeButtonRef, () => {
        setIsRegionTooltipOpened(false);
    });

    return (
        <header className = { clsx(styles.header, styles.dashboardPageHeader) }>
            <HeaderTop />
            <div
                className = 'relative block md:hidden'
                ref = { globeButtonRef }>
                <button
                    className = { clsx('flex align-center md:self-start ml-3 md:ml-5 opacity-50') }
                    onClick = { () => EventBus.emit(SHOW_REGION_PROMPT) }
                    onMouseEnter = { () => setIsRegionTooltipOpened(true) }>
                    <GlobeIcon />
                </button>
                <RegionTooltip className = { clsx({ block: isRegionTooltipOpened }) } />
            </div>
        </header>
    );
};

const SettingsHeader = () => {
    const params = useParams<SettingsParamsType>();
    let settingsTitle = 'Account Settings';

    if (params.tab) {
        const c = settingsContent.find((c) => c.key === params.tab);
        settingsTitle = c ? c.label : '';
    }

    return (
        <header className = { clsx(styles.header, styles.planPageHeader) }>
            <div className = { styles.settingsPageHeaderTitleBox }>
                <SettingsReturnBtn />
            </div>

            <h2 className = { styles.pageTitle }>
                { settingsTitle }
            </h2>
        </header>
    );
};

const MobileHeader: React.FC = () => {
    return (
        <Switch>
            <Route
                component = { DashboardHeader }
                path = { ROUTE_CONSTANTS.DASHBOARD }
            />
            <Route
                component = { WizardHeader }
                path = { ROUTE_CONSTANTS.WIZARD }
            />
            <Route
                component = { MealHeader }
                path = { ROUTE_CONSTANTS.SINGLE_MEAL }
            />
            <Route
                component = { MealsHeader }
                path = { ROUTE_CONSTANTS.MEALS }
            />
            <Route
                exact
                component = { EditPlanHeader }
                path = { ROUTE_CONSTANTS.SINGLE_PLAN_EDIT }
            />
            <Route
                exact
                component = { EditDeliveryHeader }
                path = { ROUTE_CONSTANTS.SINGLE_DELIVERY_EDIT }
            />
            <Route
                exact
                component = { PlanHeader }
                path = { ROUTE_CONSTANTS.SINGLE_PLAN }
            />
            <Route
                exact
                component = { PlansHeader }
                path = { ROUTE_CONSTANTS.PLANS }
            />
            {/* <Route
                exact
                component = { TargetsHeader }
                path = { ROUTE_CONSTANTS.TARGETS }
            /> */}
            <Route
                exact
                component = { SettingsHeader }
                path = { ROUTE_CONSTANTS.SETTINGS }
            />
            <Route
                exact
                component = { OrderDetailHeader }
                path = { ROUTE_CONSTANTS.SINGLE_ORDER }
            />
            {/* <Route
                exact
                component = { KickStarterPlansHeader }
                path = { ROUTE_CONSTANTS.KICK_START_PLANS }
            /> */}
        </Switch>
    );
};

export default MobileHeader;
