// Core
import React, { FC } from 'react';
import clsx from 'clsx';

// Components
import { WhyEatRightTable } from './components';

// Styles
import styles from './whyEatRight.module.sass';

type WhyEatRightSectionType = FC<{ className?: string }>;

export const WhyEatRightSection: WhyEatRightSectionType = ({
    className,
}) => {
    return (
        <section className = { clsx(className, 'common-section', styles.section) }>
            <div className = 'common-container'>
                <h3 className = { clsx('section-heading', 'mb-16') }>
                    Why Should You EatRight
                </h3>
            </div>
            <WhyEatRightTable />
        </section>
    );
};
