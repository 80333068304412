import {
  ACTION_GET_MEAL_PLANS_LOAD,
  ACTION_GET_MEAL_PLANS_SUCCESS,
  ACTION_GET_MEAL_PLANS_ERROR,
  ACTION_GET_MEAL_PLAN_LOAD,
  ACTION_GET_MEAL_PLAN_SUCCESS,
  ACTION_GET_MEAL_PLAN_ERROR,
  ACTION_GET_ACTIVE_MEAL_PLAN_LOAD,
  ACTION_GET_ACTIVE_MEAL_PLAN_SUCCESS,
  ACTION_GET_ACTIVE_MEAL_PLAN_ERROR,
  ACTION_UPDATE_MEAL_PLAN_LOAD,
  ACTION_UPDATE_MEAL_PLAN_SUCCESS,
  ACTION_UPDATE_MEAL_PLAN_ERROR,
  ACTION_CREATE_MEAL_PLAN_LOAD,
  ACTION_CREATE_MEAL_PLAN_SUCCESS,
  ACTION_CREATE_MEAL_PLAN_ERROR,
  ActionType,
} from '../action-types';

import { IMealPlansState } from 'src/types/state';
import {IMealPlan} from "../../types/meal-plan";

const initialState: IMealPlansState = {
  mealPlan: {
    loading: false,
    data: null,
    error: null,
  },
  activeMealPlan: {
    loading: false,
    data: null,
    error: null,
  },
  mealPlanList: {
    loading: false,
    data: null,
    error: null,
  },
  loadingUpdateMealPlan: false,
  successUpdateMealPlan: false,
  errorUpdateMealPlan: null,
  loadingCreateMealPlan: false,
  successCreateMealPlan: false,
  errorCreateMealPlan: null,
};

const updateMealPlansList = (state: IMealPlansState, payload: any) => {
  const existingMealPlans = state.mealPlanList?.data;

  if (!payload.isAuthenticated && existingMealPlans && existingMealPlans?.length > 0) {
    return existingMealPlans.map((existingPlan) => {
      const newPlanFound = payload.mealPlans.find((newPlan: IMealPlan) => newPlan.id === existingPlan.id);

      return newPlanFound ? newPlanFound : existingPlan;
    })
  }

  return payload.mealPlans;
}

export const mealsPlansReducer = (state = initialState, { type, payload }: ActionType<any>): IMealPlansState => {
  switch (type) {
      case ACTION_GET_MEAL_PLANS_LOAD:
          return {
              ...state,
              mealPlanList: {
                ...state.mealPlanList,
                loading: true,
              }
          };
      case ACTION_GET_MEAL_PLANS_SUCCESS:
          return {
            ...state,
            mealPlanList: {
              ...state.mealPlanList,
              loading: false,
              data: updateMealPlansList(state, payload),
            }
          };
      case ACTION_GET_MEAL_PLANS_ERROR:
          return {
            ...state,
            mealPlanList: {
              ...state.mealPlanList,
              loading: false,
              error: payload,
            }
          };
        case ACTION_GET_MEAL_PLAN_LOAD:
          return {
              ...state,
              successUpdateMealPlan: false,
              mealPlan: {
                ...state.activeMealPlan,
                loading: true,
              }
          };
        case ACTION_GET_MEAL_PLAN_SUCCESS:
            return {
              ...state,
              mealPlan: {
                ...state.activeMealPlan,
                loading: false,
                data: payload,
              }
            };
        case ACTION_GET_MEAL_PLAN_ERROR:
            return {
              ...state,
              mealPlan: {
                ...state.activeMealPlan,
                loading: false,
                error: payload,
              }
            };
      case ACTION_GET_ACTIVE_MEAL_PLAN_LOAD:
          return {
              ...state,
              activeMealPlan: {
                ...state.activeMealPlan,
                loading: true,
              }

          };
      case ACTION_GET_ACTIVE_MEAL_PLAN_SUCCESS:
          return {
            ...state,
            activeMealPlan: {
              ...state.activeMealPlan,
              loading: false,
              data: payload,
            }
          };
      case ACTION_GET_ACTIVE_MEAL_PLAN_ERROR:
          return {
            ...state,
            activeMealPlan: {
              ...state.activeMealPlan,
              loading: false,
              error: payload,
            }
          };
        case ACTION_UPDATE_MEAL_PLAN_LOAD:
            return {
                ...state,
                loadingUpdateMealPlan: true,
            };
        case ACTION_UPDATE_MEAL_PLAN_SUCCESS:
            return {
              ...state,
             successUpdateMealPlan: true,
             loadingUpdateMealPlan: false
            };
        case ACTION_UPDATE_MEAL_PLAN_ERROR:
            return {
              ...state,
              loadingUpdateMealPlan: false,
              errorUpdateMealPlan: payload
            };
        case ACTION_CREATE_MEAL_PLAN_LOAD:
            return {
                ...state,
                loadingCreateMealPlan: true,
            };
        case ACTION_CREATE_MEAL_PLAN_SUCCESS:
            return {
              ...state,
              mealPlanList: {
                ...state.mealPlanList,
                loading: false,
                data: Array.isArray(state.mealPlanList.data) ? state.mealPlanList.data.concat(payload) : [payload],
              },
              successCreateMealPlan: true,
              loadingCreateMealPlan: false
            };
        case ACTION_CREATE_MEAL_PLAN_ERROR:
            return {
              ...state,
              loadingCreateMealPlan: false,
              errorCreateMealPlan: payload
            };
      default:
          return state;
  }
};
