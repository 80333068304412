// Core
import React, { FC } from 'react';
import clsx from 'clsx';

// Components
import { HomeCarousel } from '../HomeCarousel';

// Styles
import styles from './TestimonialRotating.module.sass';

type TestimonialRotatingType = FC<{
    content1: string;
    content1name: string;
    content1role: string;
    image1: string;
    content2: string;
    content2name: string;
    content2role: string;
    image2: string;
    content3: string;
    content3name: string;
    content3role: string;
    image3: string;
    className?: string 
}>;

export const TestimonialRotating: TestimonialRotatingType = ({
    content1,
    content1name,
    content1role,
    image1,
    content2,
    content2name,
    content2role,
    image2,
    content3,
    content3name,
    content3role,
    image3,
    className,
}) => {
    const slide = [
        <div className = { styles.slide }>
            <p className = { styles.slide__title }>
               { content1 }
            </p>
            <div className = { styles.slide__avatar }>
                <img
                    alt = 'User avatar'
                    src = { image1 || '/images/home/user-3.png' }
                />
            </div>
            <strong className = { styles.slide__name }>
                { content1name }
            </strong>
            <p className = { styles.slide__company }>
                { content1role }
            </p>
        </div>,
        <div className = { styles.slide }>
            <p className = { styles.slide__title }>
            { content2 }
            </p>
            <div className = { styles.slide__avatar }>
                <img
                    alt = 'User avatar'
                    src = { image2 || '/images/home/user-3.png' }
                />
            </div>
            <strong className = { styles.slide__name }>
                { content2name }
            </strong>
            <p className = { styles.slide__company }>
                { content2role }
            </p>
        </div>,
        <div className = { styles.slide }>
            <p className = { styles.slide__title }>
            { content3 }
            </p>
            <div className = { styles.slide__avatar }>
                <img
                    alt = 'User avatar'
                    src = { image3 || '/images/home/user-3.png' }
                />
            </div>
            <strong className = { styles.slide__name }>
                { content3name }
            </strong>
            <p className = { styles.slide__company }>
                { content3role }
            </p>
        </div>
    ];

    return (
        <section className = { clsx(className, 'common-section', styles.section) }>
            <div className = 'common-container'>
                <div className = { styles.title }>
                    What Our Clients Say About Us
                </div>
                <HomeCarousel>
                    {
                        slide.map((slide, index) => (
                            <React.Fragment
                                key = { index }>
                                {slide}
                            </React.Fragment>
                        ))
                    }
                </HomeCarousel>
            </div>
        </section>
    );
};
