import { lazy } from 'react';
import { RouteType } from '../types/route';
import { ROUTE_CONSTANTS } from '../init/constants';

const authRoutes: RouteType[] = [
    {
        title:     'Dashboard',
        url:       ROUTE_CONSTANTS.DASHBOARD,
        Component: lazy(() => import('../pages/dashboard')),
        props:     { exact: true },
        header:    {},
    },
    {
        title:     'Orders',
        url:       ROUTE_CONSTANTS.ORDERS,
        Component: lazy(() => import('../pages/orders')),
        props:     { exact: true },
        header:    {},
    },
    {
        title:     'Settings',
        url:       '/settings/:tab?',
        Component: lazy(() => import('../pages/settings')),
        props:     { exact: true },
        header:    {},
    },
    // {
    //     title:     'Targets',
    //     url:       ROUTE_CONSTANTS.TARGETS,
    //     Component: lazy(() => import('../pages/targets')),
    //     props:     { exact: true },
    //     header:    {},
    // },
];


export default authRoutes;
