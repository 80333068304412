import React, { useContext, ComponentType } from 'react';
import { getStr, I18nContext } from './i18n.context';
import { I18nTypeNotEmpty } from './i18n.type';
import { config } from './i18n.config';

export const useTranslation = () => {
    const { strings, lang, setLang } = useContext(I18nContext) as I18nTypeNotEmpty;
    const t = (key:string, data:any = {}) => {
        let file = 'common';
        let keyCopy = key;

        if (key.includes(':')) {
            file = key.split(':')[0];
            // eslint-disable-next-line
            keyCopy = key.split(':')[ 1 ];
        }

        return getStr(strings[file], keyCopy, data)
            || getStr(strings[config.defaultLanguage], keyCopy, data) || keyCopy;
    };

    return { t, lang, setLang };
};
export const withTranslations = (Component: ComponentType) => (props: any) => {
    const { t, lang, setLang } = useTranslation();

    return (
        <Component
            { ...props }
            lang = { lang }
            setLang = { setLang }
            t = { t }
        />
    );
};
