import {
    ACTION_LOGIN_SUCCESS,
    ACTION_LOGIN_ERROR,
    ACTION_LOGOUT_SUCCESS,
    ACTION_LOGOUT_ERROR,
    ACTION_UPDATE_USER_PROFILE_SUCCESS,
    ACTION_GET_USER_ACCOUNT_SUCCESS,
    ACTION_UPDATE_USER_ACCOUNT_REGION_SUCCESS,
    ActionType,  
} from '../action-types';
import { IAuthState } from 'src/types/state';
import {IAccount} from "../../types/account";

const initialState: IAuthState = {
    isAuthenticated: false,
    userInfo: null,
    error: null,
};

export const auth = (state = initialState, { type, payload }: ActionType<any>): IAuthState => {
    switch (type) {
        case ACTION_LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                userInfo: payload,
            };
        case ACTION_LOGIN_ERROR:
            return {
                ...state,
                error: payload,
            };
        case ACTION_LOGOUT_SUCCESS:
            return {
                ...state,
                isAuthenticated: false,
            };
        case ACTION_LOGOUT_ERROR:
            return {
                ...state,
                error: payload,
            };
        case ACTION_UPDATE_USER_ACCOUNT_REGION_SUCCESS:
        case ACTION_GET_USER_ACCOUNT_SUCCESS:
            return {
                ...state,
                // @ts-ignore
                userInfo: {
                    ...state.userInfo,
                    accounts: state.userInfo?.accounts?.map((account) => {
                            return account.id === payload.id ? {
                                ...account,
                                regionInfo: payload
                            } as IAccount : account;
                        }) ?? []
                }
            };
        case ACTION_UPDATE_USER_PROFILE_SUCCESS:
            return {
                ...state,
                userInfo: payload
            };
        default:
            return state;
    }
};
