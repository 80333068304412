import { takeLatest, put, call, select } from 'redux-saga/effects';

import { getItemFromLocalStorage } from 'src/utils';
import { IMealPlan } from 'src/types/meal-plan';
import api from '../../managers/api';
import { cookieManager } from '../../managers/cookieManager';
import {
  ACTION_GET_MEAL_PLANS_REQUEST,
  ACTION_GET_MEAL_PLANS_LOAD,
  ACTION_GET_MEAL_PLANS_SUCCESS,
  ACTION_GET_MEAL_PLANS_ERROR,
  ACTION_GET_MEAL_PLAN_REQUEST,
  ACTION_GET_MEAL_PLAN_LOAD,
  ACTION_GET_MEAL_PLAN_SUCCESS,
  ACTION_GET_MEAL_PLAN_ERROR,
  ACTION_GET_ACTIVE_MEAL_PLAN_REQUEST,
  ACTION_GET_ACTIVE_MEAL_PLAN_LOAD,
  ACTION_GET_ACTIVE_MEAL_PLAN_SUCCESS,
  ACTION_GET_ACTIVE_MEAL_PLAN_ERROR,
  ACTION_UPDATE_MEAL_PLAN_REQUEST,
  ACTION_UPDATE_MEAL_PLAN_LOAD,
  ACTION_UPDATE_MEAL_PLAN_SUCCESS,
  ACTION_UPDATE_MEAL_PLAN_ERROR,
  ACTION_CREATE_MEAL_PLAN_REQUEST,
  ACTION_CREATE_MEAL_PLAN_LOAD,
  ACTION_CREATE_MEAL_PLAN_SUCCESS,
  ACTION_CREATE_MEAL_PLAN_ERROR,
  ACTION_ADD_MEAL_TO_DELIVERY_REQUEST,
  ACTION_ADD_MEAL_TO_MEAL_PLAN_REQUEST,
  ACTION_ADD_MEAL_TO_MEAL_PLAN_LOAD,
  ACTION_ADD_MEAL_TO_MEAL_PLAN_SUCCESS,
  ACTION_REMOVE_MEAL_FROM_MEAL_PLAN_REQUEST,
  ACTION_REMOVE_MEAL_FROM_MEAL_PLAN_LOAD,
  ACTION_REMOVE_MEAL_FROM_MEAL_PLAN_SUCCESS,
  ACTION_REMOVE_MEAL_FROM_MEAL_PLAN_ERROR,
  ACTION_REMOVE_MEAL_FROM_DELIVERY_REQUEST,
  ACTION_ACTIVATE_MEAL_PLAN_REQUEST,
  ACTION_ACTIVATE_MEAL_PLAN_LOAD,
  ACTION_ACTIVATE_MEAL_PLAN_SUCCESS,
  ACTION_ACTIVATE_MEAL_PLAN_ERROR,
  ACTION_SAVE_MEAL_PLAN_CHANGES_REQUEST,
  ACTION_SAVE_MEAL_PLAN_CHANGES_LOAD,
  ACTION_SAVE_MEAL_PLAN_CHANGES_SUCCESS,
  ACTION_SAVE_MEAL_PLAN_CHANGES_ERROR,
  ACTION_DISCARD_MEAL_PLAN_CHANGES_REQUEST,
  ACTION_DISCARD_MEAL_PLAN_CHANGES_LOAD,
  ACTION_DISCARD_MEAL_PLAN_CHANGES_SUCCESS,
  ACTION_DISCARD_MEAL_PLAN_CHANGES_ERROR,
  ACTION_UPDATE_DELIVERY_ADDRESS_REQUEST,
  ACTION_UPDATE_MULTIPLE_DELIVERY_ADDRESS_REQUEST,
  ACTION_APPLY_KICK_STARTER_MEAL_PLAN_REQUEST,
  ActionType,
} from '../action-types';
import {IRootState} from "../../types/state";

interface IResponse {
  [key: string]: any;
}

export function* sagaMealPlansWatcher() {
    yield takeLatest(ACTION_GET_MEAL_PLANS_REQUEST, getMealPlansWorker);
    yield takeLatest(ACTION_GET_MEAL_PLAN_REQUEST, getMealPlanWorker);
    yield takeLatest(ACTION_GET_ACTIVE_MEAL_PLAN_REQUEST, getActiveMealPlanWorker);
    yield takeLatest(ACTION_UPDATE_MEAL_PLAN_REQUEST, updateMealPlanWorker);
    yield takeLatest(ACTION_CREATE_MEAL_PLAN_REQUEST, createMealPlanWorker);
    yield takeLatest(ACTION_ADD_MEAL_TO_MEAL_PLAN_REQUEST, addMealToMealplanWorker);
    yield takeLatest(ACTION_REMOVE_MEAL_FROM_MEAL_PLAN_REQUEST, removeMealFromMealplanWorker);
    yield takeLatest(ACTION_ACTIVATE_MEAL_PLAN_REQUEST, activateMealPlanWorker);
    yield takeLatest(ACTION_SAVE_MEAL_PLAN_CHANGES_REQUEST, saveMealPlanChangesWorker);
    yield takeLatest(ACTION_DISCARD_MEAL_PLAN_CHANGES_REQUEST, discardMealPlanChangesWorker);
    yield takeLatest(ACTION_UPDATE_DELIVERY_ADDRESS_REQUEST, updateDeliveryAddressWorker);
    yield takeLatest(ACTION_ADD_MEAL_TO_DELIVERY_REQUEST, addMealToDeliveryWorker);
    yield takeLatest(ACTION_REMOVE_MEAL_FROM_DELIVERY_REQUEST, removeMealFromDeliveryWorker);
    yield takeLatest(ACTION_APPLY_KICK_STARTER_MEAL_PLAN_REQUEST, applyKickStarterPlanWorker);
    yield takeLatest(ACTION_UPDATE_MULTIPLE_DELIVERY_ADDRESS_REQUEST, updateMultipleDeliveryAddressWorker);
}

function* getMealPlansWorker() {
  const sessionId = getItemFromLocalStorage('session-id');

  try {
    yield put({ type: ACTION_GET_MEAL_PLANS_LOAD });

    const mealPlans = (yield call(() =>
      api.get(`/meal-plans?session=${sessionId}`).then((res) => res.data.data)
    )) as IMealPlan[];

    const sortedMealPlans = mealPlans.sort((a, b) => {
      if (a.is_active) {
        return -1;
      }
      if (b.is_active) {
        return 1;
      }

      return 0;
    })

    const selectIsAuthenticated = ((state: IRootState): boolean => state.auth.isAuthenticated)
    const isAuthenticated: boolean = yield select(selectIsAuthenticated);

    yield put({ type: ACTION_GET_MEAL_PLANS_SUCCESS, payload: { mealPlans: sortedMealPlans, isAuthenticated } });
  } catch (error) {
    yield put({ type: ACTION_GET_MEAL_PLANS_ERROR, payload: { error }});
  }
}

function* getMealPlanWorker({ payload }: ActionType<{ mealPlanId: number }>) {
  try {
    yield put({ type: ACTION_GET_MEAL_PLAN_LOAD });

    const mealPlan = (yield call(() =>
      api.get(`/meal-plans/${payload.mealPlanId}`).then((res) => res.data.data)
    )) as IMealPlan;

    yield put({ type: ACTION_GET_MEAL_PLAN_SUCCESS, payload: mealPlan });
  } catch (error) {
    yield put({ type: ACTION_GET_MEAL_PLAN_ERROR, payload: { error }});
  }
}

function* getActiveMealPlanWorker({ payload }: ActionType<{ onSuccess: (mealPlanId: number) => void }>) {
  try {
    const sessionId = getItemFromLocalStorage('session-id');
    const searchParams = new URLSearchParams(location.search)

    yield put({ type: ACTION_GET_ACTIVE_MEAL_PLAN_LOAD });

    const activeMealPlan = (yield call(() =>
      api.get(`/meal-plans/active?session=${sessionId}${searchParams.get('redirectToCheckout') ? '&create_new=false' : ''}`).then((res) => res.data.data)
    )) as IMealPlan;

    yield put({ type: ACTION_GET_ACTIVE_MEAL_PLAN_SUCCESS, payload: activeMealPlan });

    payload.onSuccess && payload.onSuccess(activeMealPlan.id);
  } catch (error) {
    yield put({ type: ACTION_GET_ACTIVE_MEAL_PLAN_ERROR, payload: { error }});
  }
}

function* createMealPlanWorker({ payload }: ActionType<{ name: string; fetchMealPlans?: boolean; onSuccess?: (mealPlanId: number) => void }>) {
  try {
    const sessionId = getItemFromLocalStorage('session-id');

    const reqBodyObj = {
      session: sessionId,
      name: payload.name || ''
    }

    yield put({ type: ACTION_CREATE_MEAL_PLAN_LOAD });

    const res = (yield call(() =>
      api.post('/meal-plans', reqBodyObj).then((res) => res.data.data)
    )) as IMealPlan;

    yield put({ type: ACTION_CREATE_MEAL_PLAN_SUCCESS, payload: res });

    if (payload.fetchMealPlans) {
      yield put({ type: ACTION_GET_MEAL_PLANS_REQUEST });
    }

    payload.onSuccess && payload.onSuccess(res.id);
  } catch (error) {
    yield put({ type: ACTION_CREATE_MEAL_PLAN_ERROR, payload: { error }});
  }
}

function* updateMealPlanWorker({ payload }: ActionType<{ 
    mealPlanId: number, 
    name?: string,
    deliveryNotes?: string; 
    deliveryTimeChanges?: any, 
    fetchMealPlan?: boolean, 
    onSuccess?: () => void 
  }>) {
  try {
    let reqBodyObj: any = {};

    if (payload.name) {
      reqBodyObj.name = payload.name;
    }
    
    if (payload.deliveryNotes) {
      reqBodyObj.delivery_notes = payload.deliveryNotes;
    }

    if (payload.deliveryTimeChanges) {
      reqBodyObj.meal_days = {}
      for (const day in payload.deliveryTimeChanges) {
        if (payload.deliveryTimeChanges.hasOwnProperty(day) && payload.deliveryTimeChanges[day].newDeliveryTime) {
            reqBodyObj.meal_days[day.toLocaleLowerCase()] = { delivery_time: '' };
            reqBodyObj.meal_days[day.toLocaleLowerCase()].delivery_time = payload.deliveryTimeChanges[day].newDeliveryTime;
        }
      }
    }

    yield put({ type:ACTION_UPDATE_MEAL_PLAN_LOAD });

    const mealPlan = (yield call(() =>
      api.patch(`/meal-plans/${payload.mealPlanId}`, reqBodyObj).then((res) => res.data.data)
    )) as IMealPlan;

    yield put({ type: ACTION_UPDATE_MEAL_PLAN_SUCCESS, payload: mealPlan });

    if (payload.fetchMealPlan) {
      yield put({ type: ACTION_GET_MEAL_PLAN_REQUEST, payload: { mealPlanId: payload.mealPlanId } });
    }

    payload.onSuccess && payload.onSuccess()
  } catch (error) {
    yield put({ type: ACTION_UPDATE_MEAL_PLAN_ERROR, payload: { error }});
  }
}

function* addMealToMealplanWorker({ payload }: ActionType<{
    mealId: number;
    mealPlanId: number;
    createNewMealPlan: boolean;
    newMealPlanName: string;
    mealsPerDay?: any;
    selectedOptions?: any;
    onSuccess?: () => void;
  }>) {
  try {
    const reqBodyObj: any = {
      meal_plan_id: payload.mealPlanId,
      meals_of_plans: [
        {
          meal_id: payload.mealId,
          currency_id: 1,
        }
      ],
      remove_old_meals: false,
    }

    if (Object.values(payload.mealsPerDay).some((day) => day !== null && day !== 0)) {
      const meal_days = [];
      for (const day in payload.mealsPerDay) {
        if (payload.mealsPerDay.hasOwnProperty(day) && payload.mealsPerDay[day] > 0) {
            meal_days.push({ day: day.toLowerCase(), quantity: payload.mealsPerDay[day] })
        }
      }

      reqBodyObj.meals_of_plans[0].meal_days = meal_days;
    }

    if (payload.selectedOptions?.length) {
      const options: { option_id: number, component_id: number }[] = [];
      payload.selectedOptions.forEach((option: any) => {
          options.push({ option_id: option.optionId, component_id: option.componentId })
      });

      reqBodyObj.meals_of_plans[0].options = options;
    }

    const selectIsAuthenticated = (state: IRootState): boolean => state.auth.isAuthenticated;
    const isAuthenticated: boolean = yield select(selectIsAuthenticated);
    
    let region: string = cookieManager.get('region');
    if (!region) {
      region =  yield select((state: IRootState) => state.auth.userInfo?.accounts[0]?.regionInfo?.region)
    }

    yield put({ type: ACTION_ADD_MEAL_TO_MEAL_PLAN_LOAD });

    if (payload.createNewMealPlan) {
        const sessionId = getItemFromLocalStorage('session-id');

        const newMealPlan = (yield call(() =>
            api.post('/meal-plans', { session: sessionId, name: payload.newMealPlanName  }).then((res) => res.data.data)
        )) as IResponse;

        reqBodyObj.meal_plan_id = newMealPlan.id;
    }

    const res = (yield call(() =>
        api.post(`/meal-plans/meals${region ? `?region=${region}` : ''}`, reqBodyObj).then((res) => res.data.data)
    )) as IResponse;

    yield put({ type: ACTION_ADD_MEAL_TO_MEAL_PLAN_SUCCESS });

    payload.onSuccess && payload.onSuccess();
  } catch (error) {
    yield put({ type: ACTION_GET_ACTIVE_MEAL_PLAN_ERROR, payload: { error }});
  }
}

function* removeMealFromMealplanWorker({ payload }: ActionType<{ mealId: number, mealPlanId: number, fetchMealPlan?: boolean, onSuccess?: () => void }>) {
  try {
    yield put({ type: ACTION_REMOVE_MEAL_FROM_MEAL_PLAN_LOAD });

    yield call(() =>
      api.delete(`/meal-plans/meals/${payload.mealId}`).then((res) => res.data.data)
    );

    yield put({ type: ACTION_REMOVE_MEAL_FROM_MEAL_PLAN_SUCCESS });

    payload.onSuccess && payload.onSuccess();

    if (payload.fetchMealPlan) {
      yield put({ type: ACTION_GET_MEAL_PLAN_REQUEST, payload: { mealPlanId: payload.mealPlanId } });
    }
  } catch (error) {
    yield put({ type: ACTION_REMOVE_MEAL_FROM_MEAL_PLAN_ERROR, payload: { error }});
  }
}

function* activateMealPlanWorker({ payload }: ActionType<{ mealPlanId: number, fetchMealPlanList: boolean, onSuccess?: () => void }>) {
  try {
    yield put({ type: ACTION_ACTIVATE_MEAL_PLAN_LOAD });

    yield call(() =>
      api.post('/meal-plans/activate', { meal_plan_id: payload.mealPlanId }).then((res) => res.data.data)
    );

    yield put({ type: ACTION_ACTIVATE_MEAL_PLAN_SUCCESS });

    if (payload.fetchMealPlanList) {
      yield put({ type: ACTION_GET_MEAL_PLANS_REQUEST });
    }

    payload.onSuccess && payload.onSuccess();
  } catch (error) {
    yield put({ type: ACTION_ACTIVATE_MEAL_PLAN_ERROR, payload: { error }});
  }
}

function* saveMealPlanChangesWorker({ payload }: ActionType<{ mealPlanId: number, applyChangesFrom: string, onSuccess?: () => void }>) {
  try {
    yield put({ type: ACTION_SAVE_MEAL_PLAN_CHANGES_LOAD });

    yield call(() =>
      api.patch(`/meal-plans/${payload.mealPlanId}/save`, { apply_changes_from: payload.applyChangesFrom }).then((res) => res.data.data)
    );

    yield put({ type: ACTION_SAVE_MEAL_PLAN_CHANGES_SUCCESS });

    payload.onSuccess && payload.onSuccess();
  } catch (error) {
    yield put({ type: ACTION_SAVE_MEAL_PLAN_CHANGES_ERROR, payload: { error }});
  }
}

function* discardMealPlanChangesWorker({ payload }: ActionType<{ mealPlanId: number, onSuccess?: () => void }>) {
  try {
    yield put({ type: ACTION_DISCARD_MEAL_PLAN_CHANGES_LOAD });

    yield call(() =>
      api.delete(`/meal-plans/${payload.mealPlanId}/discard-changes`).then((res) => res.data.data)
    );

    yield put({ type: ACTION_DISCARD_MEAL_PLAN_CHANGES_SUCCESS });

    payload.onSuccess && payload.onSuccess();
  } catch (error) {
    yield put({ type: ACTION_DISCARD_MEAL_PLAN_CHANGES_ERROR, payload: { error }});
  }
}

function* updateDeliveryAddressWorker({ payload }: ActionType<{
  deliveryId: number,
  deliveryNotes: string,
  deliveryTime: string,
  address_1: string,
  area: string,
  phone_number: string,
  update_meal_plan: boolean,
  onSuccess?: () => void 
}>) {
  try {
    let reqBodyObj: any = {
      update_meal_plan: payload.update_meal_plan,
      delivery_notes: payload.deliveryNotes,
      delivery_time: payload.deliveryTime,
      address_1: payload.address_1,
      address_2: "",
      area: payload.area,
      phone_number: payload.phone_number,
    };

    yield call(() =>
      api.post(`deliveries/${payload.deliveryId}/update`, reqBodyObj).then((res) => res.data.data)
    );

    payload.onSuccess && payload.onSuccess()
  } catch (error) {
    yield put({ type: ACTION_UPDATE_MEAL_PLAN_ERROR, payload: { error }});
  }
}

function* updateMultipleDeliveryAddressWorker({ payload }: ActionType<{
  deliveriesData: {
    id: number;
    delivery_time: string;
    delivery_notes: string | null;
  }[],
  address_1: string,
  area: string,
  phone_number: string,
  update_meal_plan: boolean,
  onSuccess?: () => void 
}>) {
  try {
    let reqBodyObj: any = payload.deliveriesData.map(del => ({
      id: del.id,
      delivery_notes: del.delivery_notes,
      delivery_time: del.delivery_time,
      address_1: payload.address_1,
      address_2: "",
      area: payload.area,
      phone_number: payload.phone_number,
      update_meal_plan: payload.update_meal_plan,
    }));

    yield call(() =>
      api.post(`/deliveries/batch/update`, reqBodyObj).then((res) => res.data.data)
    );

    payload.onSuccess && payload.onSuccess()
  } catch (error) {
    yield put({ type: ACTION_UPDATE_MEAL_PLAN_ERROR, payload: { error }});
  }
}


function* addMealToDeliveryWorker({ payload }: ActionType<{
  mealId: number;
  deliveryPlanId: number;
  mealsPerDay?: any;
  selectedOptions?: any;
  update_meal_plan: boolean;
  onSuccess?: () => void;
}>) {
  try {
    const reqBodyObj: any = {
      update_meal_plan: payload.update_meal_plan,
      meal_id: payload.mealId,
      currency_id: 1,
    }

    if (Object.values(payload.mealsPerDay).some((day) => day !== null && day !== 0)) {
      const meal_days = [];
      for (const day in payload.mealsPerDay) {
        if (payload.mealsPerDay.hasOwnProperty(day) && payload.mealsPerDay[day] > 0) {
            meal_days.push({ day: day.toLowerCase(), quantity: payload.mealsPerDay[day] })
        }
      }

      reqBodyObj.meal_days = meal_days;
    }

    if (payload.selectedOptions?.length) {
      const options: { option_id: number, component_id: number }[] = [];
      payload.selectedOptions.forEach((option: any) => {
          options.push({ option_id: option.optionId, component_id: option.componentId })
      });

      reqBodyObj.options = options;
    }
    
    let region: string = cookieManager.get('region');
    if (!region) {
      region =  yield select((state: IRootState) => state.auth.userInfo?.accounts[0]?.regionInfo?.region)
    }
    reqBodyObj.region = region;

    yield put({ type: ACTION_ADD_MEAL_TO_MEAL_PLAN_LOAD });

    const res = (yield call(() =>
        api.post(`/delivery-plans/${payload.deliveryPlanId}/meals`, reqBodyObj).then((res) => res.data.data)
    )) as IResponse;

    yield put({ type: ACTION_ADD_MEAL_TO_MEAL_PLAN_SUCCESS });

    payload.onSuccess && payload.onSuccess();
  } catch (error) {
    yield put({ type: ACTION_GET_ACTIVE_MEAL_PLAN_ERROR, payload: { error }});
  }
}


function* removeMealFromDeliveryWorker({ payload }: ActionType<{ deliveryId: number, mealOfDeliveryId: number, onSuccess?: () => void }>) {
  try {
    yield put({ type: ACTION_REMOVE_MEAL_FROM_MEAL_PLAN_LOAD });

    yield call(() =>
      api.post(`/deliveries/${payload.deliveryId}/remove-meal`, {meal_of_delivery_id: payload.mealOfDeliveryId}).then((res) => res.data.data)
    );

    yield put({ type: ACTION_REMOVE_MEAL_FROM_MEAL_PLAN_SUCCESS });

    payload.onSuccess && payload.onSuccess();

  } catch (error) {
    yield put({ type: ACTION_REMOVE_MEAL_FROM_MEAL_PLAN_ERROR, payload: { error }});
  }
}

function* applyKickStarterPlanWorker({ payload }: ActionType<{
  mealPlanId: number;
  remove_old_meals: boolean;
  createNewMealPlan: boolean;
  newMealPlanName: string;
  meals_of_plans: any,
  onSuccess?: (newPlanId: number) => void;
}>) {
  try {
    const reqBodyObj: any = {
      meal_plan_id: payload.mealPlanId,
      meals_of_plans: payload.meals_of_plans,
      remove_old_meals: payload.remove_old_meals,
    }

    const selectIsAuthenticated = (state: IRootState): boolean => state.auth.isAuthenticated;
    const isAuthenticated: boolean = yield select(selectIsAuthenticated);

    // const region: string = isAuthenticated ?
    //     yield select((state: IRootState) => state.auth.userInfo?.accounts[0]?.regionInfo?.region)
    //     : cookieManager.get('region');
    
    let region: string = cookieManager.get('region');
    if (!region) {
      region =  yield select((state: IRootState) => state.auth.userInfo?.accounts[0]?.regionInfo?.region)
    }

    yield put({ type: ACTION_ADD_MEAL_TO_MEAL_PLAN_LOAD });

    if (payload.createNewMealPlan) {
        const sessionId = getItemFromLocalStorage('session-id');

        const newMealPlan = (yield call(() =>
            api.post('/meal-plans', { session: sessionId, name: payload.newMealPlanName  }).then((res) => res.data.data)
        )) as IResponse;

        reqBodyObj.meal_plan_id = newMealPlan.id;
    }

    const res = (yield call(() =>
        api.post(`/meal-plans/meals${region ? `?region=${region}` : ''}`, reqBodyObj).then((res) => res.data.data)
    )) as IResponse;

    yield put({ type: ACTION_ADD_MEAL_TO_MEAL_PLAN_SUCCESS });

    payload.onSuccess && payload.onSuccess(reqBodyObj.meal_plan_id);
  } catch (error) {
    yield put({ type: ACTION_GET_ACTIVE_MEAL_PLAN_ERROR, payload: { error }});
  }
}