import React from 'react';

import placeholderImage from 'src/assets/media/homePage/contentType/contentPlaceholder.png';
import playIcon from 'src/assets/media/homePage/contentType/playIcon.svg';

import { isValidHttpUrl } from 'src/utils';

import styles from './TestimonialVideoSection.module.sass';

interface Props {
    video1: string;
    video1name: string;
    video1role: string;
    video2: string;
    video2name: string;
    video2role: string;
}

const TestimonialVideoSection: React.FC<Props> = ({
    video1,
    video1name,
    video1role,
    video2,
    video2name,
    video2role,
}) => {
    return (
        <div className = { styles.videosContainer }>
            {
                video1 && isValidHttpUrl(video1) ? (
                    <div className = { styles.contentImg }>
                        <iframe
                            className = { styles.video }
                            title = 'testimonial1'
                            src = { video1 }
                            frameBorder = '0'
                            allow = 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                            allowFullScreen
                        />
                        <div className = { styles.testimonialName }>
                            <h2>{video1name}</h2>
                            <p>{video1role}</p>
                        </div>
                    </div>
                ) : (
                    <div className = { `${styles.contentImg} h-auto` }>
                        <div className = { styles.imgGradient } ></div>
                        <div className = { styles.playButton }>
                            <img
                                alt = 'placeholderImage'
                                src = { playIcon }
                            />
                        </div>
                        <img
                            alt = 'placeholderImage'
                            src = { placeholderImage }
                        />
                        <div className = { styles.testimonialName }>
                            <h2>{video1name}</h2>
                            <p>{video1role}</p>
                        </div>
                    </div>
                )
            }

            {
                video2  && isValidHttpUrl(video2) ? (
                    <div className = { styles.contentImg }>
                        <iframe
                            className = { styles.video }
                            title = 'testimonial1'
                            src = { video2 }
                            frameBorder = '0'
                            allow = 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                            allowFullScreen
                        />
                        <div className = { styles.testimonialName }>
                            <h2>{video2name}</h2>
                            <p>{video2role}</p>
                        </div>
                    </div>
                ) : (
                    <div className = { `${styles.contentImg} h-auto` }>
                        <div className = { styles.imgGradient } ></div>
                        <div className = { styles.playButton }>
                            <img
                                alt = 'placeholderImage'
                                src = { playIcon }
                            />
                        </div>
                        <img
                            alt = 'placeholderImage'
                            src = { placeholderImage }
                        />
                        <div className = { styles.testimonialName }>
                            <h2>{video2name}</h2>
                            <p>{video2role}</p>
                        </div>
                    </div>
                )
            }
                        
        </div>
    );
};

export default TestimonialVideoSection;
