import React from 'react';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet';
import clsx from 'clsx';

import Loader from 'src/components/Loader';
import Question from './components/Question';
import Hero from './components/Hero';
import HighlightedMeals from './components/HighlightedMeals';
import CtaArea from './components/CtaArea/CtaArea';
import { SideIcons } from './components/SideIcons';
import { LifeStyle } from './components/LifeStyle';
import {
  ImageLeftCard,
  ImageRightCard,
} from './components/ContentType/ContentType';
import About from './components/About';
import fullWidthPlaceholder from 'src/assets/media/homePage/contentType/fullWidthPlaceholder.png';
import FullText from './components/FullText/FullText';
import TestimonialVideoSection from './components/TestimonialVideoSection/TestimonialVideoSection';
import TestimonialCardSection from './components/TestimonialCardSection/TestimonialCardSection';
import { TestimonialRotating } from './components/TestimonialRotating';
import Contacts from './components/Contacts';
import { WhyEatRightSection } from './components/WhyEatRightNew';

import useGetUserRegion from 'src/hooks/api/deliveries/useGetUserRegion';
import useCMSContent from 'src/hooks/api/cms/useCMSContent';
import {
  parseCtaContent,
  parseFaqContent,
  parseHeroContent, 
  parseSideIconsContent,
  parseLifestyleContent,
  parseImageVideoText,
  parseVideoTestimonial,
  parseTestimonial,
  parseAboutContent
} from 'src/utils/api/cms-content';

export default function Content() {
  const params: any = useParams()
  const region = useGetUserRegion()

  const { pageBlocks, SEOTitle, SEODescription, SEOTags, isLoading, error } = useCMSContent(params.slug, region || '')
  
  if (!isLoading && error) {
    return (
      <div className = 'w-full h-screen'>
        <h1 className = 'w-fit mx-auto my-60 text-3xl md:text-5xl font-bold' style = {{ width: 'fit-content', color: '#757575' }}>
          <h1 className = 'text-center mb-4' style ={{ color: '#90BF45' }}>404</h1> 
          <p>Page not found</p> 
        </h1>
      </div>
    )
  }

  return (
      <div>
        <Helmet>
          <title>{SEOTitle}</title>
          <meta name="description" content={SEODescription} />
          {
            SEOTags?.split(',').map((tag) => (
              <meta key={tag} property={tag.split(' ')[0]} content={tag.split(' ')[1]} />
            ))
          }
        </Helmet>

        {isLoading ? <Loader className = 'w-full h-screen'/> : 
        <div>
          {pageBlocks.map((block, index) => {
            switch (block.block_type) {
              case 'faq':
                return <div key={index} className='common-container content-container'>
                  {parseFaqContent(block).map((row: any, index: number) => (
                      <Question
                          key = { row.id }
                          num = { index + 1 }
                          question = {{ title: row.title, answer: row.answer }}
                      />
                  ))}
                </div>
              case 'hero':
                return (
                  <div key={index} className='content-container'>
                    <Hero {...parseHeroContent(block)} />
                  </div>
                )
              case 'highlighted_meals':
                return (
                  <div key={index} className='content-container py-0'>
                    <HighlightedMeals meals = { (block.contents || []).map((content: any) => content.value) || [] }/>
                  </div>
                )
              case 'cta_area':
                return (
                  <div key={index} className='common-container content-container'>
                    <CtaArea {...parseCtaContent(block)} />
                  </div>
                )
              case 'side_side_icons':
                return (
                  <div key={index}>
                    <SideIcons data={parseSideIconsContent(block)} />
                  </div>
                )
              case 'life_style':
                return (
                  <div key={index}>
                    <LifeStyle  {...parseLifestyleContent(block)} />
                  </div>
                )
              case 'left_title':
                return (
                  <div key={index} className='common-container content-container'>
                    <h3 className = { clsx('section-heading', 'mb-16', 'text-left') }>
                      { (block.contents || []).find((c: any) => c.key === 'title')?.value || '' } 
                    </h3>
                  </div>
                )
              case 'left_image_text_block':
                return (
                  <div key={index} className='common-container content-container'>
                    <ImageLeftCard { ...parseImageVideoText(block) } />
                  </div>
                )
              case 'right_image_text_block':
                return (
                  <div key={index} className='common-container content-container'>
                    <ImageRightCard { ...parseImageVideoText(block) } />
                  </div>
                )
              case 'why_eat_right':
                return (
                  <div key={index}>
                    <WhyEatRightSection />
                  </div>
                )
              case 'about':
                return (
                  <div key={index}>
                    <About {...parseAboutContent(block)} />
                  </div>
                )
              case 'contact':
                return (
                  <div key={index}>
                    <Contacts />
                  </div>
                )
              case 'left_video_text_block':
                return (
                  <div key={index} className='common-container content-container'>
                    <ImageLeftCard  { ...parseImageVideoText(block) }/>
                  </div>
                )
              case 'full_width_image':
                return (
                  <div key={index} className='common-container content-container'>
                    <img
                      className = 'w-full h-auto'
                      alt = 'full width image'
                      src = { (block.contents || []).find((c: any) => c.key === 'image')?.value || fullWidthPlaceholder }
                    />
                  </div>
                )
              case 'full_width_paragraph':
                return (
                  <div key={index} className='common-container'>
                    <FullText text = { (block.contents || []).find((c: any) => c.key === 'main_text')?.value || '' } />
                  </div>
                )
              case 'video_testimonial':
                return (
                  <div key={index} className='content-container'>
                    <TestimonialVideoSection { ...parseVideoTestimonial(block) } />
                  </div>
                  )
              case 'testimonial':
                return (
                  <div key={index} className='content-container'>
                    <TestimonialCardSection { ...parseTestimonial(block) } />
                  </div>
                )
              case 'testimonials_rotating':
                return (
                  <div key={index} className='content-container pb-0'>
                    <TestimonialRotating { ...parseTestimonial(block) }/>
                  </div>
                )
              case 'center_title':
                return (
                  <div key={index} className='common-container content-container'>
                    <h3 className = { clsx('section-heading', 'mb-16') }>
                    { (block.contents || []).find((c: any) => c.key === 'title')?.value || '' }
                    </h3>
                  </div>
                )
              default:
                return null
            }
          })}
        </div>}
      </div>
  );
}
