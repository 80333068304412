import { ILoyalty } from "src/types/state/settingsLoyalty";
import { ActionType, ACTION_GET_LOYALTY_REDEMPTIONS_ERROR, ACTION_GET_LOYALTY_REDEMPTIONS_REQUEST, ACTION_GET_LOYALTY_REDEMPTIONS_SUCCESS, ACTION_GET_LOYALTY_WALLET_ERROR, ACTION_GET_LOYALTY_WALLET_REQUEST, ACTION_GET_LOYALTY_WALLET_SUCCESS } from "../action-types";

const initialState: ILoyalty = {
    redemption: {
        loading: false,
        data: [],
        error: null,
        currentPage: 1,
        perPage: 0,
        total: 0,
    },
    wallet: {
        loading: false,
        referralToken: '',
        referrals: 0,
        totalRevenue: 0,
        totalPoints: 0,
        availablePoints: 0,
        error: null,
    }
};
  
export const settingsLoyalty = (state = initialState, { type, payload }: ActionType<any>): ILoyalty => {
    switch (type) {
        case ACTION_GET_LOYALTY_REDEMPTIONS_REQUEST:
            return {
                ...state,
                redemption: {
                    ...state.redemption,
                    loading: true,
                }

            };
        case ACTION_GET_LOYALTY_REDEMPTIONS_SUCCESS:
            return {
                ...state,
                redemption: {
                    ...state.redemption,
                    loading: false,
                    data: payload.data,
                    currentPage: payload.meta.current_page,
                    perPage: payload.meta.per_page,
                    total: payload.meta.total,
                    error: null
                }
            };
        case ACTION_GET_LOYALTY_REDEMPTIONS_ERROR:
            return {
                ...state,
                redemption: {
                    ...state.redemption,
                    loading: false,
                    error: payload,
                }
            };
        case ACTION_GET_LOYALTY_WALLET_REQUEST:
            return {
                ...state,
                wallet: {
                    ...state.wallet,
                    loading: true,
                }

            };
        case ACTION_GET_LOYALTY_WALLET_SUCCESS:
            return {
                ...state,
                wallet: {
                    ...state.wallet,
                    loading: false,
                    totalPoints: payload.data.ltp,
                    availablePoints: payload.data.points,
                    totalRevenue: payload.meta.totalRevenue,
                    referrals: payload.meta.referrals,
                    referralToken: payload.meta.referralToken,
                    error: null
                }
            };
        case ACTION_GET_LOYALTY_WALLET_ERROR:
            return {
                ...state,
                wallet: {
                    ...state.wallet,
                    loading: false,
                    error: payload,
                }
            };

        default:
            return state;
    }
};
  