import React, { useRef } from 'react';
import Drawer from './bottom-drawer.styles';
import { ReactComponent as ArrowIcon } from 'src/assets/media/icons/back-arrow.svg';
import { useSwipe } from '../../../hooks/useSwipe';

type Props = {
    children: React.ReactNode,
    title: string;
    isOpen:boolean;
    onClose:()=>void;
    back?: boolean;
};
const BottomDrawer = ({ children, title, isOpen, onClose, back }: Props) => {
    const ref = useRef(null);
    const { y } = useSwipe(ref, ({ y }) => y >= 50, onClose);

    return (
        <Drawer
            className = { 'drawer-bottom' }
            closable = { false }
            height = { 'auto' }
            placement = { 'bottom' }
            style = {{ '--top': `-${Math.max(0, y)}px` } as any}
            visible = { isOpen }
            onClose = { onClose }>
            <div
                className = { 'drawer__header' }
                ref = { ref }>
                {
                    back ? (
                        <ArrowIcon
                            className = { 'drawer__back' }
                            onClick = { onClose }
                        />
                    ) : null
                }
                <h3 className = { 'drawer__title' }>{title}</h3>
            </div>
            {children}
        </Drawer>
    );
};

export default BottomDrawer;
