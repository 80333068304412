// Core
import React, { FC } from 'react';
import clsx from 'clsx';

// Assets
import SectionImage1 from 'src/assets/media/cms/lifestyle1.png';
import SectionImage2 from 'src/assets/media/cms/lifestyle2.png';
import SectionImage3 from 'src/assets/media/cms/lifestyle3.png';
import SectionImage4 from 'src/assets/media/cms/lifestyle4.png';
import SectionImage5 from 'src/assets/media/cms/lifestyle5.png';
import SectionImage6 from 'src/assets/media/cms/lifestyle6.png';

// Styles
import styles from './LifeStyle.module.sass';

type LifeStyleType = FC<{ 
    title: string;
    text: string;
    buttonText: string;
    image1: string;
    image2: string;
    image3: string;
    image4: string;
    image5: string;
    image6: string;
    className?: string 
}>;

export const LifeStyle: LifeStyleType = ({
    className,
    title,
    text,
    buttonText,
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
}) => {
    return (
        <section className = { clsx(className, 'common-section', styles.section) }>
            <div className = 'common-container flex-1'>
                <div className = { styles.content }>
                    <h3 className = { styles.title }>
                        {title}
                    </h3>
                    <p className = { styles.description }>
                       {text}
                    </p>
                    <p className = { styles.hashtag }>
                       {buttonText}
                    </p>
                </div>
            </div>
           
            <div className = { styles.imagesGrid }>
                    <img
                        alt = 'Lifestyle1'
                        style = {{ gridArea: 'image1'}}
                        src = { image1 || SectionImage1 }
                    />
                    <img
                        alt = 'Lifestyle2'
                        style = {{ gridArea: 'image2'}}
                        src = { image2 || SectionImage2 }
                    />
                    <img
                        alt = 'Lifestyle3'
                        style = {{ gridArea: 'image3'}}
                        src = { image3 || SectionImage3 }

                    />
                    <img
                        alt = 'Lifestyle4'
                        style = {{ gridArea: 'image4'}}
                        src = { image4 || SectionImage4 }

                    />
                    <img
                        alt = 'Lifestyle5'
                        style = {{ gridArea: 'image5'}}
                        src = { image5 || SectionImage5 }

                    />
                    <img
                        alt = 'Lifestyle6'
                        style = {{ gridArea: 'image6'}}
                        src = { image6 || SectionImage6 }
                    />
            </div>
        </section>
    );
};
