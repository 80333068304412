import React from 'react';
import { DesktopLayout, MobileLayout } from 'src/layouts';

import styles from './MealCard.module.sass';
import clsx from 'clsx';

interface Props {
    name: string;
    calories: number;
    price?: string;
    imageUrl: string;
    type?: string
    openPersonalizeAndAdd?: () => void
    className?: string;
    onClick?: null | (() => void)
    customButtonLabel?: string
    onlyImg?: boolean
    imgClassName?: string
    mealTags?: string[]
}

const MealCard: React.FC<Props> = (props) => {
    const renderTypeLabel = () => {
        if (props.mealTags && props.mealTags.includes("Dessert")) {
            return (
                <div
                    className = { styles.typeLabel }
                    style = {{ backgroundColor: '#12c833' }}>
                    Dessert
                </div>
            );
        } else if (props.mealTags && props.mealTags.includes("Sauces")) {
            return (
                <div
                    className = { styles.typeLabel }
                    style = {{ backgroundColor: '#12c833' }}>
                    Sauces
                </div>
            );
        } else if (props.mealTags && props.mealTags.includes("Breakfast")) {
            return (
                <div
                    className = { styles.typeLabel }
                    style = {{ backgroundColor: '#12c833' }}>
                    Breakfast
                </div>
            );
        } else if (props.mealTags && props.mealTags.includes("Side Dishes")) {
            return (
                <div
                    className = { styles.typeLabel }
                    style = {{ backgroundColor: '#12c833' }}>
                    Side Dishes
                </div>
            );
        } else if (props.type === 'pre-set') {
            return (
                <div
                    className = { styles.typeLabel }
                    style = {{ backgroundColor: '#90BF45' }}>
                    Pre-Set Meal
                </div>
            );
        } else if (props.type === 'customisable') {
            return (
                <div
                    className = { styles.typeLabel }
                    style = {{ backgroundColor: '#EF1733' }}>
                    Build Your Own
                </div>
            );
        } else if (props.type === 'suggested') {
            return (
                <div
                    className = { styles.typeLabel }
                    style = {{ backgroundColor: '#FF6B2C' }}>
                    Suggested
                </div>
            );
        }
    };

    const labelImg = (
        <>
            { renderTypeLabel() }
            {
                props.imageUrl ?
                <div
                    className = { clsx(styles.image, props.imgClassName, {
                        'cursor-pointer': props.onClick,
                    }) }
                    style = {{ backgroundImage: `url(${props.imageUrl})` }}
                    onClick = { props.onClick as (() => void | undefined) }
                /> :
                <div
                    className = { clsx(styles.image, styles.imageFallback, props.imgClassName, {
                        'cursor-pointer': props.onClick,
                    }) }
                    onClick = { props.onClick as (() => void | undefined) }
                > 
                    <span>Image is coming soon...</span>
                </div>
            }
           
        </>
    );

    if (props.onlyImg) {
        return (
            <div
                className = { clsx(styles.container, props.className) }>
                {labelImg}
            </div>
        );
    }

    const renderPrice = () => {
        if (!props.price) {
            return null
        }

        if (props.type === 'customisable') {
            return (
                <div className = { styles.priceContainerMobile }>
                    <p>Starting from</p>
                    <span className = { styles.price }>{Number(props.price).toFixed(2)}</span>
                    <span className = { styles.currency }>AED</span>
                </div>
            )
        }

        return (
            <div className = { styles.priceContainerMobile }>
                <span className = { styles.price }>{Number(props.price).toFixed(2)}</span>
                <span className = { styles.currency }>AED</span>
            </div>
        );
    }

    const renderCalories = () => {
        if (props.type === 'customisable') {
            return (
                <div className='flex flex-col md:flex-row'>
                    <p className='text-sm md:text-lg mr-1'>Starting from</p>
                    <p>{Math.round(props.calories)} KCal</p>
                </div>
            );
        }

        return `${Math.round(props.calories)} KCal`;
    }

    return (
        <div
            className = { clsx(styles.container, props.className) }>
            {labelImg}

            <div className = { styles.content }>
                <div className = { styles.body }>
                    <div
                        className = { clsx(styles.name, {
                            'cursor-pointer': props.onClick,
                        }) }
                        onClick = { props.onClick as (() => void | undefined) }>{props.name}
                    </div>
                    <DesktopLayout>
                        <div className = { styles.calories }>{ renderCalories() }</div>
                    </DesktopLayout>
                    <MobileLayout>
                        <div className = { styles.mealInfoMobile }>
                            { renderPrice() }
                            <div className = { styles.calories }>{ renderCalories() }</div>
                        </div>
                    </MobileLayout>
                </div>

                <div className = { styles.footer }>
                    {
                        <DesktopLayout>
                           { renderPrice() }
                        </DesktopLayout>
                    }
                    <button
                        className = { styles.buttonSecondary }
                        onClick = { (event:  React.MouseEvent<HTMLElement>)=> {
                            event.preventDefault();
                            if (props.openPersonalizeAndAdd) {
                                props.openPersonalizeAndAdd();
                            }
                        } }>
                        { props.customButtonLabel || 'Personalize & Add' }
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MealCard;
