import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { useUserAccount } from '../auth/useUserAccount';
import { IRootState } from 'src/types/state';

const useGetUserRegion = () => {
    const [ cookies ] = useCookies();
    const { userAccount } = useUserAccount();

    const { isAuthenticated } = useSelector((state: IRootState) => state.auth);
    const [ region, setRegion ] = useState();

    useEffect(() => {
        setRegion(isAuthenticated ? userAccount?.region : cookies.region);
    }, [ userAccount, cookies ]);

    return region;
};

export default useGetUserRegion;
