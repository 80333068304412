// Core
import React, { FC } from 'react';
import clsx from 'clsx';

// Styles
import styles from './howDoesItWorkCard.module.sass';

type HowDoesItWorkCardType = FC<{
    className?: string;
    title: string;
    desc: string;
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | string;
}>;

export const HowDoesItWorkCard: HowDoesItWorkCardType = ({
    className,
    title,
    desc,
    Icon,
}) => {
    // @ts-ignore
    return (
        <div className = { clsx(className, styles.card) }>
            <div className = 'h-14'>
                {
                    typeof Icon === 'string'
                        ? <img
                            alt = 'Icon'
                            className = { styles.icon }
                            src = { Icon }
                        />
                        : <Icon />
                }
            </div>
            <h3 className = { styles.card__title }>
                {title}
            </h3>
            <p className = { styles.card__text }>
                {desc}
            </p>
        </div>
    );
};
