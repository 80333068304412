import { useWindowSize } from './useWindowSize';

const screenSizes = {
    DESKTOP: 1100,
    TABLET:  720,
    MOBILE:  530,
};

export const useIsMobile = () => {
    const { width } = useWindowSize();

    return width <= screenSizes.TABLET;
};
