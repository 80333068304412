// Core
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Types
import { IRootState } from "../../../types/state";
import {ACTION_GET_USER_ACCOUNT_REQUEST, ACTION_UPDATE_USER_ACCOUNT_REGION_REQUEST} from "../../../store/action-types";
import {UpdateUserAccountRegionInputType} from "../../../store/actions/auth/types";

export const useUserAccount = (accountId?: number) => {
  const dispatch = useDispatch();
  const accounts = useSelector((state: IRootState) => state.auth.userInfo?.accounts);
  const { isAuthenticated } = useSelector((state: IRootState) => state.auth);

  const userAccount = accountId ?
    accounts?.find(({id}) => id === accountId)?.regionInfo
    : accounts?.[0]?.regionInfo;

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    const targetAccountId = accountId ?? accounts?.[0]?.id;
    dispatch({type: ACTION_GET_USER_ACCOUNT_REQUEST, payload: { accountId: targetAccountId }})
  }, []);

  const updateUserAccount = (account: UpdateUserAccountRegionInputType) => {
    dispatch({type: ACTION_UPDATE_USER_ACCOUNT_REGION_REQUEST, payload: account})
  };

  return {
    userAccount,
    updateUserAccount,
  }
};
