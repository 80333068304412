import {
    ACTION_GET_ORDERS_LOAD,
    ACTION_GET_ORDERS_SUCCESS,
    ACTION_GET_ORDERS_ERROR,
    ACTION_GET_ORDER_DETAILS_LOAD,
    ACTION_GET_ORDER_DETAILS_SUCCESS,
    ACTION_GET_ORDER_DETAILS_ERROR,
    ActionType,
} from '../action-types';

import { IOrdersState } from 'src/types/state'; 

const initialState: IOrdersState = {
    ordersList: {
        loading: false,
        error: null,
        data: []
    },
    orderDetails: {
        loading: false,
        error: null,
        data: null,
    }
};

export const orders = (state = initialState, { type, payload }: ActionType<any>): IOrdersState => {
    switch (type) {
        case ACTION_GET_ORDERS_LOAD:
            return {
                ...state,
                ordersList: {
                    ...state.ordersList,
                    loading: true,
                },
            };
        case ACTION_GET_ORDERS_SUCCESS:
            return {
                ...state,
                ordersList: {
                    ...state.ordersList,
                    loading: false,
                    data:    payload,
                },
            };
        case ACTION_GET_ORDERS_ERROR:
            return {
                ...state,
                ordersList: {
                    ...state.ordersList,
                    loading: false,
                    error:   payload,
                },
            };
        case ACTION_GET_ORDER_DETAILS_LOAD:
            return {
                ...state,
                orderDetails: {
                    ...state.orderDetails,
                    loading: true,
                },
            };
        case ACTION_GET_ORDER_DETAILS_SUCCESS:
            return {
                ...state,
                orderDetails: {
                    ...state.orderDetails,
                    loading: false,
                    data:    payload,
                },
            };
        case ACTION_GET_ORDER_DETAILS_ERROR:
            return {
                ...state,
                orderDetails: {
                    ...state.orderDetails,
                    loading: false,
                    error:   payload,
                },
            };
        default:
            return state;
    }
};