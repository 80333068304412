import api from '../../../managers/api';
import { IMealPlan } from '../../../types/meal-plan';
import useSWR from 'swr';
import { getItemFromLocalStorage } from '../../../utils';

interface UseMealPlan {
    activeMealPlan: IMealPlan | undefined;
    onRevertChanges: () => void;
    isEmpty: boolean;
    isLoading: boolean;
    onRefresh: () => void
}

export default function useMealPlan(): UseMealPlan {
    const sessionId = getItemFromLocalStorage('session-id');

    const { data, error, mutate } = useSWR<any>(`/meal-plans/active?session=${sessionId}`, api.get);

    const onRevertChanges = async () => {
        try {
            await api.get('/meal-plans/revert');
            // eslint-disable-next-line no-catch-shadow
        } catch (error) {
            console.error(error);
        }
    };

    const onRefresh = () => {
        mutate();
    };

    const isLoading = !data && !error;
    const activeMealPlan = data?.data?.data;
    const isEmpty = !activeMealPlan?.meals_of_plan?.length;

    return {
        activeMealPlan,
        onRevertChanges,
        isEmpty,
        isLoading,
        onRefresh,
    };
}
