// Core
import React, { FC } from 'react';
import clsx from 'clsx';
import Slider, { Settings } from 'react-slick';

// Assets
import { ReactComponent as ArrowLeftIcon } from 'src/assets/media/icons/home/carousel-icon-left.svg';
import { ReactComponent as ArrowRightIcon } from 'src/assets/media/icons/home/carousel-icon-right.svg';

// Styles
import styles from './homeCarousel.module.sass';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

type HomeCarouselType = FC<{
    className?: string;
    children: (JSX.Element)[];
    ref?: React.RefObject<Slider>;
} & Settings>;

const sliderPropsWithArrows = {
    prevArrow: (
        <button>
            <ArrowLeftIcon />
        </button>
    ),
    nextArrow: (
        <button>
            <ArrowRightIcon />
        </button>
    ),
    slidesToShow: 1,
    dots:         true,
    infinite:     true,
    speed:        500,
    arrows:       true,
    responsive:   [
        {
            breakpoint: 768,
            settings:   {
                arrows: false,
            },
        },
    ],
};

export const HomeCarousel: HomeCarouselType = ({
    className,
    children,
    ref,
    ...rest
}) => {
    const sliderProps  = {
        ...sliderPropsWithArrows,
        ...rest,
    };

    return (
        <div className = { clsx(className, styles.carousel) }>
            <Slider
                ref = { ref }
                { ...sliderProps }>
                {children}
            </Slider>
        </div>
    );
};
