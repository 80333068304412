import React, { useState } from 'react';

// Components
import { DesktopLayout, MobileLayout } from '../index';
import MobileFooter from '../MobileLayout/MobileFooter';
import MobileHeader from '../MobileLayout/MobileHeader';
import { Header } from './Header';
import { Footer } from './Footer';
import { DesktopSidebar } from './Sidebar/DesktopSidebar';
import User from './User';

export interface Props {
    isAuthenticated: boolean;
}

const MainLayout: React.FC<Props> = (props) => {
    const [ showFooter, setShowFooter ] = useState(false);

    const toggleFooterHandler = () => {
        setShowFooter((prevState) => !prevState);
    };

    const renderLayout = () => {
        if (props.isAuthenticated) {
            return (
                <>
                    <DesktopLayout>
                        <main
                            className = 'flex overflow-visible'>
                            <DesktopSidebar
                                showFooter = { showFooter }
                                toggleFooter = { toggleFooterHandler }
                            />
                            <div
                                className = 'auth-content-wrapper'
                                onClick = { ()=>  setShowFooter(false) }>
                                <div className = 'auth-content-container'>
                                    {props.children}
                                </div>
                            </div>
                            <User
                                showFooter = { showFooter }
                                toggleFooter = { toggleFooterHandler }
                            />
                        </main>
                    </DesktopLayout>
                    <MobileLayout>
                        <main className = 'flex flex-col h-full w-full justify-between'>
                            <MobileHeader />
                                {props.children}
                            <MobileFooter />
                        </main>
                    </MobileLayout>
                </>
            );
        }

        return (
            <main>
                <Header />
                    {props.children}
                <Footer />
            </main>
        );
    };

    return (
        <div>
            {renderLayout()}
        </div>
    );
};

export default MainLayout;
