import React from 'react';
import { isValidHttpUrl } from 'src/utils';

import placeholderImage from 'src/assets/media/homePage/contentType/contentPlaceholder.png';
import playIcon from 'src/assets/media/homePage/contentType/playIcon.svg';

import styles from './ContentType.module.sass';

type ImageCardType = React.FC<{ 
    title: string;
    mainText: string;
    buttonText: string;
    buttonUrl: string;
    image: string;
    video: string;
    className?: string 
}>;

export const ImageLeftCard: ImageCardType = ({
    title,
    mainText,
    buttonText,
    buttonUrl,
    video,
    image,
}) => {
    return (
      <div className = { styles.contentBox }>
          {
              video && isValidHttpUrl(video) ?
                <iframe
                    className = { styles.video }
                    title = 'video'
                    src = { video }
                    frameBorder = '0'
                    allow = 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen
                />
                 : video && !isValidHttpUrl(video) ?
                    <div className = { styles.contentImg }>
                        <div className = { styles.playButton }>
                            <img
                                alt = 'playIcon'
                                src = { playIcon }
                            />
                        </div>
                        <img
                            alt = 'placeholder'
                            src = { placeholderImage }
                        />
                    </div> :
                    <div className = { styles.contentImg }>
                        <img
                            alt = 'placeholder'
                            src = { image || placeholderImage }
                        />
                    </div>
          }
    
          <div className = { styles.contentTextBoxRight }>
              <h1>{title}</h1>

              <div className = { styles.contentText }>
                  <p>
                      {mainText}
                  </p>
                  <br />
                <a href = { buttonUrl }>
                    <button className = { styles.buttonPrimary }>{buttonText}</button>
                </a>
                  
              </div>
          </div>
      </div>
    )
}

export const ImageRightCard: ImageCardType = ({
    title,
    mainText,
    buttonText,
    buttonUrl,
    image,
    className,
}) => {
    return (
      <div className = { styles.contentBox }>
          <div className = { styles.contentTextBoxLeft }>
              <h1>{title}</h1>

              <div className = { styles.contentText }>
                  <p>
                      {mainText}
                  </p>
                  <br />
                  <a href = { buttonUrl }>
                    <button className = { styles.buttonPrimary }>{buttonText}</button>
                  </a>
              </div>
          </div>
          <div className = { styles.contentImg }>
              <img
                alt = 'placeholder'
                src = { image || placeholderImage }
              />
          </div>
      </div>
    )
}
