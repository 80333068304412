import axios, { AxiosRequestConfig } from 'axios';
import moment from 'moment-timezone';
import { cookieManager } from './cookieManager';

interface IAccount {
    [key: string]: any;
}

const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
});
api.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        const token = cookieManager.get('access_token');

        let uuid = '';
        if (cookieManager.get('auth')) {
            uuid = JSON.parse(cookieManager.get('auth') || '{}').accounts.find((acc: IAccount) => acc.type === "client")?.uuid;
        }


        if (uuid) {
            config.headers['Account-Token'] =  uuid;
        }
        if (token) {
            config.headers.Authorization =  `Bearer ${token}`;
        }

        config.headers.Timezone = moment.tz.guess();

        return config;
    },
    (error) => Promise.reject(error),
);

api.interceptors.response.use(
    (res) => {
        return res;
    },
    (error) => {
        if (!error.response) {
            return Promise.reject(error);
        }

        return Promise.reject(error);
    },
);

export default api;
