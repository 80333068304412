import { takeLatest, put, call, select } from 'redux-saga/effects';
import { getPaymentsURL } from 'src/utils';
import { IRootState } from '../../types/state'

import api from '../../managers/api';
import {
  ACTION_GET_INVOICES_REQUEST,
  ACTION_GET_INVOICES_LOAD,
  ACTION_GET_INVOICES_SUCCESS,
  ACTION_GET_INVOICES_ERROR,
  ACTION_CREATE_INVOICE_REQUEST,
  ACTION_CREATE_INVOICE_LOAD,
  ACTION_CREATE_INVOICE_SUCCESS,
  ACTION_CREATE_INVOICE_ERROR,
  ACTION_GET_PAYMENTS_REQUEST,
  ACTION_GET_PAYMENTS_LOAD,
  ACTION_GET_PAYMENTS_SUCCESS,
  ACTION_GET_PAYMENTS_ERROR,
  ActionType,
} from '../action-types';

interface IResponse {
  [key: string]: any;
}

export function* sagaPaymentWatcher() {
    yield takeLatest(ACTION_GET_INVOICES_REQUEST, getInvoicesWorker);
    yield takeLatest(ACTION_CREATE_INVOICE_REQUEST, createInvoiceWorker);
    yield takeLatest(ACTION_GET_PAYMENTS_REQUEST, getPaymentsWorker);
}

function* getInvoicesWorker() {
  try {
    const state = (yield select()) as IRootState
    yield put({ type: ACTION_GET_INVOICES_LOAD });

    const url = encodeURI(`/invoices?filter[invoice_to]=${state.auth.userInfo?.accounts?.[0].id}`)

    const invoices = (yield call(() =>
      api.get(url, { baseURL: process.env.REACT_APP_ALLRIGHT_BASE_API_URL }).then((res) => res.data.data)
    )) as IResponse;

    yield put({ type: ACTION_GET_INVOICES_SUCCESS, payload: invoices });
  } catch (error) {
    yield put({ type: ACTION_GET_INVOICES_ERROR, payload: { error }});
  }
}

function* createInvoiceWorker({ payload }: ActionType<any>) {
  try {
    yield put({ type: ACTION_CREATE_INVOICE_LOAD });

    const reqBodyObj = { ...payload };
    delete reqBodyObj.redirectToCheckout;

    const invoice = (yield call(() =>
      api.post('/invoices', reqBodyObj, { baseURL: process.env.REACT_APP_ALLRIGHT_BASE_API_URL }).then((res) => res.data.data)
    )) as IResponse;

    yield put({ type: ACTION_CREATE_INVOICE_SUCCESS });

    yield put({ type: ACTION_CREATE_INVOICE_SUCCESS });
    
    if (payload.redirectToCheckout) {
      window.location.href = getPaymentsURL(`/invoices/${invoice.id}/pay`);
    }
  } catch (error) {
    yield put({ type: ACTION_CREATE_INVOICE_ERROR, payload: { error }});
  }
}

function* getPaymentsWorker() {
  try {
    yield put({ type: ACTION_GET_PAYMENTS_LOAD });

    const payments = (yield call(() =>
      api.get('/payments', { baseURL: process.env.REACT_APP_ALLRIGHT_BASE_API_URL }).then((res) => res.data.data)
    )) as IResponse;

    yield put({ type: ACTION_GET_PAYMENTS_SUCCESS, payload: payments });
  } catch (error) {
    yield put({ type: ACTION_GET_PAYMENTS_ERROR, payload: { error }});
  }
}
