// Core
import React, { FC } from 'react';
import clsx from 'clsx';

// Styles
import styles from './regionSelectButton.module.sass';

type RegionSelectButtonType = FC<{
    className?: string;
    isSelected: boolean;
    text: string;
    handleClick: () => void;
}>;

export const RegionSelectButton: RegionSelectButtonType = ({
    className,
    isSelected,
    text,
    handleClick,
}) => {
    return (
        <div
            className = { clsx(className, styles.button, clsx({
                [styles['button--checked']]: isSelected,
            })) }
            onClick = { handleClick }>
            <div className = { styles.mark } />
            <div className = { styles.text }>
                { text }
            </div>
        </div>
    );
};
