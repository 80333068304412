export const ACTION_INIT = '@@redux/INIT';

// Auth
export const ACTION_LOGIN_REQUEST = 'ACTION_LOGIN_REQUEST';
export const ACTION_LOGIN_SUCCESS = 'ACTION_LOGIN_SUCCESS';
export const ACTION_LOGIN_ERROR = 'ACTION_LOGIN_ERROR';
export const ACTION_LOGOUT_REQUEST = 'ACTION_LOGOUT_REQUEST';
export const ACTION_LOGOUT_SUCCESS = 'ACTION_LOGOUT_SUCCESS';
export const ACTION_LOGOUT_ERROR = 'ACTION_LOGOUT_ERROR';

export const ACTION_GET_USER_ACCOUNT_REQUEST = 'ACTION_GET_USER_ACCOUNT_REQUEST';
export const ACTION_GET_USER_ACCOUNT_SUCCESS = 'ACTION_GET_USER_ACCOUNT_SUCCESS';

export const ACTION_UPDATE_USER_ACCOUNT_REGION_REQUEST = 'ACTION_UPDATE_USER_ACCOUNT_REGION_REQUEST';
export const ACTION_UPDATE_USER_ACCOUNT_REGION_SUCCESS = 'ACTION_UPDATE_USER_ACCOUNT_REGION_SUCCESS';

export const ACTION_UPDATE_USER_PROFILE_REQUEST = 'ACTION_UPDATE_USER_PROFILE_REQUEST';
export const ACTION_UPDATE_USER_PROFILE_LOAD = 'ACTION_UPDATE_USER_PROFILE_LOAD';
export const ACTION_UPDATE_USER_PROFILE_SUCCESS = 'ACTION_UPDATE_USER_PROFILE_SUCCESS';
export const ACTION_UPDATE_USER_PROFILE_ERROR = 'ACTION_UPDATE_USER_PROFILE_ERROR';

// Meals
export const ACTION_GET_MEALS_REQUEST = 'ACTION_GET_MEALS_REQUEST';
export const ACTION_GET_MEALS_LOAD = 'ACTION_GET_MEALS_LOAD';
export const ACTION_GET_MEALS_SUCCESS = 'ACTION_GET_MEALS_SUCCESS';
export const ACTION_GET_MEALS_ERROR = 'ACTION_GET_MEALS_ERROR';

export const ACTION_GET_MEAL_DETAILS_REQUEST = 'ACTION_GET_MEAL_DETAILS_REQUEST';
export const ACTION_GET_MEAL_DETAILS_LOADING = 'ACTION_GET_MEAL_DETAILS_LOADING';
export const ACTION_GET_MEAL_DETAILS_SUCCESS = 'ACTION_GET_MEAL_DETAILS_SUCCESS';
export const ACTION_GET_MEAL_DETAILS_ERROR = 'ACTION_GET_MEAL_DETAILS_ERROR';
export const ACTION_CLEAR_MEAL_DETAILS_REQUEST = 'ACTION_CLEAR_MEAL_DETAILS_REQUEST';
export const ACTION_CLEAR_MEAL_DETAILS = 'ACTION_CLEAR_MEAL_DETAILS';

// Wizard
export const ACTION_WIZARD_GET_RESULTS_REQUEST = 'ACTION_WIZARD_GET_RESULTS_REQUEST';
export const ACTION_WIZARD_GET_RESULTS_LOAD = 'ACTION_WIZARD_GET_RESULTS_LOAD';
export const ACTION_WIZARD_GET_RESULTS_SUCCESS = 'ACTION_WIZARD_GET_RESULTS_SUCCESS';
export const ACTION_WIZARD_GET_RESULTS_ERROR = 'ACTION_WIZARD_GET_RESULTS_ERROR';

export const ACTION_WIZARD_GET_FOOD_PREFERENCES_REQUEST = 'ACTION_WIZARD_GET_FOOD_PREFERENCES_REQUEST';
export const ACTION_WIZARD_GET_FOOD_PREFERENCES_LOAD = 'ACTION_WIZARD_GET_FOOD_PREFERENCES_LOAD';
export const ACTION_WIZARD_GET_FOOD_PREFERENCES_SUCCESS = 'ACTION_WIZARD_GET_FOOD_PREFERENCES_SUCCESS';
export const ACTION_WIZARD_GET_FOOD_PREFERENCES_ERROR = 'ACTION_WIZARD_GET_FOOD_PREFERENCES_ERROR';

export const ACTION_WIZARD_GET_SUGGESTIONS_FROM_TARGETS_REQUEST = 'ACTION_WIZARD_GET_SUGGESTIONS_FROM_TARGETS_REQUEST';
export const ACTION_WIZARD_GET_SUGGESTIONS_FROM_TARGETS_LOAD = 'ACTION_WIZARD_GET_SUGGESTIONS_FROM_TARGETS_LOAD';
export const ACTION_WIZARD_GET_SUGGESTIONS_FROM_TARGETS_SUCCESS = 'ACTION_WIZARD_GET_SUGGESTIONS_FROM_TARGETS_SUCCESS';
export const ACTION_WIZARD_GET_SUGGESTIONS_FROM_TARGETS_ERROR = 'ACTION_WIZARD_GET_SUGGESTIONS_FROM_TARGETS_ERROR';

// Meal plans
export const ACTION_GET_MEAL_PLANS_REQUEST = 'ACTION_GET_MEAL_PLANS_REQUEST';
export const ACTION_GET_MEAL_PLANS_LOAD = 'ACTION_GET_MEAL_PLANS_LOAD';
export const ACTION_GET_MEAL_PLANS_SUCCESS = 'ACTION_GET_MEAL_PLANS_SUCCESS';
export const ACTION_GET_MEAL_PLANS_ERROR = 'ACTION_GET_MEAL_PLANS_ERROR';

export const ACTION_GET_ACTIVE_MEAL_PLAN_REQUEST = 'ACTION_GET_ACTIVE_MEAL_PLAN_REQUEST';
export const ACTION_GET_ACTIVE_MEAL_PLAN_LOAD = 'ACTION_GET_ACTIVE_MEAL_PLAN_LOAD';
export const ACTION_GET_ACTIVE_MEAL_PLAN_SUCCESS = 'ACTION_GET_ACTIVE_MEAL_PLAN_SUCCESS';
export const ACTION_GET_ACTIVE_MEAL_PLAN_ERROR = 'ACTION_GET_ACTIVE_MEAL_PLAN_ERROR';

export const ACTION_GET_MEAL_PLAN_REQUEST = 'ACTION_GET_MEAL_PLAN_REQUEST';
export const ACTION_GET_MEAL_PLAN_LOAD = 'ACTION_GET_MEAL_PLAN_LOAD';
export const ACTION_GET_MEAL_PLAN_SUCCESS = 'ACTION_GET_MEAL_PLAN_SUCCESS';
export const ACTION_GET_MEAL_PLAN_ERROR = 'ACTION_GET_MEAL_PLAN_ERROR';

export const ACTION_UPDATE_MEAL_PLAN_REQUEST = 'ACTION_UPDATE_MEAL_PLAN_REQUEST';
export const ACTION_UPDATE_MEAL_PLAN_LOAD = 'ACTION_UPDATE_MEAL_PLAN_LOAD';
export const ACTION_UPDATE_MEAL_PLAN_SUCCESS = 'ACTION_UPDATE_MEAL_PLAN_SUCCESS';
export const ACTION_UPDATE_MEAL_PLAN_ERROR = 'ACTION_UPDATE_MEAL_PLAN_ERROR';

export const ACTION_CREATE_MEAL_PLAN_REQUEST = 'ACTION_CREATE_MEAL_PLAN_REQUEST';
export const ACTION_CREATE_MEAL_PLAN_LOAD = 'ACTION_CREATE_MEAL_PLAN_LOAD';
export const ACTION_CREATE_MEAL_PLAN_SUCCESS = 'ACTION_CREATE_MEAL_PLAN_SUCCESS';
export const ACTION_CREATE_MEAL_PLAN_ERROR = 'ACTION_CREATE_MEAL_PLAN_ERROR';

export const ACTION_ADD_MEAL_TO_MEAL_PLAN_REQUEST = 'ACTION_ADD_MEAL_TO_MEAL_PLAN_REQUEST';
export const ACTION_ADD_MEAL_TO_MEAL_PLAN_LOAD = 'ACTION_ADD_MEAL_TO_MEAL_PLAN_LOAD';
export const ACTION_ADD_MEAL_TO_MEAL_PLAN_SUCCESS = 'ACTION_ADD_MEAL_TO_MEAL_PLAN_SUCCESS';
export const ACTION_ADD_MEAL_TO_MEAL_PLAN_ERROR = 'ACTION_ADD_MEAL_TO_MEAL_PLAN_ERROR';
export const ACTION_APPLY_KICK_STARTER_MEAL_PLAN_REQUEST = 'ACTION_APPLY_KICK_STARTER_MEAL_PLAN_REQUEST';

export const ACTION_REMOVE_MEAL_FROM_MEAL_PLAN_REQUEST = 'ACTION_REMOVE_MEAL_FROM_MEAL_PLAN_REQUEST';
export const ACTION_REMOVE_MEAL_FROM_MEAL_PLAN_LOAD = 'ACTION_REMOVE_MEAL_FROM_MEAL_PLAN_LOAD';
export const ACTION_REMOVE_MEAL_FROM_MEAL_PLAN_SUCCESS = 'ACTION_REMOVE_MEAL_FROM_MEAL_PLAN_SUCCESS';
export const ACTION_REMOVE_MEAL_FROM_MEAL_PLAN_ERROR = 'ACTION_REMOVE_MEAL_FROM_MEAL_PLAN_ERROR';

export const ACTION_ACTIVATE_MEAL_PLAN_REQUEST = 'ACTION_ACTIVATE_MEAL_PLAN_REQUEST';
export const ACTION_ACTIVATE_MEAL_PLAN_LOAD = 'ACTION_ACTIVATE_MEAL_PLAN_LOAD';
export const ACTION_ACTIVATE_MEAL_PLAN_SUCCESS = 'ACTION_ACTIVATE_MEAL_PLAN_SUCCESS';
export const ACTION_ACTIVATE_MEAL_PLAN_ERROR = 'ACTION_ACTIVATE_MEAL_PLAN_ERROR';

export const ACTION_SAVE_MEAL_PLAN_CHANGES_REQUEST = 'ACTION_SAVE_MEAL_PLAN_CHANGES_REQUEST';
export const ACTION_SAVE_MEAL_PLAN_CHANGES_LOAD = 'ACTION_SAVE_MEAL_PLAN_CHANGES_LOAD';
export const ACTION_SAVE_MEAL_PLAN_CHANGES_SUCCESS = 'ACTION_SAVE_MEAL_PLAN_CHANGES_SUCCESS';
export const ACTION_SAVE_MEAL_PLAN_CHANGES_ERROR = 'ACTION_SAVE_MEAL_PLAN_CHANGES_ERROR';

export const ACTION_DISCARD_MEAL_PLAN_CHANGES_REQUEST = 'ACTION_DISCARD_MEAL_PLAN_CHANGES_REQUEST';
export const ACTION_DISCARD_MEAL_PLAN_CHANGES_LOAD = 'ACTION_DISCARD_MEAL_PLAN_CHANGES_LOAD';
export const ACTION_DISCARD_MEAL_PLAN_CHANGES_SUCCESS = 'ACTION_DISCARD_MEAL_PLAN_CHANGES_SUCCESS';
export const ACTION_DISCARD_MEAL_PLAN_CHANGES_ERROR = 'ACTION_DISCARD_MEAL_PLAN_CHANGES_ERROR';
export const ACTION_DISCARD_DELIVERY_PLAN_CHANGES_REQUEST = 'ACTION_DISCARD_DELIVERY_PLAN_CHANGES_REQUEST';


// Macronutrients
export const ACTION_GET_MACRONUTRIENTS_REQUEST = 'ACTION_GET_MACRONUTRIENTS_REQUEST';
export const ACTION_GET_MACRONUTRIENTS_LOAD = 'ACTION_GET_MACRONUTRIENTS_LOAD';
export const ACTION_GET_MACRONUTRIENTS_SUCCESS = 'ACTION_GET_MACRONUTRIENTS_SUCCESS';
export const ACTION_GET_MACRONUTRIENTS_ERROR = 'ACTION_GET_MACRONUTRIENTS_ERROR';

// Targets
export const ACTION_GET_TARGETS_REQUEST = 'ACTION_GET_TARGETS_REQUEST';
export const ACTION_GET_TARGETS_LOAD = 'ACTION_GET_TARGETS_LOAD';
export const ACTION_GET_TARGETS_SUCCESS = 'ACTION_GET_TARGETS_SUCCESS';
export const ACTION_GET_TARGETS_ERROR = 'ACTION_GET_TARGETS_ERROR';

export const ACTION_SAVE_TARGETS_REQUEST = 'ACTION_SAVE_TARGETS_REQUEST';
export const ACTION_SAVE_TARGETS_LOAD = 'ACTION_SAVE_TARGETS_LOAD';
export const ACTION_SAVE_TARGETS_SUCCESS = 'ACTION_SAVE_TARGETS_SUCCESS';
export const ACTION_SAVE_TARGETS_ERROR = 'ACTION_SAVE_TARGETS_ERROR';
export const ACTION_SET_SAVE_TARGETS_SUCCESS = 'ACTION_SET_SAVE_TARGETS_SUCCESS'; 

// Deliveries
export const ACTION_GET_DELIVERY_TIMES_REQUEST = 'ACTION_GET_DELIVERY_TIMES_REQUEST';
export const ACTION_GET_DELIVERY_TIMES_LOAD = 'ACTION_GET_DELIVERY_TIMES_LOAD';
export const ACTION_GET_DELIVERY_TIMES_SUCCESS = 'ACTION_GET_DELIVERY_TIMES_SUCCESS';
export const ACTION_GET_DELIVERY_TIMES_ERROR = 'ACTION_GET_DELIVERY_TIMES_ERROR';

export const ACTION_GET_DELIVERY_PLANS_REQUEST = 'ACTION_GET_DELIVERY_PLANS_REQUEST';
export const ACTION_GET_DELIVERY_PLANS_LOAD = 'ACTION_GET_DELIVERY_PLANS_LOAD';
export const ACTION_GET_DELIVERY_PLANS_SUCCESS = 'ACTION_GET_DELIVERY_PLANS_SUCCESS';
export const ACTION_GET_DELIVERY_PLANS_ERROR = 'ACTION_GET_DELIVERY_PLANS_ERROR';

export const ACTION_GET_ACTIVE_DELIVERY_PLAN_REQUEST = 'ACTION_GET_ACTIVE_DELIVERY_PLAN_REQUEST';
export const ACTION_GET_ACTIVE_DELIVERY_PLAN_LOAD = 'ACTION_GET_ACTIVE_DELIVERY_PLAN_LOAD';
export const ACTION_GET_ACTIVE_DELIVERY_PLAN_SUCCESS = 'ACTION_GET_ACTIVE_DELIVERY_PLAN_SUCCESS';
export const ACTION_GET_ACTIVE_DELIVERY_PLAN_ERROR = 'ACTION_GET_ACTIVE_DELIVERY_PLAN_ERROR';

export const ACTION_GET_ACCOUNT_DELIVERIES_REQUEST = 'ACTION_GET_ACCOUNT_DELIVERIES_REQUEST';
export const ACTION_GET_ACCOUNT_DELIVERIES_LOAD = 'ACTION_GET_ACCOUNT_DELIVERIES_LOAD';
export const ACTION_GET_ACCOUNT_DELIVERIES_SUCCESS = 'ACTION_GET_ACCOUNT_DELIVERIES_SUCCESS';
export const ACTION_GET_ACCOUNT_DELIVERIES_ERROR = 'ACTION_GET_ACCOUNT_DELIVERIES_ERROR';

export const ACTION_GET_CALENDAR_DELIVERY_DATES_REQUEST = 'ACTION_GET_CALENDAR_DELIVERY_DATES_REQUEST';
export const ACTION_GET_CALENDAR_DELIVERY_DATES_LOAD = 'ACTION_GET_CALENDAR_DELIVERY_DATES_LOAD';
export const ACTION_GET_CALENDAR_DELIVERY_DATES_SUCCESS = 'ACTION_GET_CALENDAR_DELIVERY_DATES_SUCCESS';
export const ACTION_GET_CALENDAR_DELIVERY_DATES_ERROR = 'ACTION_GET_CALENDAR_DELIVERY_DATES_ERROR';

export const ACTION_UPDATE_DELIVERY_ADDRESS_REQUEST = 'ACTION_UPDATE_DELIVERY_ADDRESS_REQUEST';
export const ACTION_UPDATE_MULTIPLE_DELIVERY_ADDRESS_REQUEST = 'ACTION_UPDATE_MULTIPLE_DELIVERY_ADDRESS_REQUEST';
export const ACTION_SET_NEW_SELECTED_DELIVERY_TIME = 'ACTION_SET_NEW_SELECTED_DELIVERY_TIME';
export const ACTION_ADD_MEAL_TO_DELIVERY_REQUEST = 'ACTION_ADD_MEAL_TO_DELIVERY_REQUEST';
export const ACTION_REMOVE_MEAL_FROM_DELIVERY_REQUEST = 'ACTION_REMOVE_MEAL_FROM_DELIVERY_REQUEST';
export const ACTION_SAVE_DELIVERY_CHANGES_REQUEST = 'ACTION_SAVE_DELIVERY_CHANGES_REQUEST';
export const ACTION_GET_USER_CREDIT_REQUEST = 'ACTION_GET_USER_CREDIT_REQUEST';
export const ACTION_GET_USER_CREDIT_SUCCESS = 'ACTION_GET_USER_CREDIT_SUCCESS';

// Payment
export const ACTION_CREATE_INVOICE_REQUEST = 'ACTION_CREATE_INVOICE_REQUEST';
export const ACTION_CREATE_INVOICE_LOAD = 'ACTION_CREATE_INVOICE_LOAD';
export const ACTION_CREATE_INVOICE_SUCCESS = 'ACTION_CREATE_INVOICE_SUCCESS';
export const ACTION_CREATE_INVOICE_ERROR = 'ACTION_CREATE_INVOICE_ERROR';

export const ACTION_GET_INVOICES_REQUEST = 'ACTION_GET_INVOICES_REQUEST';
export const ACTION_GET_INVOICES_LOAD = 'ACTION_GET_INVOICES_LOAD';
export const ACTION_GET_INVOICES_SUCCESS = 'ACTION_GET_INVOICES_SUCCESS';
export const ACTION_GET_INVOICES_ERROR = 'ACTION_GET_INVOICES_ERROR';

export const ACTION_GET_PAYMENTS_REQUEST = 'ACTION_GET_PAYMENTS_REQUEST';
export const ACTION_GET_PAYMENTS_LOAD = 'ACTION_GET_PAYMENTS_LOAD';
export const ACTION_GET_PAYMENTS_SUCCESS = 'ACTION_GET_PAYMENTS_SUCCESS';
export const ACTION_GET_PAYMENTS_ERROR = 'ACTION_GET_PAYMENTS_ERROR';

// Subscriptions
export const ACTION_GET_SUBSCRIPTION_REQUEST = 'ACTION_GET_SUBSCRIPTION_REQUEST';
export const ACTION_GET_SUBSCRIPTION_LOAD = 'ACTION_GET_SUBSCRIPTION_LOAD';
export const ACTION_GET_SUBSCRIPTION_SUCCESS = 'ACTION_GET_SUBSCRIPTION_SUCCESS';
export const ACTION_GET_SUBSCRIPTION_ERROR = 'ACTION_GET_SUBSCRIPTION_ERROR';
export const ACTION_GET_ACTIVE_SUBSCRIPTION_REQUEST = 'ACTION_GET_ACTIVE_SUBSCRIPTION_REQUEST';
export const ACTION_GET_ACTIVE_SUBSCRIPTION_LOAD = 'ACTION_GET_ACTIVE_SUBSCRIPTION_LOAD';
export const ACTION_GET_ACTIVE_SUBSCRIPTION_SUCCESS = 'ACTION_GET_ACTIVE_SUBSCRIPTION_SUCCESS';
export const ACTION_GET_ACTIVE_SUBSCRIPTION_ERROR = 'ACTION_GET_ACTIVE_SUBSCRIPTION_ERROR';
export const ACTION_GET_CANCEL_SUBSCRIPTION_REQUEST = 'ACTION_GET_CANCEL_SUBSCRIPTION_REQUEST';
export const ACTION_GET_CANCEL_SUBSCRIPTION_LOAD = 'ACTION_GET_CANCEL_SUBSCRIPTION_LOAD';
export const ACTION_GET_CANCEL_SUBSCRIPTION_SUCCESS = 'ACTION_GET_CANCEL_SUBSCRIPTION_SUCCESS';
export const ACTION_GET_CANCEL_SUBSCRIPTION_ERROR = 'ACTION_GET_CANCEL_SUBSCRIPTION_ERROR';
export const ACTION_GET_SUBSCRIPTION_PAUSES_REQUEST = 'ACTION_GET_SUBSCRIPTION_PAUSES_REQUEST';
export const ACTION_GET_SUBSCRIPTION_PAUSES_LOAD = 'ACTION_GET_SUBSCRIPTION_PAUSES_LOAD';
export const ACTION_GET_SUBSCRIPTION_PAUSES_SUCCESS = 'ACTION_GET_SUBSCRIPTION_PAUSES_SUCCESS';
export const ACTION_GET_SUBSCRIPTION_PAUSES_ERROR = 'ACTION_GET_SUBSCRIPTION_PAUSES_ERROR';
export const ACTION_PAUSE_SUBSCRIPTION_REQUEST = 'ACTION_PAUSE_SUBSCRIPTION_REQUEST';
export const ACTION_PAUSE_SUBSCRIPTION_LOAD = 'ACTION_PAUSE_SUBSCRIPTION_LOAD';
export const ACTION_PAUSE_SUBSCRIPTION_SUCCESS = 'ACTION_PAUSE_SUBSCRIPTION_SUCCESS';
export const ACTION_PAUSE_SUBSCRIPTION_ERROR = 'ACTION_PAUSE_SUBSCRIPTION_ERROR';
export const ACTION_GET_RESUME_CHARGE_DATE_REQUEST = 'ACTION_GET_RESUME_CHARGE_DATE_REQUEST';
export const ACTION_GET_NEXT_CHARGE_DATE_REQUEST = 'ACTION_GET_NEXT_CHARGE_DATE_REQUEST';
export const ACTION_RESUME_SUBSCRIPTION_REQUEST = 'ACTION_RESUME_SUBSCRIPTION_REQUEST';
export const ACTION_RESUME_SUBSCRIPTION_LOAD = 'ACTION_RESUME_SUBSCRIPTION_LOAD';
export const ACTION_RESUME_SUBSCRIPTION_SUCCESS = 'ACTION_RESUME_SUBSCRIPTION_SUCCESS';
export const ACTION_RESUME_SUBSCRIPTION_ERROR = 'ACTION_RESUME_SUBSCRIPTION_ERROR';


// Meal tags
export const ACTION_GET_MEALS_TAGS_REQUEST = 'ACTION_GET_MEALS_TAGS_REQUEST';
export const ACTION_GET_MEALS_TAGS_SUCCESS = 'ACTION_GET_MEALS_TAGS_SUCCESS';

// Settings Coupons
export const ACTION_GET_USER_COUPONS_REQUEST = 'ACTION_GET_USER_COUPONS_REQUEST';
export const ACTION_GET_USER_COUPONS_SUCCESS = 'ACTION_GET_USER_COUPONS_SUCCESS';
export const ACTION_GET_USER_COUPONS_ERROR = 'ACTION_GET_USER_COUPONS_ERROR';

export const ACTION_POST_USER_COUPONS_REQUEST = 'ACTION_POST_USER_COUPONS_REQUEST';
export const ACTION_POST_USER_COUPONS_SUCCESS = 'ACTION_POST_USER_COUPONS_SUCCESS';
export const ACTION_POST_USER_COUPONS_ERROR = 'ACTION_POST_USER_COUPONS_ERROR';
export const ACTION_SET_USER_COUPONS_APPLY_SUCCESS = 'ACTION_SET_USER_COUPONS_APPLY_SUCCESS';
export const ACTION_REMOVE_USER_COUPONS_REQUEST = 'ACTION_REMOVE_USER_COUPONS_REQUEST';
export const ACTION_REMOVE_USER_COUPONS_SUCCESS = 'ACTION_REMOVE_USER_COUPONS_SUCCESS';
export const ACTION_REMOVE_USER_COUPONS_ERROR = 'ACTION_REMOVE_USER_COUPONS_ERROR';
export const ACTION_SET_USER_COUPONS_REMOVE_SUCCESS = 'ACTION_SET_USER_COUPONS_REMOVE_SUCCESS';

// Locale
export const ACTION_GET_COUNTRIES_REQUEST = 'ACTION_GET_COUNTRIES_REQUEST';
export const ACTION_GET_COUNTRIES_LOAD = 'ACTION_GET_COUNTRIES_LOAD';
export const ACTION_GET_COUNTRIES_SUCCESS = 'ACTION_GET_COUNTRIES_SUCCESS';
export const ACTION_GET_COUNTRIES_ERROR = 'ACTION_GET_COUNTRIES_ERROR';
export const ACTION_GET_REGIONS_REQUEST = 'ACTION_GET_REGIONS_REQUEST';
export const ACTION_GET_REGIONS_LOAD = 'ACTION_GET_REGIONS_LOAD';
export const ACTION_GET_REGIONS_SUCCESS = 'ACTION_GET_REGIONS_SUCCESS';
export const ACTION_GET_REGIONS_ERROR = 'ACTION_GET_REGIONS_ERROR';
export const ACTION_GET_LANGUAGES_REQUEST = 'ACTION_GET_LANGUAGES_REQUEST';
export const ACTION_GET_LANGUAGES_LOAD = 'ACTION_GET_LANGUAGES_LOAD';
export const ACTION_GET_LANGUAGES_SUCCESS = 'ACTION_GET_LANGUAGES_SUCCESS';
export const ACTION_GET_LANGUAGES_ERROR = 'ACTION_GET_LANGUAGES_ERROR';

// Settigs Loyalty
export const ACTION_GET_LOYALTY_REDEMPTIONS_REQUEST = 'ACTION_GET_LOYALTY_REDEMPTIONS_REQUEST';
export const ACTION_GET_LOYALTY_REDEMPTIONS_SUCCESS = 'ACTION_GET_LOYALTY_REDEMPTIONS_SUCCESS';
export const ACTION_GET_LOYALTY_REDEMPTIONS_ERROR = 'ACTION_GET_LOYALTY_REDEMPTIONS_ERROR';

export const ACTION_GET_LOYALTY_WALLET_REQUEST = 'ACTION_GET_LOYALTY_WALLET_REQUEST';
export const ACTION_GET_LOYALTY_WALLET_SUCCESS = 'ACTION_GET_LOYALTY_WALLET_SUCCESS';
export const ACTION_GET_LOYALTY_WALLET_ERROR = 'ACTION_GET_LOYALTY_WALLET_ERROR';

// Main Protiens Meals
export const ACTION_GET_MAIN_PROTIENS_OPTIONS_REQUEST = 'ACTION_GET_MAIN_PROTIENS_OPTIONS_REQUEST';
export const ACTION_GET_MAIN_PROTIENS_OPTIONS_SUCCESS = 'ACTION_GET_MAIN_PROTIENS_OPTIONS_SUCCESS';

// Orders
export const ACTION_GET_ORDERS_REQUEST = 'ACTION_GET_ORDERS_REQUEST';
export const ACTION_GET_ORDERS_LOAD = 'ACTION_GET_ORDERS_LOAD';
export const ACTION_GET_ORDERS_SUCCESS = 'ACTION_GET_ORDERS_SUCCESS';
export const ACTION_GET_ORDERS_ERROR = 'ACTION_GET_ORDERS_ERROR';

export const ACTION_GET_ORDER_DETAILS_REQUEST = 'ACTION_GET_ORDER_DETAILS_REQUEST';
export const ACTION_GET_ORDER_DETAILS_LOAD = 'ACTION_GET_ORDER_DETAILS_LOAD';
export const ACTION_GET_ORDER_DETAILS_SUCCESS = 'ACTION_GET_ORDER_DETAILS_SUCCESS';
export const ACTION_GET_ORDER_DETAILS_ERROR = 'ACTION_GET_ORDER_DETAILS_ERROR';

type ActionUnionType = typeof ACTION_INIT | typeof ACTION_LOGIN_REQUEST
| typeof ACTION_LOGIN_SUCCESS
| typeof ACTION_LOGIN_ERROR
| typeof ACTION_LOGOUT_REQUEST
| typeof ACTION_LOGOUT_SUCCESS
| typeof ACTION_LOGOUT_ERROR
| typeof ACTION_GET_USER_ACCOUNT_REQUEST
| typeof ACTION_GET_USER_ACCOUNT_SUCCESS
| typeof ACTION_UPDATE_USER_ACCOUNT_REGION_REQUEST
| typeof ACTION_UPDATE_USER_ACCOUNT_REGION_SUCCESS
| typeof ACTION_UPDATE_USER_PROFILE_REQUEST
| typeof ACTION_UPDATE_USER_PROFILE_LOAD
| typeof ACTION_UPDATE_USER_PROFILE_SUCCESS
| typeof ACTION_UPDATE_USER_PROFILE_ERROR
| typeof ACTION_GET_MEALS_REQUEST
| typeof ACTION_GET_MEALS_LOAD
| typeof ACTION_GET_MEALS_SUCCESS
| typeof ACTION_GET_MEALS_ERROR
| typeof ACTION_GET_MEAL_DETAILS_REQUEST
| typeof ACTION_GET_MEAL_DETAILS_LOADING
| typeof ACTION_GET_MEAL_DETAILS_SUCCESS
| typeof ACTION_GET_MEAL_DETAILS_ERROR
| typeof ACTION_CLEAR_MEAL_DETAILS_REQUEST
| typeof ACTION_CLEAR_MEAL_DETAILS
| typeof ACTION_WIZARD_GET_RESULTS_REQUEST
| typeof ACTION_WIZARD_GET_RESULTS_LOAD
| typeof ACTION_WIZARD_GET_RESULTS_SUCCESS
| typeof ACTION_WIZARD_GET_RESULTS_ERROR
| typeof ACTION_WIZARD_GET_FOOD_PREFERENCES_REQUEST
| typeof ACTION_WIZARD_GET_FOOD_PREFERENCES_LOAD
| typeof ACTION_WIZARD_GET_FOOD_PREFERENCES_SUCCESS
| typeof ACTION_WIZARD_GET_FOOD_PREFERENCES_ERROR
| typeof ACTION_WIZARD_GET_SUGGESTIONS_FROM_TARGETS_REQUEST
| typeof ACTION_WIZARD_GET_SUGGESTIONS_FROM_TARGETS_LOAD
| typeof ACTION_WIZARD_GET_SUGGESTIONS_FROM_TARGETS_SUCCESS
| typeof ACTION_WIZARD_GET_SUGGESTIONS_FROM_TARGETS_ERROR
| typeof ACTION_GET_MEAL_PLANS_REQUEST
| typeof ACTION_GET_MEAL_PLANS_LOAD
| typeof ACTION_GET_MEAL_PLANS_SUCCESS
| typeof ACTION_GET_MEAL_PLANS_ERROR
| typeof ACTION_GET_ACTIVE_MEAL_PLAN_REQUEST
| typeof ACTION_GET_ACTIVE_MEAL_PLAN_LOAD
| typeof ACTION_GET_ACTIVE_MEAL_PLAN_SUCCESS
| typeof ACTION_GET_ACTIVE_MEAL_PLAN_ERROR
| typeof ACTION_GET_MEAL_PLAN_REQUEST
| typeof ACTION_GET_MEAL_PLAN_LOAD
| typeof ACTION_GET_MEAL_PLAN_SUCCESS
| typeof ACTION_GET_MEAL_PLAN_ERROR
| typeof ACTION_UPDATE_MEAL_PLAN_REQUEST
| typeof ACTION_UPDATE_MEAL_PLAN_LOAD
| typeof ACTION_UPDATE_MEAL_PLAN_SUCCESS
| typeof ACTION_UPDATE_MEAL_PLAN_ERROR
| typeof ACTION_CREATE_MEAL_PLAN_REQUEST
| typeof ACTION_CREATE_MEAL_PLAN_LOAD
| typeof ACTION_CREATE_MEAL_PLAN_SUCCESS
| typeof ACTION_CREATE_MEAL_PLAN_ERROR
| typeof ACTION_ADD_MEAL_TO_MEAL_PLAN_REQUEST
| typeof ACTION_ADD_MEAL_TO_MEAL_PLAN_LOAD
| typeof ACTION_ADD_MEAL_TO_MEAL_PLAN_SUCCESS
| typeof ACTION_ADD_MEAL_TO_MEAL_PLAN_ERROR
| typeof ACTION_APPLY_KICK_STARTER_MEAL_PLAN_REQUEST
| typeof ACTION_REMOVE_MEAL_FROM_MEAL_PLAN_REQUEST
| typeof ACTION_REMOVE_MEAL_FROM_MEAL_PLAN_LOAD
| typeof ACTION_REMOVE_MEAL_FROM_MEAL_PLAN_SUCCESS
| typeof ACTION_REMOVE_MEAL_FROM_MEAL_PLAN_ERROR
| typeof ACTION_ACTIVATE_MEAL_PLAN_REQUEST
| typeof ACTION_ACTIVATE_MEAL_PLAN_LOAD
| typeof ACTION_ACTIVATE_MEAL_PLAN_SUCCESS
| typeof ACTION_ACTIVATE_MEAL_PLAN_ERROR
| typeof ACTION_SAVE_MEAL_PLAN_CHANGES_REQUEST
| typeof ACTION_SAVE_MEAL_PLAN_CHANGES_LOAD
| typeof ACTION_SAVE_MEAL_PLAN_CHANGES_SUCCESS
| typeof ACTION_SAVE_MEAL_PLAN_CHANGES_ERROR
| typeof ACTION_DISCARD_MEAL_PLAN_CHANGES_REQUEST
| typeof ACTION_DISCARD_MEAL_PLAN_CHANGES_LOAD
| typeof ACTION_DISCARD_MEAL_PLAN_CHANGES_SUCCESS
| typeof ACTION_DISCARD_MEAL_PLAN_CHANGES_ERROR
| typeof ACTION_DISCARD_DELIVERY_PLAN_CHANGES_REQUEST
| typeof ACTION_GET_MACRONUTRIENTS_REQUEST
| typeof ACTION_GET_MACRONUTRIENTS_LOAD
| typeof ACTION_GET_MACRONUTRIENTS_SUCCESS
| typeof ACTION_GET_MACRONUTRIENTS_ERROR
| typeof ACTION_GET_TARGETS_REQUEST
| typeof ACTION_GET_TARGETS_LOAD
| typeof ACTION_GET_TARGETS_SUCCESS
| typeof ACTION_GET_TARGETS_ERROR
| typeof ACTION_SAVE_TARGETS_REQUEST
| typeof ACTION_SAVE_TARGETS_LOAD
| typeof ACTION_SAVE_TARGETS_SUCCESS
| typeof ACTION_SAVE_TARGETS_ERROR
| typeof ACTION_SET_SAVE_TARGETS_SUCCESS
| typeof ACTION_GET_DELIVERY_TIMES_REQUEST
| typeof ACTION_GET_DELIVERY_TIMES_LOAD
| typeof ACTION_GET_DELIVERY_TIMES_SUCCESS
| typeof ACTION_GET_DELIVERY_TIMES_ERROR
| typeof ACTION_GET_DELIVERY_PLANS_REQUEST
| typeof ACTION_GET_DELIVERY_PLANS_LOAD
| typeof ACTION_GET_DELIVERY_PLANS_SUCCESS
| typeof ACTION_GET_DELIVERY_PLANS_ERROR
| typeof ACTION_GET_ACCOUNT_DELIVERIES_REQUEST
| typeof ACTION_GET_ACCOUNT_DELIVERIES_LOAD
| typeof ACTION_GET_ACCOUNT_DELIVERIES_SUCCESS
| typeof ACTION_GET_ACCOUNT_DELIVERIES_ERROR 
| typeof ACTION_GET_CALENDAR_DELIVERY_DATES_REQUEST
| typeof ACTION_GET_CALENDAR_DELIVERY_DATES_LOAD
| typeof ACTION_GET_CALENDAR_DELIVERY_DATES_SUCCESS
| typeof ACTION_GET_CALENDAR_DELIVERY_DATES_ERROR
| typeof ACTION_GET_ACTIVE_DELIVERY_PLAN_REQUEST
| typeof ACTION_GET_ACTIVE_DELIVERY_PLAN_LOAD
| typeof ACTION_GET_ACTIVE_DELIVERY_PLAN_SUCCESS
| typeof ACTION_GET_ACTIVE_DELIVERY_PLAN_ERROR
| typeof ACTION_UPDATE_DELIVERY_ADDRESS_REQUEST
| typeof ACTION_UPDATE_MULTIPLE_DELIVERY_ADDRESS_REQUEST
| typeof ACTION_SET_NEW_SELECTED_DELIVERY_TIME
| typeof ACTION_ADD_MEAL_TO_DELIVERY_REQUEST
| typeof ACTION_SAVE_DELIVERY_CHANGES_REQUEST
| typeof ACTION_GET_USER_CREDIT_REQUEST
| typeof ACTION_GET_USER_CREDIT_SUCCESS
| typeof ACTION_CREATE_INVOICE_REQUEST
| typeof ACTION_CREATE_INVOICE_LOAD
| typeof ACTION_CREATE_INVOICE_SUCCESS
| typeof ACTION_CREATE_INVOICE_ERROR
| typeof ACTION_GET_INVOICES_REQUEST
| typeof ACTION_GET_INVOICES_LOAD
| typeof ACTION_GET_INVOICES_SUCCESS
| typeof ACTION_GET_INVOICES_ERROR
| typeof ACTION_GET_PAYMENTS_REQUEST
| typeof ACTION_GET_PAYMENTS_LOAD
| typeof ACTION_GET_PAYMENTS_SUCCESS
| typeof ACTION_GET_PAYMENTS_ERROR
| typeof ACTION_GET_SUBSCRIPTION_REQUEST
| typeof ACTION_GET_SUBSCRIPTION_LOAD
| typeof ACTION_GET_SUBSCRIPTION_SUCCESS
| typeof ACTION_GET_SUBSCRIPTION_ERROR
| typeof ACTION_GET_ACTIVE_SUBSCRIPTION_REQUEST
| typeof ACTION_GET_ACTIVE_SUBSCRIPTION_LOAD
| typeof ACTION_GET_ACTIVE_SUBSCRIPTION_SUCCESS
| typeof ACTION_GET_ACTIVE_SUBSCRIPTION_ERROR
| typeof ACTION_GET_CANCEL_SUBSCRIPTION_REQUEST
| typeof ACTION_GET_CANCEL_SUBSCRIPTION_LOAD
| typeof ACTION_GET_CANCEL_SUBSCRIPTION_SUCCESS
| typeof ACTION_GET_CANCEL_SUBSCRIPTION_ERROR
| typeof ACTION_GET_SUBSCRIPTION_PAUSES_REQUEST
| typeof ACTION_GET_SUBSCRIPTION_PAUSES_LOAD
| typeof ACTION_GET_SUBSCRIPTION_PAUSES_SUCCESS
| typeof ACTION_GET_SUBSCRIPTION_PAUSES_ERROR
| typeof ACTION_PAUSE_SUBSCRIPTION_REQUEST
| typeof ACTION_PAUSE_SUBSCRIPTION_LOAD
| typeof ACTION_PAUSE_SUBSCRIPTION_SUCCESS
| typeof ACTION_PAUSE_SUBSCRIPTION_ERROR
| typeof ACTION_GET_RESUME_CHARGE_DATE_REQUEST
| typeof ACTION_GET_NEXT_CHARGE_DATE_REQUEST
| typeof ACTION_RESUME_SUBSCRIPTION_REQUEST
| typeof ACTION_RESUME_SUBSCRIPTION_LOAD
| typeof ACTION_RESUME_SUBSCRIPTION_SUCCESS
| typeof ACTION_RESUME_SUBSCRIPTION_ERROR
| typeof ACTION_GET_MEALS_TAGS_REQUEST
| typeof ACTION_GET_MEALS_TAGS_SUCCESS
| typeof ACTION_GET_USER_COUPONS_REQUEST
| typeof ACTION_GET_USER_COUPONS_SUCCESS
| typeof ACTION_GET_USER_COUPONS_ERROR
| typeof ACTION_POST_USER_COUPONS_REQUEST
| typeof ACTION_POST_USER_COUPONS_SUCCESS
| typeof ACTION_POST_USER_COUPONS_ERROR
| typeof ACTION_REMOVE_USER_COUPONS_REQUEST
| typeof ACTION_REMOVE_USER_COUPONS_SUCCESS
| typeof ACTION_REMOVE_USER_COUPONS_ERROR
| typeof ACTION_SET_USER_COUPONS_REMOVE_SUCCESS
| typeof ACTION_SET_USER_COUPONS_APPLY_SUCCESS
| typeof ACTION_GET_COUNTRIES_REQUEST
| typeof ACTION_GET_COUNTRIES_LOAD
| typeof ACTION_GET_COUNTRIES_SUCCESS
| typeof ACTION_GET_COUNTRIES_ERROR
| typeof ACTION_GET_REGIONS_REQUEST
| typeof ACTION_GET_REGIONS_LOAD
| typeof ACTION_GET_REGIONS_SUCCESS
| typeof ACTION_GET_REGIONS_ERROR
| typeof ACTION_GET_LANGUAGES_REQUEST
| typeof ACTION_GET_LANGUAGES_LOAD
| typeof ACTION_GET_LANGUAGES_SUCCESS
| typeof ACTION_GET_LANGUAGES_ERROR
| typeof ACTION_GET_LOYALTY_REDEMPTIONS_REQUEST
| typeof ACTION_GET_LOYALTY_REDEMPTIONS_SUCCESS
| typeof ACTION_GET_LOYALTY_REDEMPTIONS_ERROR
| typeof ACTION_GET_LOYALTY_WALLET_REQUEST
| typeof ACTION_GET_LOYALTY_WALLET_SUCCESS
| typeof ACTION_GET_LOYALTY_WALLET_ERROR
| typeof ACTION_GET_MAIN_PROTIENS_OPTIONS_REQUEST
| typeof ACTION_GET_MAIN_PROTIENS_OPTIONS_SUCCESS
| typeof ACTION_GET_ORDERS_REQUEST
| typeof ACTION_GET_ORDERS_LOAD
| typeof ACTION_GET_ORDERS_SUCCESS
| typeof ACTION_GET_ORDERS_ERROR
| typeof ACTION_GET_ORDER_DETAILS_REQUEST
| typeof ACTION_GET_ORDER_DETAILS_LOAD
| typeof ACTION_GET_ORDER_DETAILS_SUCCESS
| typeof ACTION_GET_ORDER_DETAILS_ERROR;

export type ActionType<G> = {
    type: ActionUnionType;
    payload: G;
}
