import {
  ACTION_GET_TARGETS_LOAD,
  ACTION_GET_TARGETS_SUCCESS,
  ACTION_GET_TARGETS_ERROR,
  ACTION_SAVE_TARGETS_LOAD,
  ACTION_SAVE_TARGETS_SUCCESS,
  ACTION_SAVE_TARGETS_ERROR,
  ActionType,
  ACTION_SET_SAVE_TARGETS_SUCCESS,
} from '../action-types';

import { ITargetsState } from 'src/types/state';

const initialState: ITargetsState = {
    loading: false,
    error: null,
    data: null,
    loadingSaveTargets: false,
    successSaveTargets: false,
    errorSaveTargets: null,
};

export const targets = (state = initialState, { type, payload }: ActionType<any>): ITargetsState => {
  switch (type) {
      case ACTION_GET_TARGETS_LOAD:
          return {
              ...state,
              loading: true,
              loadingSaveTargets: false,
              successSaveTargets: false,
          };
      case ACTION_GET_TARGETS_SUCCESS:
          return {
            ...state,
            loading: false,
            data: Array.isArray(payload) && payload.length === 0 ? null : payload,
          };
      case ACTION_GET_TARGETS_ERROR:
          return {
            ...state,
            loading: false,
            data: undefined,
            error: payload,
          };
      case ACTION_SAVE_TARGETS_LOAD:
        return {
          ...state,
          successSaveTargets: false,
          loadingSaveTargets: true,
        };
      case ACTION_SAVE_TARGETS_SUCCESS:
        return {
          ...state,
          loadingSaveTargets: false,
          successSaveTargets: true,
          errorSaveTargets: null,
          data: payload
        };
      case ACTION_SAVE_TARGETS_ERROR:
        return {
          ...state,
          loadingSaveTargets: false,
          errorSaveTargets: payload,
        };
      case ACTION_SET_SAVE_TARGETS_SUCCESS:
        return {
          ...state,
          successSaveTargets: payload,
        }
      default:
          return state;
  }
};
