import React from 'react';
import { ColumnsType } from 'antd/es/table';

interface Coupon {
    coupon: string,
    amount: string,
    status: string,
    uses: string,
}

interface TableDataType {
    columns: ColumnsType<Coupon>,
    dataSource: Coupon[],
}

export const tableData: TableDataType = {
    columns: [
        { key: '1', title: 'Coupon', dataIndex: 'coupon', fixed: false },
        { key: '2', title: 'Amount', dataIndex: 'amount', fixed: false },
        {
            key:       '3',
            title:     'Status',
            dataIndex: 'status',
            fixed:     false,
            render:    (text) => (
                <div style = {{
                    width:           'fit-content',
                    padding:         '8px 16px',
                    border:          '1px dashed #EF1733',
                    backgroundColor: '#FFEBEB',
                    borderRadius:    '10px',
                    color:           '#EF1733',
                    textTransform:   'capitalize',
                }}>
                    { text }
                </div>
            ),
        },
        { key: '4', title: 'Uses', dataIndex: 'uses', fixed: false },
        { key: '5', title: 'Remove', dataIndex: 'remove', fixed: false },
    ],
    dataSource: [
        {
            coupon: '2hksabf',
            amount: '200 AED',
            status: 'Pending Application',
            uses:   '1',
        },
        {
            coupon: '2hksabf',
            amount: '200 AED',
            status: 'Pending Application',
            uses:   '1',
        },
        {
            coupon: '2hksabf',
            amount: '200 AED',
            status: 'Pending Application',
            uses:   '1',
        },
    ],
};
