import logger from "src/managers/logger.manager";

// Sets item in local storage
export const setItemInLocalStorage = (key: string, value: string): void => {
    try {
        localStorage.setItem(key, value);
    } catch (error) {
        logger.error('ERROR: Unable to set item in local storage', error);
    }
};

// Gets item from local storage
export const getItemFromLocalStorage = (key: string): string | null => {
    try {
        return localStorage.getItem(key);
    } catch (error) {
        logger.error('ERROR: Unable to get item from local storage', error);

        return '';
    }
};

export const removeItemFromLocalStorage = (key: string) => {
    try {
        localStorage.removeItem(key);
    } catch (error) {
        logger.error('ERROR: Unable to remove item from local storage', error);
    }
};

// Group an array by key
// Takes an array and key, returns items that are grouped by the key
export const groupBy = <TItem>(xs: TItem[], key: string): {[key: string]: TItem[]} => {
    return xs.reduce(function(rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);

        return rv;
    }, {});
};

export const getEatrightURL = (path: string) => {
    if (document.location.host.startsWith('localhost')) {
        return 'http://localhost:3000' + path;
    }

    return (
        document.location.protocol + document.location.host + path
    );
};

export const getIdentityURL = (path: string) => {
    if (document.location.host.startsWith('localhost')) {
        return 'http://localhost:5111' + path;
    }
    // if (document.location.host.includes('local')) {
    //     return 'https://bs-local.com:5111';
    // }

    return (
        document.location.protocol + '//identity.' + document.location.host + path
    );
};

export const getPaymentsURL = (path: string) => {
    if (document.location.host.startsWith('localhost')) {
        return 'http://localhost:5111' + path;
    }
    // if (document.location.host.includes('local')) {
    //     return 'https://bs-local.com:5111';
    // }

    return (
        document.location.protocol + '//payments.' + document.location.host + path
    );
};

export const getInvoicesURL = (path: string) => {
    if (document.location.host.startsWith('localhost')) {
        return 'http://localhost:5111' + path;
    }
    // if (document.location.host.includes('local')) {
    //     return 'https://bs-local.com:5111';
    // }

    return (
        document.location.protocol + '//invoices.' + document.location.host + path
    );
};

// checks if a string is a valid http/https URL
export const isValidHttpUrl = (string: string): boolean => {
    let url;
    
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
  
    return url.protocol === "http:" || url.protocol === "https:";
  }


export function omitEmpty(obj: Record<string, any>): Record<string, any> {
    const copy: Record<string, any> = {};

    Object.keys(obj).forEach((key) => {
        // eslint-disable-next-line no-undefined
        if (obj[key] !== undefined && obj[key] !== null) {
            if (typeof obj[key] === 'string') {
                if (obj[key]) {
                    copy[key] = obj[key];
                }
            } else {
                copy[key] = obj[key];
            }
        }
    });

    return copy;
}

