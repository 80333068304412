import { useEffect } from 'react';
import { useLocation } from 'react-router';

const useTracking = () => {
    const _hsq = (window as any)._hsq = (window as any)._hsq || [];
    const location = useLocation();

    useEffect(() => {
        if (location.pathname) {
            _hsq.push(['setPath', location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }, [location.pathname])
}

export default useTracking;