import styled from 'styled-components';

export default styled.div`
  .table {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    table {
      border: 0;
    }

    thead {
      .ant-table-cell {
        padding: 20px;
        background-color: #90BF45;
        border: 0;
        color: white;
      }
    }
    tbody {
      tr {
        td {
          padding: 20px;
          border-bottom: 0;
          color: #2E2F31 !important;
        }
      }
    }

    .ant-table-footer {
      padding: 30px 15px;
    }
    .table-footer-content {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;

      .purchase-more-btn {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 8px 15px;
        color: #FF6B2C  ;
      }

      .ant-pagination {
        background-color: transparent;
        padding: 0;
      }
    }
  }
`;
