// Core
import React, { FC, useMemo, useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation, useRouteMatch } from 'react-router';
import clsx from 'clsx';
import { RootStateOrAny, useSelector } from 'react-redux';

// Elements
import { HeaderMobileSidebar, RegionTooltip } from './components';
import { BaseButton } from 'src/components/BaseButton';
import { ReactComponent as CartIcon } from 'src/assets/media/icons/cart.svg';
import { ReactComponent as ERWhiteLogoSvg } from 'src/assets/media/trainx-white-logo.svg';
import { ReactComponent as BurgerNavIconSvg } from 'src/assets/media/icons/navigation/burger-nav.svg';
import { ReactComponent as BurgerNavWhiteIconSvg } from 'src/assets/media/icons/navigation/burger-nav-white.svg';
import { ReactComponent as GlobeIcon } from 'src/assets/media/icons/navigation/globe.svg';
import LogoImg from 'src/assets/media/trainx-logo.svg';

/// Tools
import { useClickOutside } from 'src/hooks/useClickOutside';
import { getIdentityURL, getItemFromLocalStorage } from 'src/utils';
import { getIsLinkActive } from './tools';
import { ROUTE_CONSTANTS, SHOW_REGION_PROMPT } from 'src/init/constants';
import { headerConfig } from 'src/init/config';
import { EventBus } from 'src/managers';
import useCMSPages from 'src/hooks/api/cms/useCMSPages';
import { getCMSHeaderConfig } from 'src/utils/api/cms-pages';
import { IRootState } from 'src/types/state';
import { useIsMobile } from 'src/hooks/useIsMobile';

// Styles
import styles from './header.module.sass';
import useGetUserRegion from 'src/hooks/api/deliveries/useGetUserRegion';

type HeaderType = FC<{ className?: string }>;

export const Header: HeaderType = ({ className }) => {
    const [ isRegionTooltipOpened, setIsRegionTooltipOpened ] = useState(false);
    const [ isMobileNavOpened, setIsMobileNavOpened ] = useState(false);


    const { pathname } = useLocation();
    const isMobile = useIsMobile();
    const globeButtonRef = useRef<null | HTMLDivElement>(null);
    const isOnMealsPage = useRouteMatch(ROUTE_CONSTANTS.MEALS);


    const region = useGetUserRegion();
    const regionList = useSelector((state: IRootState) => state.locale?.regionList?.data);
    const mealPlan = useSelector((state: IRootState) => state.mealPlans.activeMealPlan.data);
    const isAuthenticated = useSelector(
        (state: RootStateOrAny) => state.auth.isAuthenticated,
    );

    const isMealsPage = pathname === ROUTE_CONSTANTS.MEALS;
    
    const regionId = useMemo(() => regionList?.find((item) => item.name === region)?.id, [ region, regionList, isAuthenticated ])

    const { CMSPages } = useCMSPages(regionId, 'en');
    const CMSHeaderConfig = useMemo(() => 
        CMSPages?.length ? getCMSHeaderConfig(CMSPages)?.filter(({ name }) => !['About', 'Contacts', 'FAQ'].includes(name)) : [], [CMSPages])
    
    const [navbarHeight, setNavbarHeight] = useState(0)
    const navbar = useRef(null)

    useEffect(() => {
        setNavbarHeight((navbar.current as any)?.clientHeight || 0)
    })

    const toggleMobileNavOpened = () => setIsMobileNavOpened(!isMobileNavOpened);
    const redirectToAuth = () => {
        if (!isAuthenticated) {
            let url = `/login${getItemFromLocalStorage('eatright-ref') ? `?ref=${getItemFromLocalStorage('eatright-ref')}` : ''}${
                (getItemFromLocalStorage('eatright-ref') && getItemFromLocalStorage('eatright-mbsy-source')) ? '&' : getItemFromLocalStorage('eatright-mbsy-source') ? '?' : ''}${
                getItemFromLocalStorage('eatright-mbsy-source') ? `mbsy_source=${getItemFromLocalStorage('eatright-mbsy-source')}&campaignid=${
                getItemFromLocalStorage('eatright-mbsy-campaignid')}&mbsy=${getItemFromLocalStorage('eatright-mbsy')}&mbsy_exp=${getItemFromLocalStorage('eatright-mbsy-exp')}` : ''}`;
            location.href = getIdentityURL(url);
        }
    };

    useClickOutside(globeButtonRef, () => {
        setIsRegionTooltipOpened(false);
    });
    
    const standardHeader = (
        <div className = 'common-section relative'>
            <header
                className = { clsx(
                    className,
                    'common-container flex justify-between items-center md:items-start md:items-center py-5',
                    { [styles.mealsHeaderMenu]: isMealsPage },
                ) }>
                <div className = 'flex items-center'>
                    <Link
                        style = {{ width: '89px', height: '51px' }}
                        to = { ROUTE_CONSTANTS.HOME }>
                        {isMealsPage ? <ERWhiteLogoSvg className = { styles.whiteLogo } /> : (
                            <img
                                alt = 'Eat right logo'
                                className = 'w-full h-full'
                                src = { LogoImg }
                            />
                        )}
                    </Link>
                    {/* // remove hover on mobile cause it is causing problems on IPhones */}
                    <div
                        className = 'relative block md:hidden'
                        ref = { globeButtonRef }>
                        <button
                            className = { clsx('flex align-center md:self-start ml-3 md:ml-5', {
                                'opacity-50': isOnMealsPage && !isAuthenticated,
                            }) }
                            onClick = { () => {
                                EventBus.emit(SHOW_REGION_PROMPT);
                                isMobile && setIsRegionTooltipOpened(true);
                            } }>
                            <GlobeIcon />
                        </button>
                        <RegionTooltip className = { clsx({ block: isRegionTooltipOpened }) } />
                    </div>
                </div>
                <nav className = 'flex md:justify-between lg:justify-end' ref = {navbar}>
                    <div className = 'flex justify-end items-center'>
                        <div className = 'hidden md:flex md:flex-wrap md:ml-12'>
                            {/* display CMS homepage first */}
                            {CMSHeaderConfig?.filter(({ name, path }) => path === '/home').map(({ name, path }) => path ? (
                                <Link
                                    className = { clsx(styles.link, {
                                        [styles['link--active']]:
                                                getIsLinkActive(pathname, path),
                                    }) }
                                    style = {{ marginTop:  navbarHeight > 40 ? '10px' : '0' }}
                                    key = { name?.slice(0, 5) }
                                    to = { path }>
                                    {name}
                                </Link>
                            ) : (
                                <span
                                    className = { clsx(
                                        styles.link,
                                        'cursor-pointer',
                                        {
                                            [styles['link--active']]:
                                                    getIsLinkActive(
                                                        pathname,
                                                        path,
                                                    ),
                                        },
                                    ) }
                                    key = { name?.slice(0, 5) }>
                                    {name}
                                </span>
                            ))}

                            {headerConfig.map(({ name, path }) => path ? (
                                <Link
                                    className = { clsx(styles.link, {
                                        [styles['link--active']]:
                                                getIsLinkActive(pathname, path),
                                    }) }
                                    style = {{ marginTop:  navbarHeight > 40 ? '10px' : '0' }}
                                    key = { name?.slice(0, 5) }
                                    to = { path }>
                                    {name}
                                </Link>
                            ) : (
                                <span
                                    className = { clsx(
                                        styles.link,
                                        'cursor-pointer',
                                        {
                                            [styles['link--active']]:
                                                    getIsLinkActive(
                                                        pathname,
                                                        path,
                                                    ),
                                        },
                                    ) }
                                    key = { name?.slice(0, 5) }>
                                    {name}
                                </span>
                            ))}

                            {/* pages from CMS */}
                            {CMSHeaderConfig?.filter(({ name, path }) => path !== '/home').map(({ name, path }) => path ? (
                                <Link
                                    className = { clsx(styles.link, {
                                        [styles['link--active']]:
                                                getIsLinkActive(pathname, path),
                                    }) }
                                    style = {{ marginTop:  navbarHeight > 40 ? '10px' : '0' }}
                                    key = { name?.slice(0, 5) }
                                    to = { path }>
                                    {name}
                                </Link>
                            ) : (
                                <span
                                    className = { clsx(
                                        styles.link,
                                        'cursor-pointer',
                                        {
                                            [styles['link--active']]:
                                                    getIsLinkActive(
                                                        pathname,
                                                        path,
                                                    ),
                                        },
                                    ) }
                                    key = { name?.slice(0, 5) }>
                                    {name}
                                </span>
                            ))}
                        </div>
                        <Link
                            className = { styles.cart }
                            to = { ROUTE_CONSTANTS.CART }>
                            {mealPlan && mealPlan.meals_of_plan.length ? (
                                <div className = { styles.fullPlateIconWrapper }>
                                    <div className = { styles.fullPlateIconDot } ></div>
                                    <CartIcon style = { isMealsPage ? { fill: 'white' } : { fill: '#2E2F31' } } />
                                </div>
                            ) : (
                                <CartIcon style = { isMealsPage ? { fill: 'white' } : { fill: '#2E2F31' } } />
                            )}
                        </Link>
                    </div>
                    <button
                        className = 'md:hidden'
                        onClick = { toggleMobileNavOpened }>
                        {isMealsPage ? (
                            <BurgerNavWhiteIconSvg />
                        ) : (
                            <BurgerNavIconSvg />
                        )}
                    </button>
                    <BaseButton
                        className = { styles['sign-in-button'] }
                        type = 'primary'
                        onClick = { redirectToAuth }>
                        Sign in
                    </BaseButton>
                    <div
                        className = 'relative hidden md:block self-center'
                        ref = { globeButtonRef }>
                        <button
                            className = { clsx('flex align-center md:self-start ml-3 md:ml-5', {
                                'opacity-50': isOnMealsPage && !isAuthenticated,
                            }) }
                            onClick = { () => EventBus.emit(SHOW_REGION_PROMPT) }
                            onMouseEnter = { () => setIsRegionTooltipOpened(true) }
                            onMouseLeave={ () => setIsRegionTooltipOpened(false) }
                            >
                            <GlobeIcon />
                        </button>
                        <RegionTooltip className = { clsx({ block: isRegionTooltipOpened }) } />
                    </div>
                </nav>
            </header>
            <HeaderMobileSidebar
                handleCloseSidebar = { () => setIsMobileNavOpened(false) }
                isActive = { isMobileNavOpened }
            />
        </div>
    );

    const mealsHeader = (
        <div className = { styles.mealsHeader }>
            {standardHeader}
            <div className = { styles.headerGradient }>
                <div className = { 'common-container ' + styles.headerTitle }>
                    Top quality ingredients, <br />
                    cooked to perfection
                </div>
                <h2 className = { 'common-container ' + styles.headerText }>
                    For the body you want, and the health you need
                </h2>
            </div>
        </div>
    );

    const renderHeader = () => {
        if (isMealsPage) {
            return mealsHeader;
        }

        return standardHeader;
    };

    return renderHeader();
};
