import {
    ACTION_GET_DELIVERY_TIMES_LOAD,
    ACTION_GET_DELIVERY_TIMES_SUCCESS,
    ACTION_GET_DELIVERY_TIMES_ERROR,
    ACTION_GET_DELIVERY_PLANS_LOAD,
    ACTION_GET_DELIVERY_PLANS_SUCCESS,
    ACTION_GET_DELIVERY_PLANS_ERROR,
    ACTION_GET_ACTIVE_DELIVERY_PLAN_LOAD,
    ACTION_GET_ACTIVE_DELIVERY_PLAN_SUCCESS,
    ACTION_GET_ACTIVE_DELIVERY_PLAN_ERROR,
    ACTION_GET_ACCOUNT_DELIVERIES_LOAD,
    ACTION_GET_ACCOUNT_DELIVERIES_SUCCESS,
    ACTION_GET_ACCOUNT_DELIVERIES_ERROR,
    ACTION_GET_CALENDAR_DELIVERY_DATES_LOAD,
    ACTION_GET_CALENDAR_DELIVERY_DATES_SUCCESS,
    ACTION_GET_CALENDAR_DELIVERY_DATES_ERROR,
    ACTION_SET_NEW_SELECTED_DELIVERY_TIME,
    ActionType,
    ACTION_GET_USER_CREDIT_SUCCESS,
} from '../action-types';

import { IDeliveriesState } from 'src/types/state';

const initialState: IDeliveriesState = {
    deliveryTimes: {
        loading: false,
        error:   null,
        data:    null,
    },
    deliveryPlanList: {
        loading: false,
        error:   null,
        data:    [],
    },
    activeDeliveryPlan: {
        loading: false,
        error: null,
        data: null,
    },
    deliveries: {
        loading: false,
        error: null,
        data: [],
    },
    calendarDeliveryDates: {
        loading: false,
        error: null,
        data: null,
    },
    selectedDeliveryTime: "",
    userCredit: 0
};

export const deliveries = (state = initialState, { type, payload }: ActionType<any>): IDeliveriesState => {
    switch (type) {
        case ACTION_GET_DELIVERY_TIMES_LOAD:
            return {
                ...state,
                deliveryTimes: {
                    ...state.deliveryTimes,
                    loading: true,
                },
            };
        case ACTION_GET_DELIVERY_TIMES_SUCCESS:
            return {
                ...state,
                deliveryTimes: {
                    ...state.deliveryTimes,
                    loading: false,
                    data:    payload,
                },
            };
        case ACTION_GET_DELIVERY_TIMES_ERROR:
            return {
                ...state,
                deliveryTimes: {
                    ...state.deliveryTimes,
                    loading: false,
                    error:   payload,
                },
            };
        case ACTION_GET_DELIVERY_PLANS_LOAD:
            return {
                ...state,
                deliveryPlanList: {
                    ...state.deliveryPlanList,
                    loading: true,
                },
            };
        case ACTION_GET_DELIVERY_PLANS_SUCCESS:
            return {
                ...state,
                deliveryPlanList: {
                    ...state.deliveryPlanList,
                    loading: false,
                    data:    payload,
                },
            };
        case ACTION_GET_DELIVERY_PLANS_ERROR:
            return {
                ...state,
                deliveryPlanList: {
                    ...state.deliveryPlanList,
                    loading: false,
                    error:   payload,
                },
            };
        case ACTION_GET_ACTIVE_DELIVERY_PLAN_LOAD:
            return {
                ...state,
                activeDeliveryPlan: {
                    ...state.activeDeliveryPlan,
                    loading: true,
                },
            };
        case ACTION_GET_ACTIVE_DELIVERY_PLAN_SUCCESS:
            return {
                ...state,
                activeDeliveryPlan: {
                    ...state.activeDeliveryPlan,
                    loading: false,
                    data:    payload,
                },
            };
        case ACTION_GET_ACTIVE_DELIVERY_PLAN_ERROR:
            return {
                ...state,
                activeDeliveryPlan: {
                    ...state.activeDeliveryPlan,
                    loading: false,
                    error:   payload,
                },
            };
        case ACTION_GET_ACCOUNT_DELIVERIES_LOAD:
            return {
                ...state,
                deliveries: {
                    ...state.deliveries,
                    loading: true,
                },
            };
        case ACTION_GET_ACCOUNT_DELIVERIES_SUCCESS:
            return {
                ...state,
                deliveries: {
                    ...state.deliveries,
                    loading: false,
                    data:    payload,
                    error: null,
                },
            };
        case ACTION_GET_ACCOUNT_DELIVERIES_ERROR:
            return {
                ...state,
                deliveries: {
                    ...state.deliveries,
                    loading: false,
                    error:   payload,
                },
            };
        case ACTION_GET_CALENDAR_DELIVERY_DATES_LOAD:
            return {
                ...state,
                calendarDeliveryDates: {
                    ...state.calendarDeliveryDates,
                    loading: true,
                },
            };
        case ACTION_GET_CALENDAR_DELIVERY_DATES_SUCCESS:
            return {
                ...state,
                calendarDeliveryDates: {
                    ...state.calendarDeliveryDates,
                    loading: false,
                    data:    payload,
                    error: null,
                },
            };
        case ACTION_GET_CALENDAR_DELIVERY_DATES_ERROR:
            return {
                ...state,
                calendarDeliveryDates: {
                    ...state.calendarDeliveryDates,
                    loading: false,
                    error:   payload,
                },
            };
        case ACTION_SET_NEW_SELECTED_DELIVERY_TIME:
            return {
                ...state,
                selectedDeliveryTime: payload
            };
        case ACTION_GET_USER_CREDIT_SUCCESS:
            return {
                ...state,
                userCredit: payload
            };
        default:
            return state;
    }
};
